import { useEffect, useState } from 'react';
import { useAppSelector } from 'common/hooks';

import { barChartsOptions } from 'constant/Dummydata';
import { Chart } from 'react-google-charts';

import styles from '../agentHome.module.scss';

type BarChartProps = {
  title: string;
};

const BarChart: React.FC<BarChartProps> = ({ title }) => {
  const requestsByAgent: any = useAppSelector((state) => state.requests.requestsByAgent.data);

  const [barChartsData, setBarChartsData] = useState([
    [
      'Projets',
      'total',
      { role: 'style' },
      {
        sourceColumn: 0,
        role: 'annotation',
        type: 'string',
        calc: 'stringify',
      },
    ],
    ['Demande annulée', 0, '#b87333', null],
    ['Refus après analyse', 0, 'silver', null],
    ['Refus assurance', 0, 'gold', null],
    ['Refus offre de crédit', 0, 'color: #e5e4e2', null],
    ['Fonds non débloqués', 0, 'color: #e5e4e2', null],
    ['Fonds débloqués', 0, 'color: #e5e4e2', null],
  ]);

  useEffect(() => {
    if (title === 'Projets fermés') {
      setBarChartsData([
        [
          'Projets',
          'total',
          { role: 'style' },
          {
            sourceColumn: 0,
            role: 'annotation',
            type: 'string',
            calc: 'stringify',
          },
        ],
        [
          'Demande annulée',
          requestsByAgent?.closedProjects?.cancelled_by_customer?.number,
          '#FFCDD2',
          null,
        ],
        ['Refus après analyse', requestsByAgent?.closedProjects?.rejected?.number, '#E57373', null],
        [
          'Refus assurance',
          requestsByAgent?.closedProjects?.assurance_refusee?.number,
          '#F44336',
          null,
        ],
        [
          'Refus offre de crédit',
          requestsByAgent?.closedProjects?.offre_de_credit_refusee?.number,
          'color: #D32F2F',
          null,
        ],
        [
          'Fonds non débloqués',
          requestsByAgent?.closedProjects?.fonds_non_debloques?.number,
          'color: #B71C1C',
          null,
        ],
        [
          'Fonds débloqués',
          requestsByAgent?.closedProjects?.offre_de_credit_refusee?.number,
          'color: #831010',
          null,
        ],
      ]);
    }

    if (title === 'Projets ouverts') {
      setBarChartsData([
        [
          'Projets',
          'total',
          { role: 'style' },
          {
            sourceColumn: 0,
            role: 'annotation',
            type: 'string',
            calc: 'stringify',
          },
        ],
        ['Nouveau projet', requestsByAgent?.openProjects?.open?.number, '#C7FFC9', null],
        [
          'Instruction du dossier',
          requestsByAgent?.openProjects?.processed?.number,
          '#99FF9C',
          null,
        ],
        [
          "Complément d'information demandé",
          requestsByAgent?.openProjects?.more_info_requested?.number,
          '#76FF7B',
          null,
        ],
        [
          'Informations fournies',
          requestsByAgent?.openProjects?.information_provided?.number,
          'color: #48FF4E',
          null,
        ],
        [
          'Attente signature mandat',
          requestsByAgent?.openProjects?.expected_signature?.number,
          'color: #2EFF35',
          null,
        ],
        [
          'Validation des documents',
          requestsByAgent?.openProjects?.signed?.number,
          'color: #0FFF17',
          null,
        ],
        [
          'Documents incomplets',
          requestsByAgent?.openProjects?.more_file_requested?.number,
          'color: #00EB08',
          null,
        ],
        // ['Documents fournis', 0, 'color: #00DA07', null],
        // ['Analyse du dossier', 0, 'color: #00C607', null],
        // ['Dossier validé', 0, 'color: #00AB06', null],
        [
          'Proposition acceptée',
          requestsByAgent?.openProjects?.proposition_acceptee?.number,
          'color: #009C07',
          null,
        ],
        // ['Attente signature questionnaire de santé et demande de prêt', 0, 'color: #008906', null],
        [
          'Attente édition de l’offre de prêt',
          requestsByAgent?.openProjects?.assurance_acceptee?.number,
          'color: #007706',
          null,
        ],
        // ['Edition offre de crédit', 0, 'color: #006605', null],
        [
          'Acceptation offre de crédit',
          requestsByAgent?.openProjects?.offre_de_credit_acceptee?.number,
          'color: #004B03',
          null,
        ],
      ]);
    }
  }, [requestsByAgent]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.barCharts}>
      <h3 className={styles.barCharts_title}>{title}</h3>
      {barChartsData && (
        <Chart chartType="BarChart" data={barChartsData} options={barChartsOptions} />
      )}
    </div>
  );
};

export default BarChart;
