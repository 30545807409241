import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'common/hooks';

import {
  addingOneAddress,
  editingOneAddress,
  getOneAddress,
} from 'reduxTK/slices/authentication/authAsyncThunk';
import { getCountries, getRegions } from 'reduxTK/slices/address/addressAsyncThunk';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { GiPositionMarker } from 'react-icons/gi';

import { MenuItem } from '@mui/material';

import styles from './editAddress.module.scss';

interface FormState {
  [key: string]: boolean | string | null;
}

interface OneAddressState {
  auth: {
    oneAddress: {
      data: any;
    };
  };
  address: {
    countries: {
      data: any;
    };
    regions: {
      data: any;
    };
  };
}

const EditAddress = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();

  const address = useSelector((state: OneAddressState) => state.auth.oneAddress.data);
  const Countries = useSelector((state: OneAddressState) => state.address?.countries?.data);
  const Regions = useSelector((state: OneAddressState) => state.address?.regions?.data);

  const addressId = params?.id;
  const profileId = localStorage.getItem('profileId');

  const isAdmin = localStorage.getItem('role') === 'ADMIN';

  const addressInputs = [
    { label: 'Libellé :', type: 'text', name: 'label', defaultValue: address?.label },
    { label: 'Civilité :', type: 'text', name: 'namePrefix', defaultValue: address?.namePrefix },
    { label: 'Prénom :', type: 'text', name: 'firstName', defaultValue: address?.firstName },
    {
      label: 'Deuxième prénom :',
      type: 'text',
      name: 'middleName',
      defaultValue: address?.middleName,
    },
    { label: 'Nom :', type: 'text', name: 'lastName', defaultValue: address?.lastName },
    {
      label: 'Organisation :',
      type: 'text',
      name: 'organization',
      defaultValue: address?.organization,
    },
    { label: 'Téléphone :', type: 'number', name: 'phone', defaultValue: address?.phone },
    { label: 'Adresse :', type: 'text', name: 'street', defaultValue: address?.street },
    { label: 'Adresse 2 :', type: 'text', name: 'street2', defaultValue: address?.street2 },
    {
      label: 'Code postal :',
      type: 'number',
      name: 'postalCode',
      defaultValue: address?.postalCode,
    },
    { label: 'Ville :', type: 'text', name: 'city', defaultValue: address?.city },
    { label: 'Département :', type: 'select', name: 'region', defaultValue: address?.region },
    { label: 'Pays :', type: 'select', name: 'country', defaultValue: address?.country },
  ];

  const [formState, setFormState] = useState<FormState>({});
  const [departmentValue, setDepartmentValue] = useState('');
  const [countryValue, setCountryValue] = useState('');

  useEffect(() => {
    console.log('countryValue', countryValue);
  }
    , [countryValue]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    setFormState({
      ...formState,
      [name]: e.target.value,
    });
  };

  const handleDepartmentChange = (e: SelectChangeEvent<string>) => {
    setDepartmentValue(e.target.value);
    setFormState({
      ...formState,
      region: e.target.value,
    });
  };

  const handleCountryChange = (e: SelectChangeEvent<string>) => {
    setCountryValue(e.target.value);
    setFormState({
      ...formState,
      country: e.target.value,
    });
  };

  // const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, checked } = e.target;
  //   setFormState({
  //     ...formState,
  //     [name]: checked,
  //   });
  // };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (addressId) {
      dispatch(editingOneAddress({ id: addressId, data: formState })).then(() =>
        navigate('/addressBookPage')
      );
    } else {
      dispatch(addingOneAddress({ data: formState })).then(() => navigate('/addressBookPage'));
    }
  };
  useEffect(() => {
    if (countryValue) {
      dispatch(getRegions(countryValue));
    }
  }, [countryValue, dispatch]);

  useEffect(() => {
    if (addressId) {
      dispatch(getOneAddress({ addressId, profileId }));
      dispatch(getCountries());
    }
  }, [addressId, profileId, dispatch]);

  useEffect(() => {
    if (address) {
      setFormState({
        phone: address?.phone,
        primary: true,
        label: address?.label,
        city: address?.city,
        street: address?.street,
        street2: address?.street2,
        postalCode: address?.postalCode,
        organization: address?.organization,
        namePrefix: address?.namePrefix,
        firstName: address?.firstName,
        middleName: address?.middleName,
        lastName: address?.lastName,
        country: address?.country,
      });
    } else {
      setFormState({
        frontendOwner: profileId,
      });
    }
  }, [address, profileId]);

  return (
    <div className={styles.editAddressContainer}>
      <div className={styles.headerContainer}>
        <h2 className={styles.title}>
          <GiPositionMarker /> {addressId ? 'MODIFIER ADRESSES' : 'AJOUTER ADRESSES'}
        </h2>
      </div>
      <div className={styles.formContainer}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formInputs}>
            {isAdmin ?? (
              <div className={styles.inputContainer}>
                <label className={styles.label}>Utilisateur</label>
                <Select
                  className={styles.select}
                  name="country"
                  value={countryValue}
                  onChange={handleCountryChange}
                >
                  {Countries.map((option: any) => (
                    <MenuItem key={option.iso2Code} value={option.iso2Code}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}

            {addressInputs.map((input, index) => (
              <div key={index} className={styles.inputContainer}>
                <label className={styles.label}>{input.label}</label>
                {input.type === 'select' && input.label === 'Département :' && (
                  <Select
                    className={styles.select}
                    name={input.name}
                    defaultValue={input?.defaultValue}
                    value={departmentValue}
                    onChange={handleDepartmentChange}
                  >
                    {
                      !Regions &&
                      <MenuItem
                        value=""
                        disabled
                      >
                        Sélectionner une pays
                      </MenuItem>
                    }
                    {Regions?.length === 0 &&
                      <MenuItem
                        value=""
                        disabled
                      >
                        Aucun département trouvé
                      </MenuItem>
                    }
                    {Regions && input.name === 'region' &&
                      Regions.map((option: any) => (
                        <MenuItem key={option.combinedCode} value={option.combinedCode}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}

                {Countries && input.type === 'select' && input.label === 'Pays :' && (
                  <Select
                    className={styles.select}
                    name={input.name}
                    value={countryValue}
                    onChange={handleCountryChange}
                  >
                    {input.name === 'country' &&
                      Countries.map((option: any) => (
                        <MenuItem key={option.iso2Code} value={option.iso2Code}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
                {input.type !== 'select' && (
                  <input
                    className={input.type === 'date' ? styles.dateInput : styles.input}
                    type={input.type}
                    defaultValue={input?.defaultValue}
                    name={input.name}
                    onChange={(e) => handleInputChange(e, input.name)}
                  />
                )}
              </div>
            ))}
          </div>
          <div className={styles.checkboxContainer}>
            {/* {checkboxes.map((checkbox, index) => (
              <div key={index} className={styles.checkboxItem}>
                <input
                  type="checkbox"
                  id={checkbox.name}
                  name={checkbox.name}
                  checked={
                    (typeof formState[checkbox.name] === 'boolean'
                      ? formState[checkbox.name]
                      : false) as boolean
                  }
                  onChange={handleCheckboxChange}
                />
                <label htmlFor={checkbox.name}>{checkbox.label}</label>
              </div>
            ))} */}
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.button} type="submit">
              Enregistrer
            </button>
            <button
              className={styles.button}
              type="button"
              onClick={() => navigate('/addressBookPage')}
            >
              Annuler
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditAddress;
