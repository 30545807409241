import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosService from 'common/axiosService';
import { API, API_HOST_FICODEV } from 'configs/api';

export const getNumberOfUser = createAsyncThunk('user/getNumberOfUser', async () => {
    const storedCustomerId = localStorage.getItem('customer');
    const URL = `${API_HOST_FICODEV}${API.auth.user}?customer=${storedCustomerId}&page=1&limit=100000`;
    try {
        const response = await axiosService.get(URL);
        return response.data.length ;
    } catch (error) {
        console.log('Error:', error);
        throw error;
    }
});


export const getUsersListByCustomer = createAsyncThunk('user/getUsersListByCustomer', async ({ page = 1, pageSize = 10 }: { page?: number, pageSize?: number }) => {
    const storedCustomerId = localStorage.getItem('customer');
    const URL = `${API_HOST_FICODEV}${API.auth.user}?customer=${storedCustomerId}&page=${page}&limit=${pageSize}`;
    try {
        const response = await axiosService.get(URL);
        return response.data ;
    } catch (error) {
        console.log('Error:', error);
        throw error;
    }
});

export const deleteUser = createAsyncThunk('user/deleteUser', async (id: string) => {
    const URL = `${API_HOST_FICODEV}${API.auth.user}/${id}`;
    try {
        const response = await axiosService.delete(URL);
        return response.data ;
    } catch (error) {
        console.log('Error:', error);
        throw error;
    }
});

export const getUserProfilToEdit = createAsyncThunk('auth/userProfilToEdit', async (id: string) => {
    
    const URL = `${API_HOST_FICODEV}${API.auth.user}/${id}`;
    try {
        const response = await axiosService.get(URL);
        return response.data ;
    } catch (error) {
        console.log('Error:', error);
        throw error;
    }
}
);