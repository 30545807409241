import axios from 'axios';
import { UsernameToken } from 'wsse';
import dayjs from 'dayjs';

const axiosService = {
  get: function (url: string, config?: any) {
    return axios.get(url, generateHeaders(config || {}));
  },
  post: function (url: string, data: any, config?: any) {
    const axiosWithCredentials = axios.create({
      withCredentials: true
    });

    return axiosWithCredentials.post(url, data, generateHeaders(config || {}));
  },
  put: function (url: string, data: any, config?: any) {
    return axios.put(url, data, generateHeaders(config || {}));
  },
  delete: function (url: string, config?: any) {
    return axios.delete(url, generateHeaders(config || {}));
  },
  patch: function (url: string, data: any, config?: any) {
    return axios.patch(url, data, generateHeaders(config || {}));
  },
};

function generateHeaders(config: any) {
  const username = 'super-admin';
  const password = '3b39c80e206d5a0f814f06c7b9d095115aefa1f2';

  const token = new UsernameToken({
    username: username,
    password: password,
    created: dayjs().format(),
  });

  const wsseHeader = token.toString({ nonceBase64: true });

  config.headers = {
    ...config.headers,
    Authorization: 'WSSE profile="UsernameToken"',
    'X-WSSE': wsseHeader,
  };

  return config;
}

export default axiosService;
