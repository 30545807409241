import React from 'react';
import { RiZoomInLine } from 'react-icons/ri';
import styles from './propositionDetails.module.scss';

interface DetailsSectionProps {
  titles: { text: string; icon: string }[];
  details: { label: string; value: string }[];
  propositionStatus: string | null;
  AgenceName: string | null;
  file: any;
}

const DetailsSection: React.FC<DetailsSectionProps> = ({ titles, details, propositionStatus, AgenceName, file }) => {
  return (
    <div className={styles.detailsSection}>
      <div className={styles.details}>
        <div className={styles.detailsHeader}>
          {titles.map((title, index) => (
            <div key={index} className={styles.title}>
              <h3 className={styles.titleText}>{title.text}</h3>
              {title.icon === '<RiZoomInLine />' ? (
                <RiZoomInLine size={20} className={styles.icon} />
              ) : (
                <span className={styles.icon}>{title.icon}</span>
              )}
            </div>
          ))}
        </div>
        <div className={styles.detailsBody}>
          <div className={styles.imgContainer}>
          {file?.content && (
            <img src={`data:image/png;base64,${file?.content}`} alt="logo" />
          )}
            <span>{AgenceName}</span>
          </div>
          <div className={styles.detailsContainer}>
            <span className={styles.status}>{propositionStatus}</span>
            {details.map((detail, index) => (
              <span className={detail.label === 'Montant à financier' ? styles.price : ''} key={index}>
                {detail.label} {detail.value}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsSection;
