import { useNavigate } from 'react-router-dom';
import styles from './propositionItem.module.scss';
import { useEffect, useState } from 'react';
import axiosService from 'common/axiosService';
import { API_HOST_FICODEV, API } from 'configs/api';

const PropositionItem = ({
  detail,
  reference,
  date,
  date2,
  accepted,
  id,
  internal_status
}: {
  detail: string;
  name: string;
  reference: string;
  date: string;
  date2: string;
  accepted: boolean;
  id: string;
  internal_status:string;
}) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState('');

  useEffect(() => {
    if (internal_status) {
      const frenchInternalStatus = async () => {
        try {
          const response = await axiosService.get(
            API_HOST_FICODEV + API.propositions.propositionInternalStatusById(internal_status)
          );
          setStatus(response.data.name);
        } catch (error) {
          console.error(error);
          setStatus('');
        }
      };
      frenchInternalStatus();
    }
  }, [internal_status]);
  return (
    <div className={styles.propositionItemContainer}>
      <div className={styles.header}>
        <div className={styles.infoDetails}>
          <div className={styles.info}>
            <h3 className={styles.name}>Proposition {reference}</h3>
           {/*<h3 className={styles.ref}>- REF :{reference}</h3>*/} 
          </div>
          <p>
            Propsée le {new Date(date).toLocaleDateString()} . Valide jusqu'au{' '}
            {new Date(date2).toLocaleDateString()} .{' '}
          </p>
          {status && status !== 'Nouvelle' && 
            <p>Statut: {status}</p>
          }
        </div>
        {/* <div className={styles.status}>
          {accepted === true ? (
            <p className={styles.accepted}>
              <span>
                <FaCheckCircle />
              </span>
              Accepté
            </p>
          ) : (
            <p className={styles.denied}>
              <RiCloseCircleLine />
              Refusé
            </p>
          )}
        </div> */}
      </div>
      <p className={styles.desktop} style={{ whiteSpace: 'pre-line' }}>{detail}</p>
      <button className={styles.btn} onClick={() => navigate(`/propositionDetailsPage/${id}`)}>
        ACCEDEZ A LA PROPOSITION
      </button>
    </div>
  );
};

export default PropositionItem;
