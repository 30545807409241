import { createSlice } from '@reduxjs/toolkit';
import { getUsersListByCustomer , getNumberOfUser , getUserProfilToEdit } from './usersAsyncThunk';

type StateType = {
  data: number | null;
  status: string;
  error: null;
};

const initialState: { users: StateType; numberOfUsers: StateType ; userProfilToEdit : StateType} = {
  users: { data: null, status: '', error: null },
  numberOfUsers: { data: null, status: '', error: null },
  userProfilToEdit: { data: null, status: '', error: null },
};
export const usersSlice = createSlice(
    {
        name: 'Users',
        initialState,
        reducers: {
            resetUserProfilToEdit: (state) => {
                state.userProfilToEdit = { data: null, status: '', error: null };
            }
        },
        extraReducers: (builder) => {
            builder
                .addCase(getUsersListByCustomer.pending, state => {
                    return { ...state, users: { ...state.users, status: 'loading' } };
                })
                .addCase(getUsersListByCustomer.fulfilled, (state, action) => {
                    return { ...state, users: { ...state.users, status: 'success', data: action.payload } };
                })
                .addCase(getUsersListByCustomer.rejected, (state, action) => {
                    return { ...state, users: { ...state.users, status: 'failed', error: (action.payload as any).error } };
                })
                .addCase(getNumberOfUser.pending, state => {
                    return { ...state, numberOfUsers: { ...state.numberOfUsers, status: 'loading' } };
                })
                .addCase(getNumberOfUser.fulfilled, (state, action) => {
                    return { ...state, numberOfUsers: { ...state.numberOfUsers, status: 'success', data: action.payload } };
                })
                .addCase(getNumberOfUser.rejected, (state, action) => {
                    return { ...state, numberOfUsers: { ...state.numberOfUsers, status: 'failed', error: (action.payload as any).error } };
                })
                .addCase (getUserProfilToEdit.pending, state => {
                    return { ...state, userProfilToEdit: { ...state.userProfilToEdit, status: 'loading' } };
                })
                .addCase(getUserProfilToEdit.fulfilled, (state, action) => {
                    return { ...state, userProfilToEdit: { ...state.userProfilToEdit, status: 'success', data: action.payload } };
                })
                .addCase(getUserProfilToEdit.rejected, (state, action) => {
                    return { ...state, userProfilToEdit: { ...state.userProfilToEdit, status: 'failed', error: (action.payload as any).error } };
                });
        }
    }
);

export const { resetUserProfilToEdit } = usersSlice.actions;
export default usersSlice.reducer;