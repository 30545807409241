import { createSlice } from '@reduxjs/toolkit';
import {
  getAgentPropositions,
  getAgentQuotesByRequests,
  getAllPropositions,
  getAdminFilteredPropositions,
  getOneProposition,
  getPropositionsByCustomerID,
  acceptProposition,
  rejectProposition,
} from './propositionsAsyncThunk';
const reduxState = {
  data: null,
  status: 'loading',
  error: null,
};
const initialState = {
  allPropositions: reduxState,
  adminFilteredPropositions: reduxState,
  propositionbyCustomer: reduxState,
  oneProposition: reduxState,
  agentPropositions: reduxState,
  agentQuotesByRequests: reduxState,
  editProposition: reduxState,
};
export const propositionsSlice = createSlice({
  name: 'Proposition',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getAllPropositions
      .addCase(getAllPropositions.pending, (state) => {
        return { ...state, allPropositions: { ...state.allPropositions, status: 'loading' } };
      })
      .addCase(getAllPropositions.fulfilled, (state, action) => {
        return {
          ...state,
          allPropositions: {
            ...state.allPropositions,
            status: 'success',
            data: action.payload.data,
          },
        };
      })
      .addCase(getAllPropositions.rejected, (state, action) => {
        return {
          ...state,
          allPropositions: {
            ...state.allPropositions,
            status: 'failed',
            error: action.error as any,
          },
        };
      })
      // getPropositionsByCustomerID
      .addCase(getPropositionsByCustomerID.pending, (state) => {
        return {
          ...state,
          propositionbyCustomer: { ...state.propositionbyCustomer, status: 'loading' },
        };
      })
      .addCase(getPropositionsByCustomerID.fulfilled, (state, action) => {
        return {
          ...state,
          propositionbyCustomer: {
            ...state.propositionbyCustomer,
            status: 'success',
            data: action.payload.data,
          },
        };
      })
      .addCase(getPropositionsByCustomerID.rejected, (state, action) => {
        return {
          ...state,
          propositionbyCustomer: {
            ...state.propositionbyCustomer,
            status: 'failed',
            error: action.error as any,
          },
        };
      })
      // getOneProposition
      .addCase(getOneProposition.pending, (state) => {
        return { ...state, oneProposition: { ...state.oneProposition, status: 'loading' } };
      })
      .addCase(getOneProposition.fulfilled, (state, action) => {
        return {
          ...state,
          oneProposition: { ...state.oneProposition, status: 'success', data: action.payload.data },
        };
      })
      .addCase(getOneProposition.rejected, (state, action) => {
        return {
          ...state,
          oneProposition: { ...state.oneProposition, status: 'failed', error: action.error as any },
        };
      })
      // get agent quotes by request
      .addCase(getAgentQuotesByRequests.pending, (state, action) => {
        return {
          ...state,
          agentQuotesByRequests: { ...state.agentQuotesByRequests, status: 'loading' },
        };
      })
      .addCase(getAgentQuotesByRequests.fulfilled, (state, action) => {
        return {
          ...state,
          agentQuotesByRequests: {
            ...state.agentQuotesByRequests,
            status: 'success',
            data: action.payload,
          },
        };
      })
      .addCase(getAgentQuotesByRequests.rejected, (state, action) => {
        return {
          ...state,
          agentQuotesByRequests: {
            ...state.agentQuotesByRequests,
            status: 'failed',
            error: (action.payload as any).error,
          },
        };
      })

      // getAgentPropositions
      .addCase(getAgentPropositions.pending, (state) => {
        return { ...state, agentPropositions: { ...state.agentPropositions, status: 'loading' } };
      })
      .addCase(getAgentPropositions.fulfilled, (state, action) => {
        return {
          ...state,
          agentPropositions: {
            ...state.agentPropositions,
            status: 'success',
            data: action.payload as any,
          },
        };
      })
      .addCase(getAgentPropositions.rejected, (state, action) => {
        return {
          ...state,
          agentPropositions: {
            ...state.agentPropositions,
            status: 'failed',
            error: action.error as any,
          },
        };
      })
      // get admin filtered propositions
      .addCase(getAdminFilteredPropositions.pending, (state) => {
        return {
          ...state,
          propositionbyCustomer: { ...state.propositionbyCustomer, status: 'loading' },
        };
      })
      .addCase(getAdminFilteredPropositions.fulfilled, (state, action) => {
        return {
          ...state,
          propositionbyCustomer: {
            ...state.propositionbyCustomer,
            status: 'success',
            data: action.payload.data,
          },
        };
      })
      .addCase(getAdminFilteredPropositions.rejected, (state, action) => {
        return {
          ...state,
          propositionbyCustomer: {
            ...state.propositionbyCustomer,
            status: 'failed',
            error: action.error as any,
          },
        };
      })
      // accept proposition
      .addCase(acceptProposition.pending, (state) => {
        return { ...state, editProposition: { ...state.editProposition, status: 'loading' } };
      })
      .addCase(acceptProposition.fulfilled, (state, action) => {
        return {
          ...state,
          editProposition: {
            ...state.editProposition,
            status: 'success',
            data: action.payload,
          },
        };
      })
      .addCase(acceptProposition.rejected, (state, action) => {
        return {
          ...state,
          editProposition: {
            ...state.editProposition,
            status: 'failed',
            error: action.error as any,
          },
        };
      })
      // reject proposition
      .addCase(rejectProposition.pending, (state) => {
        return { ...state, editProposition: { ...state.editProposition, status: 'loading' } };
      })
      .addCase(rejectProposition.fulfilled, (state, action) => {
        return {
          ...state,
          editProposition: {
            ...state.editProposition,
            status: 'success',
            data: action.payload,
          },
        };
      })
      .addCase(rejectProposition.rejected, (state, action) => {
        return {
          ...state,
          editProposition: {
            ...state.editProposition,
            status: 'failed',
            error: action.error as any,
          },
        };
      });
  },  
});

export default propositionsSlice.reducer;
