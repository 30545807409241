import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosService from 'common/axiosService';
import { API, API_HOST_FICODEV } from 'configs/api';

export const getAllAffiliations = createAsyncThunk('Affiliation/getAffiliations', async () => {
  const URL = `${API_HOST_FICODEV}${API.affiliations.allAffiliations}`;
  try {
    const response = await axiosService.get(URL);
    console.log(response.data);
    const serializableData = {
      data: response.data, // Assuming response.data is serializable
      status: response.status, // Assuming response.status is serializable
      statusText: response.statusText, // Assuming response.statusText is serializable
    };

    return serializableData;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
});

