// App.tsx
import ConfirmResetPassword from 'components/authentication/resetpassword/ConfirmResetPassword';
import React, { useEffect } from 'react';

const ConfirmResetPasswordPage: React.FC = () => {
  useEffect(() => {
    document.title = "Intergestion - Modification du mot de passe";
  }, []);
  return (
    <div>
      <ConfirmResetPassword />
    </div>
  );
};

export default ConfirmResetPasswordPage;