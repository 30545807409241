import { useNavigate } from 'react-router-dom';
import styles from './newProposals.module.scss';
import { useEffect, useState } from 'react';
import axiosService from 'common/axiosService';
import { API, API_HOST_FICODEV } from 'configs/api';

//propsType
interface NewProposalsProps {
    poNumber: string
    internal_status: string
    createdAt: string
    validUntil: string
    id?: string
}

const NewProposals = (
    {
        poNumber,
        internal_status,
        createdAt,
        validUntil,
        id
    }: NewProposalsProps
) => {

    const navigate = useNavigate();
    const [status, setStatus] = useState<string>('');

    useEffect(() => {
        if(internal_status) {
            const fetchStatuses = async () => {
                const statusResponse = await axiosService.get(`${API_HOST_FICODEV}${API.propositions.propositionInternalStatus}/${internal_status}`)
                setStatus(statusResponse.data.name)
            }
            fetchStatuses()
        }
    }, [internal_status])

            return (
                <div className={styles.newProposalsContainer}>
                    <div className={styles.newProposals}>
                        <div className={styles.newProposalsDetails}>
                            <div className={styles.firstDetails}>
                                <span>
                                    Proposition : {poNumber}
                                </span>
                                <div className={styles.newsBtncontainer}>
                                    <span>{status}</span>
                                </div>
                            </div>
                            <div className={styles.secondDetails}>
                                <span>
                                    Proposée le  {new Date(createdAt).toLocaleDateString()}
                                </span>
                                <span>
                                    Valide jusqu'au {new Date(validUntil).toLocaleDateString()}
                                </span>
                            </div>
                            <button onClick={() => navigate(`/propositionDetailsPage/${id}`)} className={styles.btn}>
                                ACCEDEZ A LA PROPOSITION
                            </button>
                        </div>
                    </div>
                </div>
            )
        }

        export default NewProposals