import styles from './newProject.module.scss';
import { useNavigate } from 'react-router-dom';

const NewProject = () => {
    const navigate = useNavigate();
    return (
        <div className={styles.newProjectContainer}>
            <h3>VOUS SOUHAITEZ FINANCER VOTRE PROJET ?</h3>
            <button className={styles.btn} 
            onClick={() => navigate('/editProjectApplicant')}
            >
                CLIQUEZ ICI POUR SOUMETTRE VOTRE PROJET
            </button>
        </div>
    )
}

export default NewProject