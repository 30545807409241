import { useEffect } from 'react';
import PropositionItem from './propositionItem/PropositionItem';
import { useAppDispatch } from 'common/hooks';
import { useSelector } from 'react-redux';
import { getPropositionsByCustomerID } from 'reduxTK/slices/propositions/propositionsAsyncThunk';
import { useParams } from 'react-router-dom';

interface RootState {
  propositions: {
    propositionbyCustomer: {
      data: any;
      status: string;
      error: any;
    };
  };
}

const Propositions = () => {
  const dispatch = useAppDispatch();

  const propositions = useSelector(
    (state: RootState) => state.propositions.propositionbyCustomer.data
  );

  useEffect(() => {
    dispatch(
      getPropositionsByCustomerID({
        agency: '',
        agent: '',
        status: '',
        order: '',
        sort: '',
        startDate: '',
        endDate: ''
      })
    );
  }, [dispatch]);

  const id = useParams().id;

  return (
    <div>
      {propositions?.map((proposition: any, index: any) => {
        if (`${proposition.request}` === id) {
          return (
            <PropositionItem
              key={index}
              name={proposition.name}
              reference={proposition.poNumber}
              date={proposition.createdAt}
              date2={proposition.validUntil}
              accepted={proposition.accepted}
              detail={proposition.s_quote_detail}
              id={proposition.id}
              internal_status={proposition.internal_status}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default Propositions;
