import { createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosService from 'common/axiosService';
import { API, API_HOST_FICODEV } from 'configs/api';

interface LoginData {
  email: string;
  password: string;
}

interface LoginResponse {
  meta: { apiKey: string };
  email: string;
}

export interface UserModel {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  birthday: string;
}
export interface NewUserModel {
  confirmed: boolean;
  email: any;
  firstName: any;
  lastName: any;
  birthday: any;
  enabled: boolean;
  username: any;
  password: any;
  s_email_agent: any;
  roles: number[];
  customer: string;
}

interface RegisterResponse {
  data: any;
}

export const login: AsyncThunk<LoginResponse, LoginData, {}> = createAsyncThunk(
  'auth/login',
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const { email, password } = data;
    const URL = API_HOST_FICODEV + API.auth.login;

    try {
      const res = await axios.post<LoginResponse>(URL, { meta: { email, password } });
      const apiKey = res.data.meta.apiKey;

      localStorage.setItem('token', apiKey);
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('email', email);

      return fulfillWithValue({ meta: { apiKey }, email });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const registerUser: AsyncThunk<RegisterResponse, UserModel, {}> = createAsyncThunk(
  'auth/register',
  async (dataObj, { fulfillWithValue, rejectWithValue }) => {
    const URL = API_HOST_FICODEV + API.auth.user;
    const data = {
      confirmed: true,
      email: dataObj.email,
      firstName: dataObj.firstName,
      lastName: dataObj.lastName,
      birthday: dataObj.birthday,
      enabled: true,
      username: dataObj.email,
      password: dataObj.password,
      s_email_agent: 'intergestion-superviseur@fibourse.com',
      roles: [2],
      customer: 59,
    };

    return axiosService
      .post(URL, data)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
  }
);

export const getUserProfil = createAsyncThunk(
  'auth/userProfile',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    const email = localStorage.getItem('email');
    const URL = `${API_HOST_FICODEV}${API.auth.user}?email=${email}`;

    return axiosService
      .get(URL)
      .then((res) => {
        const roleId = res.data[0].roles[0];
        localStorage.setItem('profileId', res.data[0].id);
        localStorage.setItem('customer', res.data[0].customer);
        if (roleId === 1) {
          localStorage.setItem('role', 'ADMIN');
        } else if (roleId === 2) {
          localStorage.setItem('role', 'APPLICANT');
        } else if (roleId === 4) {
          localStorage.setItem('role', 'AGENT');
        } else if (roleId === 5) {
          localStorage.setItem('role', 'DIRECTOR');
        }

        return fulfillWithValue({ user: res.data[0], roleId });
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const editingProfile = createAsyncThunk(
  'auth/editProfile',
  async (data: { id: string | null; data: any }, { fulfillWithValue, rejectWithValue }) => {
    const URL = `${API_HOST_FICODEV}${API.auth.user}/${data.id}`;

    return axiosService
      .patch(URL, data.data)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);
export const addUser: AsyncThunk<RegisterResponse, NewUserModel, {}> = createAsyncThunk(
  'auth/addUser',
  async (newUser, { fulfillWithValue, rejectWithValue }) => {
    const URL = API_HOST_FICODEV + API.auth.user;
    console.log("newUser",newUser)
    return axiosService
      .post(URL, newUser)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        return rejectWithValue(err.response.data);
      });
  }
);
export const getOneAddress = createAsyncThunk(
  'auth/getOneAddress',
  async (data: { profileId: any; addressId: any }, { fulfillWithValue, rejectWithValue }) => {
    const URL = `${API_HOST_FICODEV}${API.auth.oneAddress(data.profileId, data.addressId)}`;

    return axiosService
      .get(URL)
      .then((res) => {
        return fulfillWithValue(res.data[0]);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const editingOneAddress = createAsyncThunk(
  'auth/editOneAddress',
  async (data: { id: string | undefined; data: any }, { fulfillWithValue, rejectWithValue }) => {
    const URL = `${API_HOST_FICODEV}${API.auth.editAddress(data.id)}`;

    return axiosService
      .patch(URL, data.data)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const addingOneAddress = createAsyncThunk(
  'auth/addingOneAddress',
  async (data: { data: any }, { fulfillWithValue, rejectWithValue }) => {
    const URL = `${API_HOST_FICODEV}${API.auth.addAddress}`;

    return axiosService
      .post(URL, data.data)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const deletingOneAddress = createAsyncThunk(
  'auth/deletingOneAddress',
  async (id: string | undefined, { fulfillWithValue, rejectWithValue }) => {
    const URL = `${API_HOST_FICODEV}${API.auth.editAddress(id)}`;

    return axiosService
      .delete(URL)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);




export  const passwordRequest = createAsyncThunk(
  'auth/passwordRequest',
  async (data: { email: string, front_website: string }, { fulfillWithValue, rejectWithValue }) => {
    const URL = `${API_HOST_FICODEV}${API.password.reset}`;

    return axiosService
      .post(URL, data)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);


export const passwordReset = createAsyncThunk(
  'auth/passwordReset',
  async (data: { password: string, token: string }, { fulfillWithValue, rejectWithValue }) => {
    const URL = `${API_HOST_FICODEV}${API.password.newPassword}`;

    return axiosService
      .post(URL, data)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const updatePassword = createAsyncThunk(
  'auth/updatePassword',
  async (data: { old_password: any, new_password: any , id : any  }, { fulfillWithValue, rejectWithValue }) => {
    console.log("data",data)
    const URL = `${API_HOST_FICODEV}${API.auth.updatePassword}`;

    return axiosService
      .post(URL, data)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);