import AgentHome from 'components/home/agentHome/AgentHome';
import { useEffect } from 'react';

const HomePageAgent = () => {
  useEffect(() => {
    document.title = "Intergestion - Accueil";
  }, []);
  return (
    <div>
      <AgentHome />
    </div>
  );
};

export default HomePageAgent;
