// App.tsx
import ConfirmRegister from 'components/authentication/register/ConfirmRegister';
import React from 'react';

const ConfirmRegisterPage: React.FC = () => {
  return (
    <div>
      <ConfirmRegister />
    </div>
  );
};

export default ConfirmRegisterPage;