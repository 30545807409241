import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosService from 'common/axiosService';
import { API, API_HOST_FICODEV } from 'configs/api';

export const getUseraddresslist = createAsyncThunk('user/getUserProfile', async (
    { country, region }: { country: string, region: string },
) => {
    const userId = localStorage.getItem('profileId');
    const URL = `${API_HOST_FICODEV}${API.auth.allAddress(userId as string)}?country=${country}&region=${region}`;
    try {
        const res = await axiosService.get(URL);
        return res.data;
    } catch (error) {
        console.log('Error:', error);
        throw error;
    }
}
);


export const getCountries = createAsyncThunk('user/getCountries', async () => {
    const URL = `${API_HOST_FICODEV}${API.addressBook.countries}?page=1&limit=1000`;
    try {
        const res = await axiosService.get(URL);
        return res.data;
    } catch (error) {
        console.log('Error:', error);
        throw error;
    }
}
);

export const getRegions = createAsyncThunk('user/getRegions', async (country: string) => {
    const URL = `${API_HOST_FICODEV}${API.addressBook.regions}?country=${country}&page=1&limit=1000`;
    try {
        const res = await axiosService.get(URL);
        return res.data;
    } catch (error) {
        console.log('Error:', error);
        throw error;
    }
}
);


export const getCountriesName = createAsyncThunk('user/getCountriesName', async (country: string) => {
    const URL = `${API_HOST_FICODEV}${API.addressBook.countries}/${country}`;
    try {
        const res = await axiosService.get(URL);
        return res.data;
    } catch (error) {
        console.log('Error:', error);
        throw error;
    }
}
);

export const  getRegionsName = createAsyncThunk('user/getRegionsName', async (region: string) => {
    const URL = `${API_HOST_FICODEV}${API.addressBook.regions}/${region}`;
    try {
        const res = await axiosService.get(URL);
        return res.data;
    } catch (error) {
        console.log('Error:', error);
        throw error;
    }
});