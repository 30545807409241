import { useEffect , useState } from 'react';
import LatestProject from 'components/home/latestProject/LatestProject';
import NewProject from 'components/home/newProject/NewProject';
import NewProposals from 'components/home/newProposals/NewProposals';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'common/hooks';
import {
  getRequestInternalStatus,
  getRequestTypeBien,
  getRequestUsageBien,
  getRequestsbyCustomerID,
} from 'reduxTK/slices/requests/requestsAsyncThunk';
import { getPropositionsByCustomerID } from 'reduxTK/slices/propositions/propositionsAsyncThunk';
import { API, API_HOST_FICODEV } from 'configs/api';
import axiosService from 'common/axiosService';

const HomePageApplicant = () => {
  const dispatch = useAppDispatch();
  const [firstNameOwner, setFirstNameOwner] = useState<string>('');
  const [lastNameOwner, setLastNameOwner] = useState<string>('');

  const requestsbyCustomerID = useSelector((state: any) => state.requests.requestsbyCustomerID);
  const requestInternalStatus = useSelector(
    (state: any) => state.requests.requestInternalStatus?.data?.name
  );
  const requestUsageBien = useSelector((state: any) => state.requests.requestUsageBien?.data?.name);
  const requestTypeBien = useSelector((state: any) => state.requests.requestTypeBien?.data?.name);
  const propositions = useSelector((state: any) => state.propositions.propositionbyCustomer?.data);

  useEffect(() => {
    document.title = "Intergestion - Accueil";
  }, []);
  useEffect(() => {
    const data = {
      agency: "",
      status: "",
      agent: "",
      startDate : "", 
      endDate : "",
      sort : "createdAt",
      order : ""
    };
    dispatch(getRequestsbyCustomerID({ ...data, applicant: '' })).then((res: any) => {
      dispatch(getRequestInternalStatus(res.payload?.data[0]?.id));
      dispatch(getRequestTypeBien(res.payload?.data[0]?.id));
      dispatch(getRequestUsageBien(res.payload?.data[0]?.id));
    });
    dispatch(
      getPropositionsByCustomerID({
        agency: '',
        agent: '',
        status: 'draft',
        filters :'createdAt' , 
        order : '' ,
        startDate : "", 
        endDate : "",
      } as any)
    );
  }, [dispatch]);

  // backend : sort by date inavaible so we have to sort it here
  const recentRequest = requestsbyCustomerID.data?.slice().sort((a: any, b: any) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  })[0];

  useEffect(() => {
    const fetchData = async () => {
      if (recentRequest?.owner) {
        try {
          const onwnerRes = await axiosService.get(`${API_HOST_FICODEV}${API.users}/${recentRequest.owner}`);
          const onwnerData = onwnerRes.data;
          setFirstNameOwner(onwnerData.firstName);
          setLastNameOwner(onwnerData.lastName);
        } catch (error) {
          console.error('Error:', error);
        }
      }
    }
    fetchData();
  }
  , [recentRequest]);

  return (
    <div>
      {recentRequest ? (
        <LatestProject
          title="VOTRE DERNIER PROJET"
          usage={requestUsageBien}
          type={requestTypeBien}
          amount={parseFloat(recentRequest?.s_montant_global).toFixed(2)}
          status={requestInternalStatus}
          applicantFirstName={recentRequest?.firstName}
          applicantLastName={recentRequest?.lastName}
          manager={`${firstNameOwner} ${lastNameOwner}`}
          creationDate={recentRequest?.createdAt}
          id={recentRequest?.id}
          customer={recentRequest?.customer}
          lastContactedDate = {recentRequest?.lastContactedDate}
          timesContacted = {recentRequest?.timesContacted}
          s_email_agent = {recentRequest?.s_email_agent}
        />
      ) : (
        <em>VOUS N'AVEZ AUCUN PROJET POUR LE MOMENT</em>
      )}
      <h3>LES NOUVELLES PROPOSITIONS</h3>
      {propositions?.length !== 0 ? (
        propositions?.map((proposition: any, index: number) => {
          return (
            <NewProposals
              key={index}
              poNumber={proposition.poNumber}
              internal_status={proposition.internal_status}
              createdAt={proposition.createdAt}
              validUntil={proposition.validUntil}
              id={proposition.id}
            />
          );
        })
      ) : (
        <em>VOUS N'AVEZ AUCUNE PROPOSITION POUR LE MOMENT</em>
      )}
      <NewProject />
    </div>
  );
};

export default HomePageApplicant;
