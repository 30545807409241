import React from 'react';
import styles from './descriptionProjectFile.module.scss';
import { useSelector } from 'react-redux';

interface RootState {
  requests: {
    oneRequestById: {
      data: any;
      status: string;
      error: any;
    };
  };
}

const DescriptionProjectFile = () => {
  const fileHeader = useSelector((state: RootState) => state.requests.oneRequestById.data);

  return (
    <div className={styles.descriptionContainer}>
      <h3>Description de votre projet</h3>
      <p style={{ whiteSpace: 'pre-line' }}>{fileHeader?.note }</p>
      {fileHeader?.s_url_project_media != null &&
        <><h3>Présentation vidéo</h3><div className={styles.videoContainer}>
          <video className={styles.video} controls>
            <source src={fileHeader?.s_url_project_media} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div></>
      }
      
    </div>
  );
};

export default DescriptionProjectFile;
