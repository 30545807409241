import { createSlice } from '@reduxjs/toolkit';
import {
  getRequestInternalStatus,
  getRequestUsageBien,
  getRequestTypeBien,
  getRequestsbyCustomerID,
  getAllRequests,
  getOneRequest,
  getRequestCustomer,
  getRequestAssignedUser,
  addFinancialRequest,
  gettingOneRequest,
  editingRequest,
  confirmAddProject,
  getRequestSituationPro,
  getAgentRequests,
  getAllRequestsByAgent,
  getRequestsByAgent,
} from './requestsAsyncThunk';

const reduxState = {
  data: null,
  status: 'idle',
  error: null,
};
const initialState = {
  requestsById: reduxState,
  oneRequestById: reduxState,
  requestCustomer: reduxState,
  requestAssignedUser: reduxState,
  allRequests: reduxState,
  requestInternalStatus: reduxState,
  requestUsageBien: reduxState,
  requestTypeBien: reduxState,
  requestsbyCustomerID: reduxState,
  requestSituationPro: reduxState,
  addingRequest: reduxState,
  oneRequest: reduxState,
  editingOneRequest: reduxState,
  addProjectConfirmation: reduxState,
  agentRequests: reduxState,
  requestsByAgent: reduxState,
  requestByAgent: reduxState,
};
export const requestsSlice = createSlice({
  name: 'Requests',
  initialState,
  reducers: {
    deleteStoredData: (state) => {
      state.oneRequest = reduxState;
      state.editingOneRequest = reduxState;
      state.addProjectConfirmation = reduxState;
      state.addingRequest = reduxState;
    },
  },
  extraReducers: (builder) => {
    builder
      // getRequestsbyCustomerID
      .addCase(getRequestsbyCustomerID.pending, (state) => {
        return {
          ...state,
          requestsbyCustomerID: { ...state.requestsbyCustomerID, status: 'loading' },
        };
      })
      .addCase(getRequestsbyCustomerID.fulfilled, (state, action) => {
        return {
          ...state,
          requestsbyCustomerID: {
            ...state.requestsbyCustomerID,
            status: 'success',
            data: action.payload.data,
          },
        };
      })
      .addCase(getRequestsbyCustomerID.rejected, (state, action) => {
        return {
          ...state,
          requestsbyCustomerID: {
            ...state.requestsbyCustomerID,
            status: 'failed',
            error: action.error as any,
          },
        };
      })

      .addCase(getOneRequest.pending, (state) => {
        return { ...state, oneRequestById: { ...state.oneRequestById, status: 'loading' } };
      })
      .addCase(getOneRequest.fulfilled, (state, action) => {
        return {
          ...state,
          oneRequestById: { ...state.oneRequestById, status: 'success', data: action.payload },
        };
      })
      .addCase(getOneRequest.rejected, (state, action) => {
        return {
          ...state,
          oneRequestById: { ...state.oneRequestById, status: 'failed', error: action.error as any },
        };
      })

      //getRequestsInternalStatus
      .addCase(getRequestInternalStatus.pending, (state) => {
        return {
          ...state,
          requestInternalStatus: { ...state.requestInternalStatus, status: 'loading' },
        };
      })
      .addCase(getRequestInternalStatus.fulfilled, (state, action) => {
        return {
          ...state,
          requestInternalStatus: {
            ...state.requestInternalStatus,
            status: 'success',
            data: action.payload,
          },
        };
      })
      .addCase(getRequestInternalStatus.rejected, (state, action) => {
        return {
          ...state,
          requestInternalStatus: {
            ...state.requestInternalStatus,
            status: 'failed',
            error: action.error as any,
          },
        };
      })

      //getRequestUsageBien
      .addCase(getRequestUsageBien.pending, (state) => {
        return { ...state, requestUsageBien: { ...state.requestUsageBien, status: 'loading' } };
      })
      .addCase(getRequestUsageBien.fulfilled, (state, action) => {
        return {
          ...state,
          requestUsageBien: { ...state.requestUsageBien, status: 'success', data: action.payload },
        };
      })
      .addCase(getRequestUsageBien.rejected, (state, action) => {
        return {
          ...state,
          requestUsageBien: {
            ...state.requestUsageBien,
            status: 'failed',
            error: action.error as any,
          },
        };
      })

      //getRequestTypeBien
      .addCase(getRequestTypeBien.pending, (state) => {
        return { ...state, requestTypeBien: { ...state.requestTypeBien, status: 'loading' } };
      })
      .addCase(getRequestTypeBien.fulfilled, (state, action) => {
        return {
          ...state,
          requestTypeBien: { ...state.requestTypeBien, status: 'success', data: action.payload },
        };
      })

      // get all requests
      .addCase(getAllRequests.pending, (state) => {
        return { ...state, allRequests: { ...state.allRequests, status: 'loading' } };
      })
      .addCase(getAllRequests.fulfilled, (state, action) => {
        return {
          ...state,
          allRequests: { ...state.allRequests, status: 'success', data: action.payload },
        };
      })
      .addCase(getAllRequests.rejected, (state, action) => {
        return {
          ...state,
          allRequests: {
            ...state.allRequests,
            status: 'failed',
            error: (action.payload as any).error,
          },
        };
      })

      //get request customer
      .addCase(getRequestCustomer.pending, (state) => {
        return { ...state, requestCustomer: { ...state.requestCustomer, status: 'loading' } };
      })
      .addCase(getRequestCustomer.fulfilled, (state, action) => {
        return {
          ...state,
          requestCustomer: { ...state.requestCustomer, status: 'success', data: action.payload },
        };
      })
      .addCase(getRequestCustomer.rejected, (state, action) => {
        return {
          ...state,
          requestCustomer: {
            ...state.requestCustomer,
            status: 'failed',
            error: (action.payload as any).error,
          },
        };
      })

      //get request assigned user
      .addCase(getRequestAssignedUser.pending, (state) => {
        return {
          ...state,
          requestAssignedUser: { ...state.requestAssignedUser, status: 'loading' },
        };
      })
      .addCase(getRequestAssignedUser.fulfilled, (state, action) => {
        return {
          ...state,
          requestAssignedUser: {
            ...state.requestAssignedUser,
            status: 'success',
            data: action.payload,
          },
        };
      })
      .addCase(getRequestAssignedUser.rejected, (state, action) => {
        return {
          ...state,
          requestAssignedUser: {
            ...state.requestAssignedUser,
            status: 'failed',
            error: (action.payload as any).error,
          },
        };
      })
      // add one request
      .addCase(addFinancialRequest.pending, (state) => {
        return { ...state, addingRequest: { ...state.addingRequest, status: 'loading' } };
      })
      .addCase(addFinancialRequest.fulfilled, (state, action) => {
        return {
          ...state,
          addingRequest: { ...state.addingRequest, status: 'success', data: action.payload },
        };
      })
      .addCase(addFinancialRequest.rejected, (state, action) => {
        return {
          ...state,
          addingRequest: {
            ...state.addingRequest,
            status: 'failed',
            error: (action.payload as any).error,
          },
        };
      })

      // get one request
      .addCase(gettingOneRequest.pending, (state) => {
        return { ...state, oneRequest: { ...state.oneRequest, status: 'loading' } };
      })
      .addCase(gettingOneRequest.fulfilled, (state, action) => {
        return {
          ...state,
          oneRequest: { ...state.oneRequest, status: 'success', data: action.payload },
        };
      })
      .addCase(gettingOneRequest.rejected, (state, action) => {
        return {
          ...state,
          oneRequest: {
            ...state.oneRequest,
            status: 'failed',
            error: (action.payload as any).error,
          },
        };
      })

      // edit one request
      .addCase(editingRequest.pending, (state) => {
        return { ...state, editingOneRequest: { ...state.editingOneRequest, status: 'loading' } };
      })
      .addCase(editingRequest.fulfilled, (state, action) => {
        return {
          ...state,
          editingOneRequest: {
            ...state.editingOneRequest,
            status: 'success',
            data: action.payload,
          },
        };
      })
      .addCase(editingRequest.rejected, (state, action) => {
        return {
          ...state,
          editingOneRequest: {
            ...state.editingOneRequest,
            status: 'failed',
            error: (action.payload as any).error,
          },
        };
      })

      // add project confirmation
      .addCase(confirmAddProject.pending, (state) => {
        return {
          ...state,
          addProjectConfirmation: { ...state.addProjectConfirmation, status: 'loading' },
        };
      })
      .addCase(confirmAddProject.fulfilled, (state, action) => {
        return {
          ...state,
          addProjectConfirmation: {
            ...state.addProjectConfirmation,
            status: 'success',
            data: action.payload,
          },
        };
      })
      .addCase(confirmAddProject.rejected, (state, action) => {
        return {
          ...state,
          addProjectConfirmation: {
            ...state.addProjectConfirmation,
            status: 'failed',
            error: (action.payload as any).error,
          },
        };
      })

      // get request Situation Pro
      .addCase(getRequestSituationPro.pending, (state) => {
        return {
          ...state,
          requestSituationPro: { ...state.requestSituationPro, status: 'loading' },
        };
      })
      .addCase(getRequestSituationPro.fulfilled, (state, action) => {
        return {
          ...state,
          requestSituationPro: {
            ...state.requestSituationPro,
            status: 'success',
            data: action.payload,
          },
        };
      })
      .addCase(getRequestSituationPro.rejected, (state, action) => {
        return {
          ...state,
          requestSituationPro: {
            ...state.requestSituationPro,
            status: 'failed',
            error: (action.payload as any).error,
          },
        };
      })

      // get requestByAgent
      .addCase(getRequestsByAgent.pending, (state) => {
        return {
          ...state,
          requestByAgent: { ...state.requestByAgent, status: 'loading' },
        };
      })
      .addCase(getRequestsByAgent.fulfilled, (state, action) => {
        return {
          ...state,
          requestByAgent: {
            ...state.requestByAgent,
            status: 'success',
            data: action.payload,
          },
        };
      })
      .addCase(getRequestsByAgent.rejected, (state, action) => {
        return {
          ...state,
          requestByAgent: {
            ...state.requestByAgent,
            status: 'failed',
            error: (action.payload as any).error,
          },
        };
      })

      // get agent requests
      .addCase(getAgentRequests.pending, (state) => {
        return {
          ...state,
          agentRequests: { ...state.agentRequests, status: 'loading' },
        };
      })
      .addCase(getAgentRequests.fulfilled, (state, action) => {
        return {
          ...state,
          agentRequests: {
            ...state.agentRequests,
            status: 'success',
            data: action.payload as any,
          },
        };
      })
      .addCase(getAgentRequests.rejected, (state, action) => {
        return {
          ...state,
          agentRequests: {
            ...state.agentRequests,
            status: 'failed',
            error: (action.payload as any).error,
          },
        };
      })

      // get agent requests
      .addCase(getAllRequestsByAgent.pending, (state) => {
        return {
          ...state,
          requestsByAgent: { ...state.requestsByAgent, status: 'loading' },
        };
      })
      .addCase(getAllRequestsByAgent.fulfilled, (state, action) => {
        return {
          ...state,
          requestsByAgent: {
            ...state.requestsByAgent,
            status: 'success',
            data: action.payload as any,
          },
        };
      })
      .addCase(getAllRequestsByAgent.rejected, (state, action) => {
        return {
          ...state,
          requestsByAgent: {
            ...state.requestsByAgent,
            status: 'failed',
            error: (action.payload as any).error,
          },
        };
      });
  },
});

export const { deleteStoredData } = requestsSlice.actions;

export default requestsSlice.reducer;
