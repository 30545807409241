import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_HOST, API } from 'configs/api';

export const getCarousel = createAsyncThunk( 'carousel/getCarousel', async () => {
    try {
        const response = await axios.get(`${API_HOST}${API.carousel.slides}`, {
            params: {
                page: 1,
                limit: 15,
                'filter[_and][0][slider_intergestion_id]': 3,
                'fields[]': [
                    'directus_files_id.id',
                    'directus_files_id.type',
                    'directus_files_id.title',
                    'directus_files_id.filename_download',
                    'id'
                ]
            },
        });
        return response.data.data; 
    } catch (error: any) {
        return error.response.data as any;
    }
}
);