import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { FiEdit } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import styles from './adresses.module.scss';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'common/hooks';
import { deletingOneAddress } from 'reduxTK/slices/authentication/authAsyncThunk';
import { getCountriesName, getRegionsName } from 'reduxTK/slices/address/addressAsyncThunk';

const Adresses = ({
  city,
  region,
  street,
  postalCode,
  firstName,
  lastName,
  street2,
  country,
  primary,
  id,
}: {
  city?: string;
  region?: string;
  street?: string;
  postalCode?: string;
  firstName?: string;
  lastName?: string;
  street2?: string;
  country?: string;
  primary?: string;
  id?: string;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    p: 3,
    color: '#003c8a',
  };

  useEffect(() => {
    if (country) {
    dispatch(getCountriesName(country as string));
  }
  if (region) {
    dispatch(getRegionsName(region as string));
  }
  }
  , [country ,region, dispatch]);


  const deleteAddress = () => {
    dispatch(deletingOneAddress(id)).then(() => handleClose());
  };
  const countryName = useSelector((state: any) => state.address?.countriesName.data?.name);
  const regionName = useSelector((state: any) => state.address?.regionsName.data?.name);

  return (
    <div className={styles.profileDetailsContainer}>
      <div className={styles.flex}>
        <div className={styles.adresses}>
          <span className={styles.label}>
            {firstName} {lastName}
          </span>
          <span className={styles.label}>
            {street} {street2}
          </span>
          <span className={styles.label}>
            {postalCode} {city}
          </span>
          <span className={styles.label}>
            {countryName && countryName} {regionName && regionName}
          </span>
          <span className={styles.value}>{primary}</span>
        </div>
        <div className={styles.editIcons}>
          <span className={styles.btn}>
            <FiEdit onClick={() => navigate(`/agent/address/edit/${id}`)} size={20} />
          </span>
          <span className={styles.btn}>
            <RiDeleteBin6Line onClick={handleOpen} size={20} />
          </span>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Êtes-vous sûr de vouloir supprimer cette adresse ?
          </Typography>
          <div className={styles.buttonGroup}>
            <button onClick={deleteAddress} className={styles.modal_buttons}>
              Supprimer
            </button>
            <button onClick={handleClose} className={styles.modal_buttons}>
              Annuler
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Adresses;
