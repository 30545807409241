import styles from './blockTitle.module.scss';

// { handleFilterChange, filters , type  }
//         : { handleFilterChange: any, filters: any , type : string}

const BlockTitle = ({title, subtitle}: {title: string, subtitle: string}) => {
  return (
    <div className={styles.blockTitleMainContainer}>
      <h2>{title}<span>{subtitle}</span></h2>
    </div>
  );
}

export default BlockTitle;