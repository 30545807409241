import { useEffect, useState } from 'react';
import { getUseraddresslist } from 'reduxTK/slices/address/addressAsyncThunk';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './addressBookItem.module.scss';
import Countryfilter from './Countryfilter';
import { SelectChangeEvent } from '@mui/material/Select';
import RegionFilter from './RegionFilter';
import Adresses from 'components/adresses/Adresses';
import { FaMapMarkerAlt } from 'react-icons/fa';
import FormControl from '@mui/material/FormControl';
import { InputLabel } from '@mui/material';
import { emptyOneAddress } from 'reduxTK/slices/authentication/authSlice';

interface AddressType {
  city: string;
  country: string;
  region: string;
  postalCode: string;
  firstName: string;
  lastName: string;
  street: string;
  street2: string;
  primary: string;
  id: string;
}

const AddressBookItem = ({ sectionName }: { sectionName: string }) => {
  const [filterData, setFilterData] = useState({
    country: '',
    region: '',
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const deleteAddressStatus = useAppSelector((state) => state.auth.oneAddress.addEditSuccess);

  useEffect(() => {
    dispatch(
      getUseraddresslist({
        country: filterData.country,
        region: filterData.region,
      })
    );
    dispatch(emptyOneAddress());
  }, [dispatch, filterData, deleteAddressStatus]);

  const Address = useSelector((state: any) => state.address.addressList?.data);

  const selectChangeHandler = (e: SelectChangeEvent) => {
    setFilterData((preState) => ({
      ...preState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className={styles.addressBookItemContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.iconContainer}>
          <FaMapMarkerAlt size={39} />
          <h2>{sectionName}</h2>
        </div>
        <button onClick={() => navigate('/agent/address/add')} className={styles.editButton}>
          NOUVELLE ADRESSE
        </button>
      </div>
      <div className={styles.filterContainer}>
        <FormControl sx={{ m: 1, minWidth: 280 }}>
          <InputLabel id="country">Pays</InputLabel>
          <Countryfilter
            filterData={filterData}
            Address={Address}
            selectChangeHandler={selectChangeHandler}
          />
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 280 }}>
          <InputLabel id="region">Région</InputLabel>
          <RegionFilter
            filterData={filterData}
            Address={Address}
            selectChangeHandler={selectChangeHandler}
          />
        </FormControl>
      </div>
      <div className={styles.adressDetails}>
        {Address && Address.length > 0 ? (
          <span>{Address.length} ADRESSES</span>
        ) : (
          <span>0 ADRESSES</span>
        )}
      </div>
      {Address && Address.length > 0 &&
        Address.map((adress: AddressType, index: number) => {
          return (
            <div key={index} className={styles.adressContainer}>
              <Adresses
                city={adress.city}
                country={adress.country}
                region={adress.region}
                postalCode={adress.postalCode}
                firstName={adress.firstName}
                lastName={adress.lastName}
                street={adress.street}
                street2={adress.street2}
                primary={adress.primary}
                id={adress.id}
              />
            </div>
          );
        })}
    </div>
  );
};

export default AddressBookItem;
