import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosService from 'common/axiosService';
import { API, API_HOST_FICODEV } from 'configs/api';

export const getSituationProList = createAsyncThunk(
  'requestDropDowns/getSituationProList',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    const URL = `${API_HOST_FICODEV}${API.requestDropDowns.situationPro}`;

    return axiosService
      .get(URL)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const getTypeBienList = createAsyncThunk(
    'requestDropDowns/getTypeBienList',
    async (_, { fulfillWithValue, rejectWithValue }) => {
      const URL = `${API_HOST_FICODEV}${API.requestDropDowns.typeBien}`;
  
      return axiosService
        .get(URL)
        .then((res) => {
          return fulfillWithValue(res.data);
        })
        .catch((err) => {
          return rejectWithValue(err);
        });
    }
  );

  export const getUsageBienList = createAsyncThunk(
    'requestDropDowns/getUsageBienList',
    async (_, { fulfillWithValue, rejectWithValue }) => {
      const URL = `${API_HOST_FICODEV}${API.requestDropDowns.usageBien}`;
  
      return axiosService
        .get(URL)
        .then((res) => {
          return fulfillWithValue(res.data);
        })
        .catch((err) => {
          return rejectWithValue(err);
        });
    }
  );

  export const getModaliteAchatList = createAsyncThunk(
    'requestDropDowns/getModaliteAchatList',
    async (_, { fulfillWithValue, rejectWithValue }) => {
      const URL = `${API_HOST_FICODEV}${API.requestDropDowns.modaliteAchat}`;
  
      return axiosService
        .get(URL)
        .then((res) => {
          return fulfillWithValue(res.data);
        })
        .catch((err) => {
          return rejectWithValue(err);
        });
    }
  );

  export const getModeAchatList = createAsyncThunk(
    'requestDropDowns/getModeAchatList',
    async (_, { fulfillWithValue, rejectWithValue }) => {
      const URL = `${API_HOST_FICODEV}${API.requestDropDowns.modeAchat}`;
  
      return axiosService
        .get(URL)
        .then((res) => {
          return fulfillWithValue(res.data);
        })
        .catch((err) => {
          return rejectWithValue(err);
        });
    }
  );

  export const getEtatAvancementList = createAsyncThunk(
    'requestDropDowns/getEtatAvancementList',
    async (_, { fulfillWithValue, rejectWithValue }) => {
      const URL = `${API_HOST_FICODEV}${API.requestDropDowns.etatAvancement}`;
  
      return axiosService
        .get(URL)
        .then((res) => {
          return fulfillWithValue(res.data);
        })
        .catch((err) => {
          return rejectWithValue(err);
        });
    }
  );

  export const getDureeCreditSouhaiteList = createAsyncThunk(
    'requestDropDowns/getDureeCreditSouhaiteList',
    async (_, { fulfillWithValue, rejectWithValue }) => {
      const URL = `${API_HOST_FICODEV}${API.requestDropDowns.dureeCreditSouhaite}`;
  
      return axiosService
        .get(URL)
        .then((res) => {
          return fulfillWithValue(res.data);
        })
        .catch((err) => {
          return rejectWithValue(err);
        });
    }
  );
