import { useEffect, useState } from 'react';

import axiosService from 'common/axiosService';
import { API, API_HOST_FICODEV } from 'configs/api';
import LatestProject from 'components/home/latestProject/LatestProject';

interface OneProjectProps {
  key: number;
  title: string;
  s_usage_bien: string;
  s_type_bien: string;
  company: string;
  s_montant_global: string;
  internal_status: string;
  firstName: string;
  lastName: string;
  s_customer_name: string;
  createdAt: string;
  id: string;
  customer: string;
  owner: number;
  s_email_agent: string;
  timesContacted : string;
  lastContactedDate : string;
}

const OneProject: React.FC<OneProjectProps> = ({
  title,
  s_usage_bien,
  s_type_bien,
  s_montant_global,
  internal_status,
  firstName,
  lastName,
  createdAt,
  id,
  customer,
  owner,
  s_email_agent,
  timesContacted,
  lastContactedDate,
}) => {
  const [internalStatus, setInternalStatus] = useState('');
  const [usageBien, setUsageBien] = useState('');
  const [typeBien, setTypeBien] = useState('');
  const [firstNameOwner, setFirstNameOwner] = useState<string>('');
  const [lastNameOwner, setLastNameOwner] = useState<string>('');

  useEffect(() => {
    if (internal_status) {
      const frenchInternalStatus = async () => {
        try {
          const response = await axiosService.get(
            API_HOST_FICODEV + API.requests.requestInternalStatusById(internal_status)
          );
          setInternalStatus(response.data.name);
        } catch (error) {
          console.error(error);
          setInternalStatus('');
        }
      };
      frenchInternalStatus();
    }
    if (s_usage_bien) {
      const frenchGoodsUsage = async () => {
        try {
          const response = await axiosService.get(
            API_HOST_FICODEV + API.requests.requsetUsageBienById(s_usage_bien)
          );
          setUsageBien(response.data.name);
        } catch (error) {
          console.error(error);
          setUsageBien('');
        }
      };
      frenchGoodsUsage();
    }
    if (s_type_bien) {
      const frenchGoodsType = async () => {
        try {
          const response = await axiosService.get(
            API_HOST_FICODEV + API.requests.requsetTypeBienById(s_type_bien)
          );
          setTypeBien(response.data.name);
        } catch (error) {
          console.error(error);
          setTypeBien('');
        }
      };
      frenchGoodsType();
    }
  }, [internal_status, s_usage_bien, s_type_bien]);

  useEffect(() => {
    const fetchData = async () => {
      if (owner) {
        try {
          const onwnerRes = await axiosService.get(`${API_HOST_FICODEV}${API.users}/${owner}`);
          const onwnerData = onwnerRes.data;
          setFirstNameOwner(onwnerData.firstName);
          setLastNameOwner(onwnerData.lastName);
        } catch (error) {
          console.error('Error:', error);
        }
      }
    }
    fetchData();
  }
  , [owner]);


  return (
    <LatestProject
      title={title}
      type={typeBien}
      usage={usageBien}
      amount={s_montant_global}
      status={internalStatus}
      applicantFirstName={firstName}
      applicantLastName={lastName}
      manager={`${firstNameOwner} ${lastNameOwner}`}
      creationDate={createdAt}
      id={id}
      customer={customer}
      s_email_agent={s_email_agent}
      timesContacted={timesContacted}
    lastContactedDate={lastContactedDate}
    />
  );
};

export default OneProject;
