import { useEffect, useState } from 'react';
import { useAppSelector } from 'common/hooks';

import { lineChartsOptions, monthNames } from 'constant/Dummydata';
import { Chart } from 'react-google-charts';

import styles from '../agentHome.module.scss';

type LineChartProps = {
  title: string;
};

const LineChart: React.FC<LineChartProps> = ({ title }) => {
  const requests: any = useAppSelector((state) => state.requests.agentRequests.data);
  const propositions: any = useAppSelector((state) => state.propositions.agentPropositions.data);
  const requestsByAgent: any = useAppSelector((state) => state.requests.requestsByAgent.data);

  const currentDate = new Date();
  const monthIndex = currentDate.getMonth();

  const getMonthAbbreviation = (index: number) => {
    if (index >= 0) {
      return monthNames[index];
    } else {
      return monthNames[11 + index];
    }
  };

  const [lineChartsData, setLineChartsData] = useState<(string | number)[][]>([
    ['', ''],
    [getMonthAbbreviation(monthIndex - 5), 0],
    [getMonthAbbreviation(monthIndex - 4), 0],
    [getMonthAbbreviation(monthIndex - 3), 0],
    [getMonthAbbreviation(monthIndex - 2), 0],
    [getMonthAbbreviation(monthIndex - 1), 0],
    [getMonthAbbreviation(monthIndex), 0],
  ]);

  useEffect(() => {
    if (requests) {
      if (title === 'Nouveaux Projets') {
        setLineChartsData([
          ['', 'Nombre projets'],
          [
            getMonthAbbreviation(monthIndex - 5),
            requests[monthIndex - 5 < 0 ? 11 + monthIndex - 5 : monthIndex - 5].count,
          ],
          [
            getMonthAbbreviation(monthIndex - 4),
            requests[monthIndex - 4 < 0 ? 11 + monthIndex - 4 : monthIndex - 4].count,
          ],
          [
            getMonthAbbreviation(monthIndex - 3),
            requests[monthIndex - 3 < 0 ? 11 + monthIndex - 3 : monthIndex - 3].count,
          ],
          [
            getMonthAbbreviation(monthIndex - 2),
            requests[monthIndex - 2 < 0 ? 11 + monthIndex - 2 : monthIndex - 2].count,
          ],
          [
            getMonthAbbreviation(monthIndex - 1),
            requests[monthIndex - 1 < 0 ? 11 + monthIndex - 1 : monthIndex - 1].count,
          ],
          [getMonthAbbreviation(monthIndex), requests[monthIndex].count],
        ]);
      }
    }
    if (propositions) {
      if (title === 'nouvelles propositions') {
        setLineChartsData([
          ['', 'Nombre projets'],
          [
            getMonthAbbreviation(monthIndex - 5),
            propositions[monthIndex - 5 < 0 ? 11 + monthIndex - 5 : monthIndex - 5].count,
          ],
          [
            getMonthAbbreviation(monthIndex - 4),
            propositions[monthIndex - 4 < 0 ? 11 + monthIndex - 4 : monthIndex - 4].count,
          ],
          [
            getMonthAbbreviation(monthIndex - 3),
            propositions[monthIndex - 3 < 0 ? 11 + monthIndex - 3 : monthIndex - 3].count,
          ],
          [
            getMonthAbbreviation(monthIndex - 2),
            propositions[monthIndex - 2 < 0 ? 11 + monthIndex - 2 : monthIndex - 2].count,
          ],
          [
            getMonthAbbreviation(monthIndex - 1),
            propositions[monthIndex - 1 < 0 ? 11 + monthIndex - 1 : monthIndex - 1].count,
          ],
          [getMonthAbbreviation(monthIndex), propositions[monthIndex].count],
        ]);
      }
    }
    if (requestsByAgent && requestsByAgent?.yearData) {
      if (title === 'propositions accéptées') {
        setLineChartsData([
          ['', 'Nombre projets'],
          [
            getMonthAbbreviation(monthIndex - 5),
            requestsByAgent?.yearData[monthIndex - 5 < 0 ? 11 + monthIndex - 5 : monthIndex - 5]
              .count,
          ],
          [
            getMonthAbbreviation(monthIndex - 4),
            requestsByAgent?.yearData[monthIndex - 4 < 0 ? 11 + monthIndex - 4 : monthIndex - 4]
              .count,
          ],
          [
            getMonthAbbreviation(monthIndex - 3),
            requestsByAgent?.yearData[monthIndex - 3 < 0 ? 11 + monthIndex - 3 : monthIndex - 3]
              .count,
          ],
          [
            getMonthAbbreviation(monthIndex - 2),
            requestsByAgent?.yearData[monthIndex - 2 < 0 ? 11 + monthIndex - 2 : monthIndex - 2]
              .count,
          ],
          [
            getMonthAbbreviation(monthIndex - 1),
            requestsByAgent?.yearData[monthIndex - 1 < 0 ? 11 + monthIndex - 1 : monthIndex - 1]
              .count,
          ],
          [getMonthAbbreviation(monthIndex), requestsByAgent?.yearData[monthIndex].count],
        ]);
      }
    }
  }, [requests, propositions, requestsByAgent]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.lineCharts}>
      <h3 className={styles.lineCharts_title}>{title}</h3>
      {requests && <Chart chartType="Line" data={lineChartsData} options={lineChartsOptions} />}
    </div>
  );
};

export default LineChart;
