import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'common/hooks';

import {
  NewUserModel,
  addUser,
  editingProfile,
} from 'reduxTK/slices/authentication/authAsyncThunk';
import { MdPerson } from 'react-icons/md';

import styles from './userEdit.module.scss';
import { getUserProfilToEdit } from 'reduxTK/slices/users/usersAsyncThunk';

interface FormState {
  [key: string]: boolean | string | null;
}

interface OneAddressState {
  users: {
    userProfilToEdit: {
      data: any;
    };
  };
}

const UserEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();

  const user = useSelector((state: OneAddressState) => state.users.userProfilToEdit.data);

  const userId = params?.id;
  const [formState, setFormState] = useState<FormState>({});
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState<string | null>(null);
  const [disabled, setDisabled] = useState<boolean>(true);


  interface RoleMap {
    [key: number]: string;
  }

  const roleMap: RoleMap = {
    1: 'Admin',
    2: 'Demandeur',
    4: 'Agent',
    5: 'Directeur d\'agence'
  };

  const getDefaultRole = (roles: number[]): number | null => {
    if (!roles || roles.length === 0) {
      return null;
    }
    const defaultRole = roles.find(role => roleMap[role]);
    return defaultRole || null;
  }


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, name: string) => {
    const { value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });

    if (name === 'password') {
      setPassword(value);
      validatePassword(value);
    } else if (name === 'confirmPassword') {
      validateConfirmPassword(value);
    }
  };

  const validatePassword = (value: string) => {
    const hasUpperCase = /[A-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    if (!hasUpperCase || !hasNumber) {
      setPasswordError('Le mot de passe doit comporter une lettre majuscule et un chiffre');
    } else {
      setPasswordError(null);
    }
  };

  const validateConfirmPassword = (value: string) => {
    if (value !== password) {
      setConfirmPasswordError('Le mot de passe et la confirmation du mot de passe doivent être identiques');
    } else {
      setConfirmPasswordError(null);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (userId) {
      dispatch(editingProfile({ id: userId, data: formState }))
        .then(() => dispatch(getUserProfilToEdit(userId)))
        .then(() => navigate('/usersList'));
    } else {
      if (passwordError || confirmPasswordError) {
        return;
      }

      const newUser: NewUserModel = {
        confirmed: true,
        email: formState.email,
        firstName: formState.firstName,
        lastName: formState.lastName,
        birthday: formState.birthday,
        enabled: true,
        username: formState.email,
        password: formState.password, 
        s_email_agent: 'intergestion-superviseur@fibourse.com',
        roles: [formState.role ? Number(formState.role) : 0], 
        customer: localStorage.getItem('customer') ?? '',
      };
      dispatch(addUser(newUser)).then(() => navigate('/usersList'));
    }
  };
  useEffect(() => {
    document.title = "Intergestion - Utilisateur";
  }, []);
  useEffect(() => {
    if (userId) {
      dispatch(getUserProfilToEdit(userId));
    }
  }, [userId , dispatch]);

  useEffect(() => {
    if (user) {
      setFormState({
        firstName: user?.firstName,
        middleName: user?.middleName,
        lastName: user?.lastName,
        namePrefix: user?.namePrefix,
        birthday: user?.birthday,
        email: user?.email,
      });
    }
  }, [user]);

  const userInputs = [
    
    { label: 'Titre :', type: 'text', name: 'namePrefix', defaultValue: user?.namePrefix },
    { label: 'Prénom :', type: 'text', name: 'firstName', defaultValue: user?.firstName },
    { label: 'Deuxième prénom :',type: 'text',name: 'middleName',defaultValue: user?.middleName},
    { label: 'Nom :', type: 'text', name: 'lastName', defaultValue: user?.lastName },
    { label: 'Date de naissance :', type: 'date', name: 'birthday', defaultValue: user?.birthday },
    { label: 'Email :', type: 'text', name: 'email', defaultValue: user?.email },
    {  label: 'Role :',  type: 'select',  name: 'role',  defaultValue: getDefaultRole(user?.roles)}
  ];

  
  const newUserInputs = [
    {
      label: 'Mot de passe',
      type: 'password',
      name: 'password',
      validation: (value: string) => {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasNumber = /\d/.test(value);
        if (!hasUpperCase || !hasNumber) {
            if (value.length < 8) {
            return 'Le mot de passe doit comporter au moins 8 caractères, une lettre majuscule et un chiffre';
            }
            return 'Le mot de passe doit comporter une lettre majuscule et un chiffre';
        }
        return null;
      }
    },
    {
      label: 'Retapez le mot de passe',
      type: 'password',
      name: 'confirmPassword',
    }
  ];

  useEffect(() => {
    if (!userId && (!!passwordError || !!confirmPasswordError || !formState.firstName || !formState.lastName || !formState.birthday || !formState.email || !formState.role)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [userId, passwordError, confirmPasswordError, formState.firstName, formState.lastName, formState.birthday, formState.email, formState.role]);
  

  return (
    <div className={styles.editAddressContainer}>
      <div className={styles.headerContainer}>
        <h2 className={styles.title}>
          <MdPerson /> {userId ? 'MODIFIER UTILISATEUR' : 'AJOUTER UTILISATEUR'}
        </h2>
      </div>
      <div className={styles.formContainer}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formInputs}>
            {userInputs.map((input, index) => (
              <div key={index} className={styles.inputContainer}>
                <label className={styles.label}>{input.label}</label>
                {input.type !== 'select' && (
                  <input
                    className={input.type === 'date' ? styles.dateInput : styles.input}
                    type={input.type}
                    defaultValue={userId ? input.defaultValue : "" }
                    name={input.name}
                    onChange={(e) => handleInputChange(e, input.name)}
                    required={true}
                  />
                )}
                {input.type === 'select' && (
                  <select
                    className={styles.input}
                    name={input.name}
                    onChange={(e) => handleInputChange(e, input.name)}
                    defaultValue={userId ? input.defaultValue : "" }
                    required={true}
                  >
                    <option value="">Sélectionner un rôle</option>
                    {Object.keys(roleMap).map((key) => (
                      <option key={key} value={key}>
                        {roleMap[key as any]}
                      </option>
                    ))}
                  </select>
                )}
                
              </div>
            ))}
            {userId ? null : newUserInputs.map((input, index) => (
              <div key={index} className={styles.inputContainer}>
                <label className={styles.label}>{input.label}</label>
                {input.type !== 'select' && (
                  <input
                    className={input.type === 'date' ? styles.dateInput : styles.input}
                    type={input.type}
                    name={input.name}
                    onChange={(e) => handleInputChange(e, input.name)}
                    required={true}
                  />
                )}
                {input.name === 'password' && passwordError && (
                  <span className={styles.error}>{passwordError}</span>
                )}
                {input.name === 'confirmPassword' && confirmPasswordError && (
                  <span className={styles.error}>{confirmPasswordError}</span>
                )}
              </div>
            ))}
          </div>
          <div className={styles.buttonContainer}>  
            <button
              className={disabled ? styles.disabledButton : styles.button}
              type="submit"
              disabled={disabled}
            >
              Enregistrer
            </button>
            <button
              className={styles.button}
              type="button"
              onClick={() => navigate('/usersList')}
            >
              Annuler
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserEdit;
