import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosService from 'common/axiosService';
import { API, API_HOST_FICODEV } from 'configs/api';


export const getAllCustomers = createAsyncThunk(
    'filter/getAllCustomers',
    async () => {
        try {
            const response = await axiosService.get(API_HOST_FICODEV + API.customer.allCustomer);
            return response.data;
        } catch (error) {
            console.error("Error fetching customers:", error);
            return error;
        }
    }
); 

export const getrfpinternalstatuses = createAsyncThunk(
    'filter/getrfpinternalstatuses',
    async () => {
        try {
            const response = await axiosService.get(API_HOST_FICODEV + API.internalstatuses.all);
            return response.data;
        } catch (error) {
            console.error("Error fetching internal statuses:", error);
            return error;
        }
    }
);

export const getAllApplicants = createAsyncThunk(
    'filter/getAllApplicants',
    async () => {
        try {
            const response = await axiosService.get(API_HOST_FICODEV + API.customer.applicants);
            return response.data;
        } catch (error) {
            console.error("Error fetching applicants:", error);
            return error;
        }
    }
);

export const getAllAgents = createAsyncThunk(
    'filter/getAllAgents',
    async () => {
        try {
            const response = await axiosService.get(API_HOST_FICODEV + API.customer.agents);
            return response.data;
        } catch (error) {
            console.error("Error fetching agents:", error);
            return error;
        }
    }
);


export const getAllPropositionsStatuses = createAsyncThunk(
    'filter/getAllPropositionsStatuses',
    async () => {
        try {
            const response = await axiosService.get(API_HOST_FICODEV + API.propositions.propositionInternalStatus);
            return response.data;
        } catch (error) {
            console.error("Error fetching propositions statuses:", error);
            return error;
        }
    }
);
