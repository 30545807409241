import { createSlice } from '@reduxjs/toolkit';
import { getDureeCreditSouhaiteList, getEtatAvancementList, getModaliteAchatList, getModeAchatList, getSituationProList, getTypeBienList, getUsageBienList } from './requestDropDownsAsyncThunk';

const initialState = {
  situation_pro: {
    isLoading: false,
    error: null as string | null,
    data:null
  },
  type_bien: {
    isLoading: false,
    error: null as string | null,
    data:null
  },
  usage_bien: {
    isLoading: false,
    error: null as string | null,
    data:null
  },
  modalite_achat: {
    isLoading: false,
    error: null as string | null,
    data:null
  },
  mode_achat: {
    isLoading: false,
    error: null as string | null,
    data:null
  },
  etat_avancement: {
    isLoading: false,
    error: null as string | null,
    data:null
  },
  duree_credit_souhaite: {
    isLoading: false,
    error: null as string | null,
    data:null
  },
};

export const requestDropDownsSlice = createSlice({
  name: 'requestDropDowns',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    // situation pro dropdown
    .addCase(getSituationProList.pending, (state) => {
        state.situation_pro.isLoading = true;
      })
      .addCase(getSituationProList.fulfilled, (state, action) => {
        state.situation_pro.isLoading = false;
        state.situation_pro.data = action.payload;
      })
      .addCase(getSituationProList.rejected, (state, action) => {
        state.situation_pro.isLoading = false;
        state.situation_pro.error = action.payload as string | null;
      })

      // type de bien dropdown
    .addCase(getTypeBienList.pending, (state) => {
        state.type_bien.isLoading = true;
      })
      .addCase(getTypeBienList.fulfilled, (state, action) => {
        state.type_bien.isLoading = false;
        state.type_bien.data = action.payload;
      })
      .addCase(getTypeBienList.rejected, (state, action) => {
        state.type_bien.isLoading = false;
        state.type_bien.error = action.payload as string | null;
      })

      // usage de bien dropdown
    .addCase(getUsageBienList.pending, (state) => {
        state.usage_bien.isLoading = true;
      })
      .addCase(getUsageBienList.fulfilled, (state, action) => {
        state.usage_bien.isLoading = false;
        state.usage_bien.data = action.payload;
      })
      .addCase(getUsageBienList.rejected, (state, action) => {
        state.usage_bien.isLoading = false;
        state.usage_bien.error = action.payload as string | null;
      })

      // modalité d'achat dropdown
    .addCase(getModaliteAchatList.pending, (state) => {
      state.modalite_achat.isLoading = true;
    })
    .addCase(getModaliteAchatList.fulfilled, (state, action) => {
      state.modalite_achat.isLoading = false;
      state.modalite_achat.data = action.payload;
    })
    .addCase(getModaliteAchatList.rejected, (state, action) => {
      state.modalite_achat.isLoading = false;
      state.modalite_achat.error = action.payload as string | null;
    })

    // mode d'achat dropdown
    .addCase(getModeAchatList.pending, (state) => {
      state.mode_achat.isLoading = true;
    })
    .addCase(getModeAchatList.fulfilled, (state, action) => {
      state.mode_achat.isLoading = false;
      state.mode_achat.data = action.payload;
    })
    .addCase(getModeAchatList.rejected, (state, action) => {
      state.mode_achat.isLoading = false;
      state.mode_achat.error = action.payload as string | null;
    })

    // etat d'avancement acquisition dropdown
    .addCase(getEtatAvancementList.pending, (state) => {
      state.etat_avancement.isLoading = true;
    })
    .addCase(getEtatAvancementList.fulfilled, (state, action) => {
      state.etat_avancement.isLoading = false;
      state.etat_avancement.data = action.payload;
    })
    .addCase(getEtatAvancementList.rejected, (state, action) => {
      state.etat_avancement.isLoading = false;
      state.etat_avancement.error = action.payload as string | null;
    })

    // durée de crédit souhaitée dropdown
    .addCase(getDureeCreditSouhaiteList.pending, (state) => {
      state.duree_credit_souhaite.isLoading = true;
    })
    .addCase(getDureeCreditSouhaiteList.fulfilled, (state, action) => {
      state.duree_credit_souhaite.isLoading = false;
      state.duree_credit_souhaite.data = action.payload;
    })
    .addCase(getDureeCreditSouhaiteList.rejected, (state, action) => {
      state.duree_credit_souhaite.isLoading = false;
      state.duree_credit_souhaite.error = action.payload as string | null;
    })
  },
});

export default requestDropDownsSlice.reducer;
