import { createSlice } from '@reduxjs/toolkit';
import {
  getAllAffiliations,
 
} from './affiliationsAsyncThunk';
const reduxState = {
  data: null,
  status: 'loading',
  error: null,
};
const initialState = {
  allAffiliations: reduxState,
};
export const affiliationSlice = createSlice({
  name: 'Affiliation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getAllAffiliations
      .addCase(getAllAffiliations.pending, (state) => {
        return { ...state, allAffiliations: { ...state.allAffiliations, status: 'loading' } };
      })
      .addCase(getAllAffiliations.fulfilled, (state, action) => {
        return {
          ...state,
          allAffiliations: {
            ...state.allAffiliations,
            status: 'success',
            data: action.payload.data,
          },
        };
      })
      .addCase(getAllAffiliations.rejected, (state, action) => {
        return {
          ...state,
          allAffiliations: {
            ...state.allAffiliations,
            status: 'failed',
            error: action.error as any,
          },
        };
      })
      
  },  
});

export default affiliationSlice.reducer;
