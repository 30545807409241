import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from './common/hooks';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Footer from './components/layout/footer/Footer';
import Header from './components/layout/header/Header';
import PrivateRoute from 'common/PrivateRoute';
import HomePage from 'pages/public/home/HomePage';
import AuthenticationPage from 'pages/authentication/AuthenticationPage';
import ConfirmRegisterPage from 'pages/authentication/ConfirmRegisterPage';
import ResetPasswordPage from 'pages/authentication/resetpassword/ResetPasswordPage';
import ConfirmResetPasswordPage from 'pages/authentication/resetpassword/ConfirmResetPasswordPage';
import AboutPage from 'pages/public/about/AboutPage';
import TermsPage from 'pages/public/terms/TermsPage';
import LegalPage from 'pages/public/legalPage/LegalPage';
import ProjectsPage from 'pages/public/projects/ProjectsPage';
import HomePageApplicant from 'pages/applicant/home/HomePageApplicant';
import EditProjectPageApplicant from 'pages/applicant/editProject/EditProjectPageApplicant';
import PropositionsList from 'pages/applicant/propositionsList/PropositionsList';
import ProjectsListPage from 'pages/applicant/projectsListPage/ProjectsListPage';
import PropositionsDetailsPage from 'pages/applicant/propositionDetails/PropositionsDetailsPage';

import { ROLE } from 'features/Role.enum';

import calsses from './app.module.scss';
import ProfilApplicantPage from 'pages/applicant/profile/Profile';
import EditProfilApplicantPage from 'pages/applicant/editProfile/EditProfilApplicantPage';
import ProjectFilePage from 'pages/applicant/projectFile/ProjectFilePage';
import ContactUsPage from 'pages/contactUs/ContactUsPage';
import AddressBookPage from 'pages/applicant/addressBook/AddressBookPage';
import AcceptRefuseDetailsPage from './pages/applicant/acceptRefuseDetails/AcceptRefuseDetailsPage';
import HomePageAgent from 'pages/agent/home/HomePageAgent';
import Profile from 'pages/applicant/profile/Profile';
import { getUserProfil } from 'reduxTK/slices/authentication/authAsyncThunk';
import EditAddressPage from 'pages/agent/address/AddEditAddressPage';
import ProjectAddConfirmation from 'pages/applicant/editProject/ProjectAddConfirmation';
import UserListPage from 'pages/agent/usersList/UserListPage';
import UserEdit from 'pages/agent/usersList/userEdit/UserEdit';
import NewPassword from 'components/authentication/resetpassword/NewPassword';
import { initGA, logPageView } from 'components/analytics';
import { initializeGTM } from 'components/cookies/gtm';
import AffiliationList from 'pages/director/affiliation/affiliationList';

interface AxeptioSettings {
  clientId: string;
  cookiesVersion: string;
  // Add other properties if necessary
}

// Declare window object with axeptioSettings
declare global {
  interface Window {
    axeptioSettings : AxeptioSettings;
  }
}
const App: React.FC = () => {
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector((state) => !!state.auth.auth.isAuthenticated);
  const roleId = useAppSelector((state) => state.auth.profile.roleId);

  const [role, setRole] = useState('VISITOR');

  useEffect(() => {
    if (roleId === 1) {
      setRole('ADMIN');
    } else if (roleId === 2) {
      setRole('APPLICANT');
    } else if (roleId === 4) {
      setRole('AGENT');
    } else if ( roleId === 5) {
      setRole('DIRECTOR');
    }
  }, [roleId]);

  useEffect(() => {
    if (!isAuthenticated) {
      setRole('VISITOR');
    } else {
      dispatch(getUserProfil());
    }
  }, [isAuthenticated, dispatch]);
  useEffect(() => {
    initGA();
    logPageView();
  }, []);
//Axeptio cookies
  useEffect(() => {
      window.axeptioSettings = {
        clientId: '668d0e9135888e7f5ddf3caf',
        cookiesVersion: 'intergestion-fr-EU',
      };

      const script = document.createElement('script');
      script.async = true;
      script.src = '//static.axept.io/sdk-slim.js';
      script.onload = () => {
        console.log('Axeptio script loaded successfully.');
      };
      document.body.appendChild(script);
    }
  , []);

  //Google Tag Manager
  useEffect(() => {
    initializeGTM();
  }, []);

  
  return (
    <Router>
      <Header isAuthenticated={isAuthenticated} role={role} />
      <main className={calsses.main}>
        <Routes>
          <Route path="/" element={<HomePage role={role} />} />
          <Route path="/login" element={<AuthenticationPage />} />
          <Route path="/ConfirmRegister" element={<ConfirmRegisterPage />} />
          <Route path="/ResetPassword" element={<ResetPasswordPage />} />
          <Route path="/ConfirmResetPassword" element={<ConfirmResetPasswordPage />} />         
          <Route path="/password/reset/:token" element={<NewPassword />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/legal" element={<LegalPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route
            path="/applicant/home"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                roles={[ROLE.APPLICANT, ROLE.AGENT, ROLE.VISITOR , ROLE.DIRECTOR]}
              >
                <HomePageApplicant />
              </PrivateRoute>
            }
          />
          <Route
            path="/editProjectApplicant"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                roles={[ROLE.APPLICANT, ROLE.ADMIN, ROLE.AGENT, ROLE.VISITOR , ROLE.DIRECTOR]}
              >
                <EditProjectPageApplicant />
              </PrivateRoute>
            }
          />
          <Route
            path="/editProjectApplicant/:id"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                roles={[ROLE.APPLICANT, ROLE.ADMIN, ROLE.AGENT, ROLE.VISITOR , ROLE.DIRECTOR]}
              >
                <EditProjectPageApplicant />
              </PrivateRoute>
            }
          />
          <Route
            path="/ConfirmAddEditProject"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                roles={[ROLE.APPLICANT, ROLE.ADMIN, ROLE.AGENT, ROLE.VISITOR , ROLE.DIRECTOR]}
              >
                <ProjectAddConfirmation />
              </PrivateRoute>
            }
          />
          <Route
            path="/propositionsList"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                roles={[ROLE.APPLICANT, ROLE.ADMIN, ROLE.AGENT, ROLE.VISITOR , ROLE.DIRECTOR]}
              >
                <PropositionsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/ProfileDetail"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                roles={[ROLE.APPLICANT, ROLE.ADMIN, ROLE.AGENT, ROLE.VISITOR , ROLE.DIRECTOR]}
              >
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/projectsList/"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                roles={[ROLE.APPLICANT, ROLE.ADMIN, ROLE.AGENT, ROLE.VISITOR, ROLE.DIRECTOR]}
              >
                <ProjectsListPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/applicant/profile"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                roles={[ROLE.APPLICANT, ROLE.ADMIN, ROLE.AGENT, ROLE.VISITOR , ROLE.DIRECTOR]}
              >
                <ProfilApplicantPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/applicant/profile/edit"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                roles={[ROLE.APPLICANT, ROLE.ADMIN, ROLE.AGENT, ROLE.VISITOR , ROLE.DIRECTOR]}
              >
                <EditProfilApplicantPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/projectFile/:id"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                roles={[ROLE.APPLICANT, ROLE.ADMIN, ROLE.AGENT, ROLE.VISITOR , ROLE.DIRECTOR]}
              >
                <ProjectFilePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/addressBookPage"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                roles={[ROLE.APPLICANT, ROLE.ADMIN, ROLE.AGENT, ROLE.VISITOR , ROLE.DIRECTOR]}
              >
                <AddressBookPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/propositionDetailsPage/:id"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} roles={[ROLE.APPLICANT, ROLE.ADMIN, ROLE.AGENT , ROLE.DIRECTOR]}>
                <PropositionsDetailsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/propositionDetailsPage/accept"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} roles={[ROLE.APPLICANT, ROLE.ADMIN , ROLE.DIRECTOR]}>
                <AcceptRefuseDetailsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/propositionDetailsPage/refuse"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} roles={[ROLE.APPLICANT, ROLE.ADMIN , ROLE.DIRECTOR]}>
                <AcceptRefuseDetailsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/agent/home"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated} roles={[ROLE.ADMIN, ROLE.AGENT , ROLE.DIRECTOR]}>
                <HomePageAgent />
              </PrivateRoute>
            }
          />
          <Route
            path="/agent/address/add"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                roles={[ROLE.ADMIN, ROLE.AGENT, ROLE.APPLICANT , ROLE.DIRECTOR]}
              >
                <EditAddressPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/agent/address/edit/:id"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                roles={[ROLE.ADMIN, ROLE.AGENT, ROLE.APPLICANT , ROLE.DIRECTOR]}
              >
                <EditAddressPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/usersList"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                roles={[ROLE.ADMIN, ROLE.AGENT, ROLE.APPLICANT , ROLE.DIRECTOR]}
              >
                <UserListPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/user/edit/:id"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                roles={[ROLE.ADMIN, ROLE.AGENT, ROLE.APPLICANT , ROLE.DIRECTOR]}
              >
                <UserEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/user/new"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                roles={[ROLE.ADMIN]}
              >
                <UserEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/affiliations"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                roles={[ROLE.ADMIN, ROLE.DIRECTOR]}
              >
                <AffiliationList />
              </PrivateRoute>
            }
          />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
