import React, { useEffect } from 'react'
import Detail from '../Detail';
import { useSelector } from 'react-redux';
import { getRequestSituationPro } from 'reduxTK/slices/requests/requestsAsyncThunk';
import { useAppDispatch } from 'common/hooks';

interface RootState {
    requests: {
      oneRequestById: {
        data: any;
        status: string;
        error: any;
      };
      requestSituationPro: {
        data: any;
        status: string;
        error: any;
      }
    };
    auth: {
        profile: {
          user: {
            addresses: any;
          };
        };
      };
}
const MaritalStatusProjectFile = () => {
const dispatch = useAppDispatch();
    const fileHeader = useSelector((state: RootState) => state.requests.oneRequestById.data);

    const billingAddress = useSelector((state: RootState) => state.auth.profile.user.addresses);

    const situationPro = useSelector((state: RootState) => state.requests.requestSituationPro.data);

    const billingPrimary = billingAddress[0]?.primary === true;
    
    const personalDetails = [
        { label: "Prénom :", value: `${fileHeader?.firstName}` },
        { label: "Nom :", value: `${fileHeader?.lastName}` },
        { label: "Adresse e-mail :", value: `${fileHeader?.email}` },
        { label: "Numéro de téléphone :", value: `${fileHeader?.phone}` },
        { label: "Situation professionnelle :", value: `${situationPro?.name}` },
    ];

    const billingDetails = [
        { label: "Société :", value: billingPrimary ? billingAddress[0]?.organization : "" },
        { label: "Prénom :", value: billingPrimary ? billingAddress[0]?.firstName : "" },
        { label: "Nom :", value: billingPrimary ? billingAddress[0]?.lastName : "" },
        { label: "Adresse :", value: `${fileHeader?.s_street1} ${fileHeader?.s_street2 }`},
        { label: "Code postal :", value:  fileHeader?.s_codepostal },
        { label: "ville :", value: fileHeader?.s_ville },
        { label: "Région :", value: fileHeader?.s_region },
        { label: "Pays :", value:  fileHeader?.s_pays},
    ];

    useEffect(() => {
        dispatch(getRequestSituationPro(fileHeader?.s_situation_pro));
    }, [dispatch, fileHeader?.s_situation_pro]);


    return (
        <div>
            <h3>Etat civil</h3>
            {personalDetails.map((detail, index) => (
                <Detail key={index} label={detail.label} value={detail.value} />
            ))}
            <h3>Adresse de facturation</h3>
            {billingDetails.map((detail, index) => (
                <Detail key={index} label={detail.label} value={detail.value} />
            ))}
        </div>
    )
}

export default MaritalStatusProjectFile