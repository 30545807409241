import { createSlice } from '@reduxjs/toolkit';
import { StaticPagesState } from 'reduxTK/RootState';
import { gettingAboutUsPage, gettingLegalPage, gettingProjectPage, gettingTermsPage,projectListBlock , requestListBlock ,profileBlock ,usersListBlock,adressesBookBlock,adresseListBlock , contactus} from './staticPagesAsyncThunk';

const initialState: StaticPagesState = {
    about: {
        data: null,
        isLoading: false,
        error: null
    },
    legal: {
        data: null,
        isLoading: false,
        error: null
    },
    terms: {
        data: null,
        isLoading: false,
        error: null
    },
    project: {
        data: null,
        isLoading: false,
        error: null
    },
    projectListBlock: {
        data: null,
        isLoading: false,
        error: null
    },
    requestListBlock: {
        data: null,
        isLoading: false,
        error: null
    },
    profileBlock: {
        data: null,
        isLoading: false,
        error: null
    },
    usersListBlock: {
        data: null,
        isLoading: false,
        error: null
    },
    adressesBookBlock: {
        data: null,
        isLoading: false,
        error: null
    },
    adresseListBlock: {
        data: null,
        isLoading: false,
        error: null
    },
    contactus: {
        data: null,
        isLoading: null, 
        error: null
    }

};

export const staticPagesSlice = createSlice({
    name: 'staticPages',
    initialState,
    reducers: {
        // rest contactus is loading to null
        resetContactus: (state) => {
            state.contactus.isLoading = null;
        }
    },
    extraReducers: (builder) => {
        builder
            // aboutPage
            .addCase(gettingAboutUsPage.pending, (state) => {
                state.about.isLoading = true;
            })
            .addCase(gettingAboutUsPage.fulfilled, (state, action) => {
                state.about.isLoading = false;
                state.about.error = null;
                state.about.data = action.payload;
            })
            .addCase(gettingAboutUsPage.rejected, (state, action) => {
                state.about.isLoading = false;
                state.about.error = action.error.message ?? null;
            })
            // termsPage
            .addCase(gettingTermsPage.pending, (state) => {
                state.terms.isLoading = true;
            })
            .addCase(gettingTermsPage.fulfilled, (state, action) => {
                state.terms.isLoading = false;
                state.terms.error = null;
                state.terms.data = action.payload;
            })
            .addCase(gettingTermsPage.rejected, (state, action) => {
                state.terms.isLoading = false;
                state.terms.error = action.error.message ?? null;
            })
            // legalPage
            .addCase(gettingLegalPage.pending, (state) => {
                state.legal.isLoading = true;
            })
            .addCase(gettingLegalPage.fulfilled, (state, action) => {
                state.legal.isLoading = false;
                state.legal.error = null;
                state.legal.data = action.payload;
            })
            .addCase(gettingLegalPage.rejected, (state, action) => {
                state.legal.isLoading = false;
                state.legal.error = action.error.message ?? null;
            })
            // projectPage
            .addCase(gettingProjectPage.pending, (state) => {
                state.project.isLoading = true;
            })
            .addCase(gettingProjectPage.fulfilled, (state, action) => {
                state.project.isLoading = false;
                state.project.error = null;
                state.project.data = action.payload;
            })
            .addCase(gettingProjectPage.rejected, (state, action) => {
                state.project.isLoading = false;
                state.project.error = action.error.message ?? null;
            })
            // projectListBlock
            .addCase(projectListBlock.pending, (state) => {
                state.projectListBlock.isLoading = true;
            })
            .addCase(projectListBlock.fulfilled, (state, action) => {
                state.projectListBlock.isLoading = false;
                state.projectListBlock.error = null;
                state.projectListBlock.data = action.payload;
            })
            .addCase(projectListBlock.rejected, (state, action) => {
                state.projectListBlock.isLoading = false;
                state.projectListBlock.error = action.error.message ?? null;
            })
            // requestListBlock
            .addCase(requestListBlock.pending, (state) => {
                state.requestListBlock.isLoading = true;
            })
            .addCase(requestListBlock.fulfilled, (state, action) => {
                state.requestListBlock.isLoading = false;
                state.requestListBlock.error = null;
                state.requestListBlock.data = action.payload;
            })
            .addCase(requestListBlock.rejected, (state, action) => {
                state.requestListBlock.isLoading = false;
                state.requestListBlock.error = action.error.message ?? null;
            })
            // profileBlock
            .addCase(profileBlock.pending, (state) => {
                state.profileBlock.isLoading = true;
            })
            .addCase(profileBlock.fulfilled, (state, action) => {
                state.profileBlock.isLoading = false;
                state.profileBlock.error = null;
                state.profileBlock.data = action.payload;
            })
            .addCase(profileBlock.rejected, (state, action) => {
                state.profileBlock.isLoading = false;
                state.profileBlock.error = action.error.message ?? null;
            })
            // usersListBlock
            .addCase(usersListBlock.pending, (state) => {
                state.usersListBlock.isLoading = true;
            })
            .addCase(usersListBlock.fulfilled, (state, action) => {
                state.usersListBlock.isLoading = false;
                state.usersListBlock.error = null;
                state.usersListBlock.data = action.payload;
            })
            .addCase(usersListBlock.rejected, (state, action) => {
                state.usersListBlock.isLoading = false;
                state.usersListBlock.error = action.error.message ?? null;
            })
            // adressesBookBlock
            .addCase(adressesBookBlock.pending, (state) => {
                state.adressesBookBlock.isLoading = true;
            })
            .addCase(adressesBookBlock.fulfilled, (state, action) => {
                state.adressesBookBlock.isLoading = false;
                state.adressesBookBlock.error = null;
                state.adressesBookBlock.data = action.payload;
            })
            .addCase(adressesBookBlock.rejected, (state, action) => {
                state.adressesBookBlock.isLoading = false;
                state.adressesBookBlock.error = action.error.message ?? null;
            })
            // adresseListBlock
            .addCase(adresseListBlock.pending, (state) => {
                state.adresseListBlock.isLoading = true;
            })
            .addCase(adresseListBlock.fulfilled, (state, action) => {
                state.adresseListBlock.isLoading = false;
                state.adresseListBlock.error = null;
                state.adresseListBlock.data = action.payload;
            })
            .addCase(adresseListBlock.rejected, (state, action) => {
                state.adresseListBlock.isLoading = false;
                state.adresseListBlock.error = action.error.message ?? null;
            })
            // contactus
            .addCase(contactus.pending, (state) => {
                state.contactus.isLoading = true;
            })
            .addCase(contactus.fulfilled, (state, action) => {
                state.contactus.isLoading = false;
                state.contactus.error = null;
                state.contactus.data = action.payload;
            })
            .addCase(contactus.rejected, (state, action) => {
                state.contactus.isLoading = false;
                state.contactus.error = action.error.message ?? null;
            });
    },
});

export const { resetContactus } = staticPagesSlice.actions;
export default staticPagesSlice.reducer;