import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosService from 'common/axiosService';
import { API, API_HOST_FICODEV } from 'configs/api';
import dayjs from 'dayjs';

export const getAllPropositions = createAsyncThunk('Proposition/getPropositions', async () => {
  try {
    return axiosService.get(`${API_HOST_FICODEV}${API.propositions.allPropositions}`);
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
});

export const getAdminFilteredPropositions = createAsyncThunk(
  'Proposition/getAdminFilteredPropositions',
  async ({ agency, agent, status }: { agency: string; agent: string; status: string }) => {
    try {
      return axiosService.get(
        `${API_HOST_FICODEV}${API.propositions.filteredAdminPropositions(agency, agent, status)}`
      );
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }
);

export const getPropositionsByCustomerID = createAsyncThunk(
  'Proposition/getPropositionsByCustomerID',
  async ({ agency, agent, status , order, sort,endDate,startDate}: { agency: string; agent: string; status: string ; order : string ; sort : string ; startDate : string ; endDate :string}) => {
    try {
      const profileId = localStorage.getItem('profileId') || '';
      const role = localStorage.getItem('role');

      const URL =
        role === 'ADMIN'
          ? `${API_HOST_FICODEV}${API.propositions.filteredAdminPropositions(agency, agent, status ,endDate , startDate ,sort,order)}`
          : `${API_HOST_FICODEV}${API.propositions.propositionbyCustomerUser(profileId, agency, agent, status,endDate , startDate ,sort,order)}`;

      const response = await axiosService.get(URL);

      // Extract only serializable data from the response
      const serializableData = {
        data: response.data, // Assuming response.data is serializable
        status: response.status, // Assuming response.status is serializable
        statusText: response.statusText, // Assuming response.statusText is serializable
      };

      return serializableData;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }
);

export const getOneProposition = createAsyncThunk(
  'Proposition/getOneProposition',
  async (id: string) => {
    try {
      const response = await axiosService.get(
        `${API_HOST_FICODEV}${API.propositions.allPropositions}/${id}`
      );
      // Extract only serializable data from the response
      const serializableData = {
        data: response.data, // Assuming response.data is serializable
        status: response.status, // Assuming response.status is serializable
        statusText: response.statusText, // Assuming response.statusText is serializable
      };

      return serializableData;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }
);

export const getAgentQuotesByRequests = createAsyncThunk(
  'Requests/getAgentQuotesByRequests',
  async (requestsId: any) => {
    try {
      const response = await axiosService.get(
        `${API_HOST_FICODEV + API.propositions.allPropositions}?request=${requestsId}&page=1&limit=1000`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const getAgentPropositions = createAsyncThunk(
  'Proposition/getAgentPropositions',
  async (requestsIds: string) => {
    const formatDateToMonth = (date: Date) => date.getMonth();

    const countOccurrences = (array: any) => {
      const countMap: Record<string, number> = {};
      array.forEach((obj: any) => {
        const month = formatDateToMonth(dayjs(obj.createdAt).toDate());
        countMap[month] = (countMap[month] || 0) + 1;
      });
      return countMap;
    };

    const convertToDesiredFormat = (countMap: Record<string, number>) => {
      const resultArray = [];
      for (let month = 0; month <= 11; month++) {
        const count = countMap[month] || 0;
        resultArray.push({ month: month, count });
      }
      return resultArray;
    };

    try {
      const response = await axiosService.get(
        `${API_HOST_FICODEV}${API.propositions.allPropositions}?request=${requestsIds}`
      );

      const occurrences = countOccurrences(response.data);

      return convertToDesiredFormat(occurrences);
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }
);


export const acceptProposition = createAsyncThunk(
  'Proposition/acceptProposition',
  async (data2: any) => {
    const data = {
      "internal_status":"accepted"
    };
    try {
      const response = await axiosService.patch(
        `${API_HOST_FICODEV}${API.propositions.allPropositions}/${data2.propositionId}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json', // Specify JSON content type
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }
);

export const rejectProposition = createAsyncThunk(
  'Proposition/rejectProposition',
  async (data2: any) => {
    const data = {
      "internal_status":"declined"
    };
    try {
      const response = await axiosService.patch(
        `${API_HOST_FICODEV}${API.propositions.allPropositions}/${data2.propositionId}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json', 
          },
        }
      
      );
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }
);