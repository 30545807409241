import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { useNavigate } from 'react-router-dom';

import BarChart from './barCharts/BarChart';
import LineChart from './lineCharts/LineChart';
import { topAgents } from 'constant/Dummydata';

import {
  getAgentRequests,
  getAllRequestsByAgent,
  getChildrenRequest,
} from 'reduxTK/slices/requests/requestsAsyncThunk';
import { getAgentPropositions } from 'reduxTK/slices/propositions/propositionsAsyncThunk';

import styles from './agentHome.module.scss';

const AgentHome = () => {
  const dispatch = useAppDispatch();
  const requestsByAgent: any = useAppSelector((state) => state.requests.requestsByAgent.data);

  const [agentRequestIds, setAgentRequestIds] = useState('');

  const navigate = useNavigate();
    const isAgent = localStorage.getItem('role') === 'AGENT';
    

  useEffect(() => {
    let arg = null
    dispatch(getAgentRequests());
    isAgent
      ? dispatch(getAllRequestsByAgent(arg))
      : dispatch(getChildrenRequest()).then((res) => dispatch(getAllRequestsByAgent(res?.payload)));
    
  }, [dispatch ,isAgent]);

  useEffect(() => {
    if (requestsByAgent && requestsByAgent?.data) {
      requestsByAgent?.data?.map((req: any) => {
        if (agentRequestIds === '') {
          setAgentRequestIds((prevState: any) => `${req.id}`);
          return null;
        } else {
          setAgentRequestIds((prevState: any) => `${prevState},${req.id}`);
          return null;
        }
      });
    }
  }, [requestsByAgent]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (agentRequestIds !== '') {
      dispatch(getAgentPropositions(agentRequestIds));
    }
  }, [agentRequestIds, dispatch]);

  return (
    <div className={styles.agentHome}>
      <section className={styles.projects}>
        <div className={styles.projects_charts}>
          <LineChart title="Nouveaux Projets" />
          <BarChart title="Projets ouverts" />
          <BarChart title="Projets fermés" />
        </div>
        <button className={styles.projects_btn} onClick={() => navigate('/projectsList')}>ACCEDEZ AU PROJETS</button>
      </section>
      <section>
        <div className={styles.proposals}>
          <div className={styles.proposals_charts}>
            <LineChart title="nouvelles propositions" />
            <LineChart title="propositions accéptées" />
          </div>
          <button className={styles.proposals_btn} onClick={() => navigate('/propositionsList')}>ACCEDEZ AU PROPOSITIONS</button>
        </div>
      </section>
      {!isAgent && (
      <section className={styles.topAgents}>
        {topAgents.map((agentGroup, index) => (
          <div key={index} className={styles.topAgents_group}>
            <h3 className={styles.topAgents_group_title}>{agentGroup.title}</h3>
            <h5 className={styles.topAgents_group_subtitle}>{agentGroup.subtitle}</h5>
            {agentGroup.agents.map((agent, index) => (
              <div key={index} className={styles.topAgents_agent}>
                <div className={styles.topAgents_agent_details}>
                  <span className={styles.topAgents_agent_icon}>{agent.icon}</span>
                  <span className={styles.topAgents_agent_name}>{agent.name}</span>
                </div>
                <span className={styles.topAgents_agent_note}>{agent.note}</span>
              </div>
            ))}
          </div>
        ))}
      </section>
      )}
    </div>
  );
};

export default AgentHome;
