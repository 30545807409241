import React, { useState } from 'react';
import styles from '../Authentication.module.scss';
import { useNavigate } from 'react-router-dom';
import { passwordReset } from 'reduxTK/slices/authentication/authAsyncThunk';
import { useAppDispatch } from 'common/hooks';
import { useParams } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

const NewPassword: React.FC = () => {
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    
    const { token } = useParams<{ token: string }>();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (newPassword !== confirmPassword) {
            setErrorMessages(['Les mots de passe ne correspondent pas']);
            return;
        }
        const tokenToSent = token?.split('=')[1] ?? ''; 
        await dispatch(passwordReset({ token: tokenToSent, password: newPassword }));
        navigate('/login');
    };
    const handleReturn = () => {
        navigate('/login');
    };

    return (
        <div className={styles.authenticationContainer}>
            <h2>
                NOUVEAU MOT DE PASSE
            </h2>
            <form onSubmit={handleSubmit}>
                <div className={styles.inputGroup}>
                    <label htmlFor="newPassword">
                        Nouveau mot de passe *
                    </label>
                    <input className={styles.inputPassword} id="newPassword" {
                        ...showPassword ? { type: 'text' } : { type: 'password' }

                    } placeholder='Nouveau mot de passe'
                        value={newPassword} onChange={(e) => setNewPassword(e.target.value)}
                        required />
                    <div
                        className={styles.eyeIconContainer}
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </div>

                </div>
                <div className={styles.inputGroup}>
                    <label htmlFor="confirmPassword">
                        Confirmer le mot de passe *
                    </label>
                    <input className={styles.inputPassword}  id="confirmPassword" {
                        ...showConfirmPassword ? { type: 'text' } : { type: 'password' }
                    } placeholder='Confirmer le mot de passe'
                        value={
                            confirmPassword
                        } onChange={(e) => setConfirmPassword
                            (e.target.value)}
                        required />
                    <div
                        className={styles.eyeIconContainer}
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}

                    </div>
                </div>
                <div className={styles.errorMessages}>
                    {
                        errorMessages.map((message, index) => (
                            <p key={index}>{message}</p>
                        ))
                    }
                </div>
                <div className={styles.inputGroup}>
                    <span className={styles.spacer_30}></span>
                    <div className={styles.buttonGroup}>
                        <button type="submit">
                            CONFIRMER
                        </button>
                        <button type="button" onClick={handleReturn}>RETOUR</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default NewPassword;