import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosService from 'common/axiosService';
import { API, API_HOST_FICODEV } from 'configs/api';



export const getFile1 = createAsyncThunk(
    'Files/getFile1',
    async (id: string ) => {
        try {
            const res = await axiosService.get(`${API_HOST_FICODEV}${API.propositions.allPropositions}/${id}/s_quote_att_1`);
            return res.data;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }
);

export const getFile2 = createAsyncThunk(
    'Files/getFil2s',
    async (id: string ) => {
        try {
            const res = await  axiosService.get(`${API_HOST_FICODEV}${API.propositions.allPropositions}/${id}/s_quote_att_2`);
            return res.data;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }
);

export const getFile3 = createAsyncThunk(
    'Files/getFile3',
    async (id: string ) => {
        try {
            const res = await axiosService.get(`${API_HOST_FICODEV}${API.propositions.allPropositions}/${id}/s_quote_att_3`);
            return res.data;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }
);


