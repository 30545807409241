import BlockTitle from '../../blockTitle/BlockTitle';
import styles from './commentObtenirPret.module.scss';
import imgRevenus from 'assets/icons/revenus.png';
import imgFiscalite from 'assets/icons/fiscalite.png';
import imgSouscription from 'assets/icons/souscription.png';
import imgMobile from 'assets/icons/mobile.png';
import imgAssemblee from 'assets/icons/assemblee.png';
import logoPrexange from 'assets/logos/logo-prexange.png';


const CommentObtenirPret = () => {
  return (
    <div className={styles.commentObtenirPretContainer}>

      <BlockTitle title="Comment ?" subtitle="Obtenir un prêt en quelques clics !" />

      <p className={styles.intro}>En partenariat avec <img src={logoPrexange} alt="prexange" /></p>

      <div>
        <ol>
          <li>
            <span className={styles.counter}>Etape 1</span>
            <img src={imgRevenus} alt="Demande de financement" />
            <h3>Demande de financement</h3>
            <p>Vous remplissez le formulaire de demande de financement.</p>
          </li>

          <li>
            <span className={styles.counter}>Etape 2</span>
            <img src={imgFiscalite} alt="Étude de votre situation" />
            <h3>Étude de votre situation</h3>
            <p>Un expert étudie la faisabilité de votre demande en fonction de votre situation.</p>
          </li>

          <li>
            <span className={styles.counter}>Etape 3</span>
            <img src={imgSouscription} alt="Proposition de prêt" />
            <h3>Proposition de prêt</h3>
            <p>Si votre demande est acceptée, vous recevez une proposition de prêt de notre partenaire bancaire.</p>
          </li>

          <li>
            <span className={styles.counter}>Etape 4</span>
            <img src={imgMobile} alt="Réception de votre dossier" />
            <h3>Réception de votre dossier</h3>
            <p>Vous nous transmettez votre dossier complet, après signature de votre mandat de financement.</p>
          </li>

          <li>
            <span className={styles.counter}>Etape 5</span>
            <img src={imgAssemblee} alt="Signature de l’offre de prêt" />
            <h3>Signature de l’offre de prêt</h3>
            <p>Vous signez votre offre de prêt électroniquement, après un délai de réflexion règlementaire de 10
              jours.</p>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default CommentObtenirPret;