import React, { useState } from 'react';
import styles from '../Authentication.module.scss';
import { useNavigate } from 'react-router-dom';
import { passwordRequest } from 'reduxTK/slices/authentication/authAsyncThunk';
import { useAppDispatch } from 'common/hooks';

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const dispatch = useAppDispatch();

  const handleSubmit = (event: React.FormEvent) => {
    // Handle forgotten password logic here
    dispatch(passwordRequest({ email, front_website: 'INTERGESTION' }));
    navigate('/ConfirmResetPassword');
  };
  const handleReturn = () => {
    navigate('/login');
  };

  return (
    <div className={styles.authenticationContainer}>
      <h2>MOT DE PASSE OUBLIÉ ?</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.inputGroup}>
          <label htmlFor="email">Adresse e-mail *</label>
          <input id="email" type="email" placeholder='Adresse e-mail' value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div className={styles.inputGroup}>
          <span className={styles.spacer_30}></span>
          <div className={styles.buttonGroup}>
            <button type="submit">GÉNÉRER UN NOUVEAU MOT DE PASSE</button>
            <button type="button" onClick={handleReturn}>RETOUR</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;