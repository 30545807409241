import BlockTitle from '../blockTitle/BlockTitle';
import styles from './demandePret.module.scss';

const DemandePret = () => {
    return (
        <div className={styles.demandePretMainContainer}>

          <BlockTitle title="Alors ?" subtitle="Démarrez votre demande de prêt" />

          <div className={styles.buttonsContainer}>
            <a href='/login' type='button' className={styles.createAccount} style={{ textDecoration: 'none' }}>Créer un compte</a>
            <a href='/login' type='button' className={styles.loginUser} style={{ textDecoration: 'none' }}>Se connecter</a>
          </div>
        </div>
    )
}

export default DemandePret