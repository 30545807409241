import { useNavigate } from 'react-router-dom';
import styles from './profileDetails.module.scss';
import { FaCheck } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import { MdPerson } from 'react-icons/md';

interface ProfileDetailsProps {
  email: string;
  namePrefix: string;
  firstName: string;
  middleName: string;
  lastName: string;
  birthday: string;
  roles: string[];
  confirmed: boolean;
  enabled: boolean;
}

const ProfileDetails = ({
  email,
  namePrefix,
  firstName,
  middleName,
  lastName,
  birthday,
  roles,
  confirmed,
  enabled,
}: ProfileDetailsProps) => {
  const navigate = useNavigate();

  const profilFileds = [
    { label: 'Titre', value: namePrefix },
    { label: 'Prénom', value: firstName },
    { label: 'Deuxième prénom', value: middleName },
    { label: 'Nom', value: lastName },
    { label: 'Data de naissance', value: birthday },
    { label: 'Email', value: email },
    { label: 'Rôles', roles },
    { label: 'Status', confirmed: confirmed, enabled: enabled },
  ];

  const displayRole = (role: string) => {
    switch (`${role}`) {
      case '1':
        return 'Administrateur';
      case '2':
        return 'Demandeur';
      case '4':
        return 'Agent';
      default:
        break;
    }
  };

  return (
    <div className={styles.profileDetailsContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.profileLogo}>
          <MdPerson size={39} />
        </div>
        <h2>VOTRE PROFIL</h2>
      </div>
      <div>
        {profilFileds.map((field, index) => (
          <div key={index} className={styles.flex}>
            <label className={styles.label}>{field.label}</label>
            <span className={styles.value}>{field.value}</span>
            {field.roles && (
              <div className={styles.list}>
                {field.roles.map((role, index) => (
                  <span key={index}>{displayRole(role)}</span>
                ))}
              </div>
            )}
            <div className={styles.activity}>
              {field.confirmed && (
                <div className={styles.confirmed}>
                  {field.confirmed ? (
                    <span>
                      <FaCheck color="green" size={15} /> Confirmé
                    </span>
                  ) : (
                    <span>
                      <ImCross color="red" size={15} /> Non confirmé
                    </span>
                  )}
                </div>
              )}
              {field.enabled && (
                <div className={styles.confirmed}>
                  {field.enabled ? (
                    <span>
                      <FaCheck color="green" size={15} /> Activé
                    </span>
                  ) : (
                    <span>
                      <ImCross color="red" size={15} /> Non activé
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <button className={styles.editButton} onClick={() => navigate('/applicant/profile/edit')}>
        MODIFIER LE PROFIL
      </button>
    </div>
  );
};

export default ProfileDetails;
