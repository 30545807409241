import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  getOneRequest,
  getRequestAssignedUser,
  getRequestCustomer,
} from 'reduxTK/slices/requests/requestsAsyncThunk';
import HeaderProjectFile from 'components/projectFile/header/HeaderProjectFile';
import AccordionNavbar from 'components/accordion/Accordion';
import styles from './projectFilePage.module.scss';

const ProjectFilePage = () => {
  const dispatch = useDispatch();

  const pathname = window.location.pathname;
  const match = pathname.match(/\/(\d+)\/?$/);
  const id = match ? match[1] : null;
  useEffect(() => {
    document.title = "Intergestion - Projet";
  }, []);
  useEffect(() => {
    dispatch(getOneRequest(id) as any);
    dispatch(getRequestCustomer(id) as any);
    dispatch(getRequestAssignedUser(id) as any);
  }, [dispatch, id]);
  
  return (
    <div className={styles.projectFileContainer}>
      <HeaderProjectFile />
      <AccordionNavbar />
    </div>
  );
};

export default ProjectFilePage;
