import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.scss';

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer_links}>
        <Link to="/about" className={styles.nav__item}>Qui sommes nous</Link>
        <Link to="/legal" className={styles.nav__item}>Mentions légales</Link>
        <Link to="/terms" className={styles.nav__item}>CGU / CGV</Link>
      </div>
      <div>
        © 2024 Prexange. Tous droits réservés.
      </div>
    </footer>
  );
};

export default Footer;