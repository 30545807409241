import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_HOST, API } from 'configs/api';

export const gettingAboutUsPage = createAsyncThunk(
    'staticPages/gettingAboutUsPage',
    async () => {
        const response = await axios.get(`${API_HOST}${API.static.ABOUT}`);
        return response.data.data;
    }
);

export const gettingTermsPage = createAsyncThunk(
    'staticPages/gettingTermsPage',
    async () => {
        const response = await axios.get(`${API_HOST}${API.static.TERMS}`);
        return response.data.data;
    }
);

export const gettingLegalPage = createAsyncThunk(
    'staticPages/gettingLegalPage',
    async () => {
        const response = await axios.get(`${API_HOST}${API.static.LEGAL}`);
        return response.data.data;
    }
);

export const gettingProjectPage = createAsyncThunk(
    'staticPages/gettingProjectPage',
    async () => {
        const response = await axios.get(`${API_HOST}${API.static.PROJECT}`);
        return response.data.data;
    }
);

export const projectListBlock = createAsyncThunk( 'staticPages/projectListBlock', async () => {
    const response = await axios.get(`${API_HOST}${API.static.projectList}`);
    return response.data.data;
});


//requestList

export const requestListBlock = createAsyncThunk( 'staticPages/requestListBlock', async () => {
    const response = await axios.get(`${API_HOST}${API.static.requestList}`);
    return response.data.data;
});

//profile

export const profileBlock = createAsyncThunk( 'staticPages/profileBlock', async () => {
    const response = await axios.get(`${API_HOST}${API.static.profile}`);
    return response.data.data;
})

//usersList

export const usersListBlock = createAsyncThunk( 'staticPages/usersListBlock', async () => {
    const response = await axios.get(`${API_HOST}${API.static.usersList}`);
    return response.data.data;
})

//adressesBook 

export const adressesBookBlock = createAsyncThunk( 'staticPages/adressesBookBlock', async () => {
    const response = await axios.get(`${API_HOST}${API.static.adressesBook}`);
    return response.data.data;
})

//adresseList

export const adresseListBlock = createAsyncThunk( 'staticPages/adresseListBlock', async () => {
    const response = await axios.get(`${API_HOST}${API.static.adresseList}`);
    return response.data.data;
})


export const contactus = createAsyncThunk('staticPages/contact', async (data:object) => {
    const response = await axios.post(`${API_HOST}${API.static.contact}`, data);
    return response.data.data;
}
);