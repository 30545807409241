import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosService from 'common/axiosService';
import { API, API_HOST_FICODEV } from 'configs/api';

export const getUserProfile = createAsyncThunk('user/getUserProfile', async () => {
    const email = localStorage.getItem('email');
    const URL = `${API_HOST_FICODEV}${API.auth.user}?email=${email}`;
    try {
        const res = await axiosService.get(URL);
        return res.data[0];
    } catch (error) {
        console.log('Error:', error);
        throw error;
    }
}
);