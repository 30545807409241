import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'reduxTK/store/store';
import { editProjectContent } from 'reduxTK/slices/editProjectContent/editProjectContentAsyncThunk';
import CivilStatusBlock from 'components/editProjectApplicant/civilStatusBlock/CivilStatusBlock';
import ProjectPurposeBlock from 'components/editProjectApplicant/projectPurposeBlock/ProjectPurposeBlock';
import ProjectBlock from 'components/editProjectApplicant/projectBlock/ProjectBlock';
import AdviceBlock from 'components/editProjectApplicant/adviseBlock/AdviceBlock';

import styles from './editProjectPageApplicant.module.scss';
import {
  addFinancialRequest,
  editingRequest,
  gettingOneRequest,
} from 'reduxTK/slices/requests/requestsAsyncThunk';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { deleteStoredData } from 'reduxTK/slices/requests/requestsSlice';

interface RootState {
  editProjectContent: {
    data: {
      title: string;
      content: string;
    };
    isLoading: boolean;
    error: Error | null;
  };
}

interface RequestRootState {
  requests: {
    oneRequest: {
      data: any;
      status: string;
    };
    addingRequest: {
      status: string;
    };
    editingOneRequest: {
      status: string;
    };
  };
}

const EditProjectPageApplicant = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const fetchEditProjectContent = useSelector((state: RootState) => state.editProjectContent?.data);
  const addingRequestStatus = useSelector(
    (state: RequestRootState) => state.requests.addingRequest.status
  );
  const { data } = useSelector((state: RequestRootState) => state.requests.oneRequest);
  const { status } = useSelector((state: RequestRootState) => state.requests.editingOneRequest);

  const profileId = localStorage.getItem('profileId');

  const requestId = params?.id;
  useEffect(() => {
    document.title = "Intergestion - Projet";
  }, []);
  useEffect(() => {
    dispatch(editProjectContent());
  }, [dispatch]);

  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    s_situation_pro: '265f5fab',
    s_type_bien: 'achat_fond_de_commerce',
    s_mode_achat: '2326d790',
    s_modalite_achat: 'achat_avec_un_co_emprunteur',
    s_usage_bien: '25a94479',
    s_etat_avancement: '6f66da25',
    s_montant_impot: '',
    s_montant_global: '',
    s_montant_travaux: '',
    s_montant_apport: '',
    s_montant_credit: '',
    s_acquisition: '',
    note: '',
    s_duree_credit_souhait: '10_ans',
    s_montant_mens_max: '',
    customerUser: profileId,
    // *****A VERIFIER SI CES VALEURS DOIVENT ETRE CHANGE PLUTARD*****
    s_email_agent: 'intergestion-superviseur@fibourse.com',
    company: 'Inspire',
    customer: 59,
    owner: 124,
  });

  const handleInputChange = (name: string, value: any) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (requestId) {
      dispatch(editingRequest({ data: formData, id: requestId }));
    } else {
      dispatch(addFinancialRequest(formData));
    }
  };

  useEffect(() => {
    if (requestId) {
      dispatch(gettingOneRequest(requestId));
    }
  }, [requestId, dispatch]);

  useEffect(() => {
    if (addingRequestStatus === 'success' || status === 'success') {
      navigate('/ConfirmAddEditProject');
    }
  }, [status, addingRequestStatus, navigate]);

  useEffect(() => {
    if (data) {
      setFormData({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        s_situation_pro: data.s_situation_pro,
        s_type_bien: data.s_type_bien,
        s_mode_achat: data.s_mode_achat,
        s_modalite_achat: data.s_modalite_achat,
        s_usage_bien: data.s_usage_bien,
        s_etat_avancement: data.s_etat_avancement,
        s_montant_impot: data.s_montant_impot,
        s_montant_global: data.s_montant_global,
        s_montant_travaux: data.s_montant_travaux,
        s_montant_apport: data.s_montant_apport,
        s_montant_credit: data.s_montant_credit,
        s_acquisition: data.s_acquisition,
        note: data.note,
        s_duree_credit_souhait: data.s_duree_credit_souhait,
        s_montant_mens_max: data.s_montant_mens_max,
        customerUser: profileId,
        // *****A VERIFIER SI CES VALEURS DOIVENT ETRE CHANGE PLUTARD*****
        s_email_agent: '',
        company: 'Inspire',
        customer: 59,
        owner: 124,
      });
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(deleteStoredData());
    };
  }, [dispatch]);

  return (
    <div className={styles.EditProjectPageContainer}>
      <form onSubmit={handleSubmit}>
        <h2>{fetchEditProjectContent?.title}</h2>
        <p dangerouslySetInnerHTML={{ __html: fetchEditProjectContent?.content }} />
        <CivilStatusBlock formData={formData} handleInputChange={handleInputChange} />
        <ProjectPurposeBlock formData={formData} handleInputChange={handleInputChange} />
        <ProjectBlock formData={formData} handleInputChange={handleInputChange} />
        <AdviceBlock formData={formData} handleInputChange={handleInputChange} />
        <button type="submit" className={styles.btn}>
          {(addingRequestStatus === 'loading' || status === 'loading') && (
            <CircularProgress size={15} className={styles.loader} />
          )}
          <span>{requestId ? 'MODIFIER VOTRE PROJET' : 'SOUMETTRE VOTRE PROJET'}</span>
        </button>
      </form>
    </div>
  );
};

export default EditProjectPageApplicant;
