// dummydata for intedgration
import { LuFile } from 'react-icons/lu';
import { IoStarOutline } from 'react-icons/io5';

export const latestProjects = [
  {
    title: 'VOTRE DERNIER PROJET',
    usage: 'Résidence Principale',
    name: 'Robert Da Silva',
    type: 'Terrain + Construction',
    amount: '450000€',
    status: 'En cours',
    applicantFirstName: 'Christophe',
    applicantLastName: 'Debosque',
    manager: 'Gregory Hernandez',
    creationDate: '12/12/2021',
  },
  {
    title: 'VOTRE DERNIER PROJET',
    usage: 'Résidence Principale',
    name: 'Robert Da Silva',
    type: 'Terrain + Construction',
    amount: '450000€',
    status: 'En cours',
    applicantFirstName: 'Christophe',
    applicantLastName: 'Debosque',
    manager: 'Gregory Hernandez',
    creationDate: '12/12/2021',
  },
];

export const files = [
  { icon: <LuFile />, fileNum: 1 },
  { icon: <LuFile />, fileNum: 2 },
  { icon: <LuFile />, fileNum: 3 },
];

export const titles = [
  { text: 'Résidence Principale', icon: '/' },
  { text: 'Terrain + Construction', icon: '<RiZoomInLine />' },
];

export const details = [
  { label: 'Demandeur:', value: 'Christophe Debosque' },
  { label: 'Via', value: 'Robert Da Silva' },
  { label: 'Dossier géré par:', value: 'Gregory Hernandez' },
  { label: 'Montant à financier', value: '450000€' },
];

export const newPropositions = [
  { type: 'title', label: '', value: 'Proposition CEPAC' },
  { type: 'title', label: 'REF:', value: '6789AJ' },
  { type: 'date', label: 'Proposée le', value: '01/01/2023' },
  { type: 'date', label: "Valide jusqu'ou", value: '01/01/2023' },
];

export const description = [
  {
    label: 'Description de la proposition',
    value:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur, officiis.Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur, officiis.Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur, officiis.Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur, officiis.',
  },
];



export const acceptParagraph =
  "Vous étes sur le point d'accpeter la proposition. Cette opération est irreversible depuis votre espace.";
export const acceptConfirmation = "Confirmez-vous l'acceptation de cette proposition?";
export const rejectConfirmation = 'Confirmez-vous le refus de cette proposition?';
export const rejectParagraph =
  'Vous étes sur le point de refuser la proposition. Cette opération est irreversible depuis votre espace.';

export const topAgents = [
  {
    title: 'TOP 5 AGENTS-MENSUEL',
    subtitle: 'Par dossiers financiés ce mois',
    agents: [
      { icon: <IoStarOutline />, name: 'Robert Durand', note: 10 },
      { icon: <IoStarOutline />, name: 'Norbert Durand', note: 9 },
      { icon: <IoStarOutline />, name: 'Sébastien Rodier', note: 5 },
      { icon: <IoStarOutline />, name: 'Bernard Borde', note: 4 },
      { icon: <IoStarOutline />, name: 'Robert Durand', note: 4 },
    ],
  },
  {
    title: 'TOP 5 AGENTS-ANNUEL',
    subtitle: 'Par dossiers financiés cette année',
    agents: [
      { icon: <IoStarOutline />, name: 'Robert Durand', note: 10 },
      { icon: <IoStarOutline />, name: 'Norbert Durand', note: 9 },
      { icon: <IoStarOutline />, name: 'Sébastien Rodier', note: 5 },
      { icon: <IoStarOutline />, name: 'Bernard Borde', note: 4 },
      { icon: <IoStarOutline />, name: 'Robert Durand', note: 4 },
    ],
  },
];

export const barChartsOptions = {
  width: 400,
  height: 300,
  bar: { groupWidth: '80%' },
  legend: { position: 'none' },
};

export const lineChartsOptions = {
  width: 300,
  height: 300,
  legend: { position: 'none' },
};

export const Department = [
  { value: 'FR-75', label: 'PARIS' },
  { value: 'TN-11', label: 'TUNIS' },
];

export const Countries = [
  { value: 'FR', label: 'FRANCE' },
  { value: 'TN', label: 'TUNISIE' },
];

export const checkboxes = [
  { label: 'Facturation', name: 'billing' },
  { label: 'Livraison', name: 'delivery' },
  { label: 'Facturation par défaut', name: 'defaultBilling' },
  { label: 'Livraison par défaut', name: 'defaultDelivery' },
];

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];


export const constactUsInputs = [
  {
    name: 'firstName',
    label: 'Prénom',
    type: 'text',
    required: true,
  },
  {
    name: 'lastName',
    label: 'Nom',
    type: 'text',
    required: true,
  },
  {
    name: 'organization',
    label: 'Nom de l’organisation',
    type: 'text',
    required: true,
  },
  {
    name: 'contactBy',
    label: 'Méthode de contact préférée',
    type: 'select',
    options: [
      { value: 'email', label: 'Email' },
      { value: 'phone', label: 'Téléphone' },
      { value: 'emailAndPhone', label: 'e-mail et téléphone' },
    ],
    defaultValue: 'email',
    required: true,
  },
  {
    name: 'email',
    label: 'Email',
    type: 'email',
    required: true,
  },
  {
    name: 'phoneNumber',
    label: 'Téléphone',
    type: 'tel',
    required: true,
  },
  {
    name: 'reason',
    label: 'Raison du contact',
    type: 'select',
    options: [
      { label: 'Choisir la raison du contact...' ,value: ""  },
      { label: 'Je voudrais en savoir plus sur vous', value: 'informationRequest' },
      { label: 'Je recherche un crédit immobilier', value: 'mortgageRequest' },
      { label: 'Je suis utilisateur et j\'ai besoin d\'assistance', value: 'userSupport' },
      { label: 'Je suis agent immobilier et je recherche un partenariat', value: 'partnershipRequest' },
      { label: 'Je représente une banque et je recherche un partenariat', value: 'bankPartnershipRequest' },
      { label: 'Autre', value: 'other' },
    ],
    required: true,
  },
  {
    name: 'comment',
    label: 'Message',
    type: 'textarea',
    required: true,
  }
];
