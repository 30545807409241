import { createSlice } from '@reduxjs/toolkit';
import {
  login,
  registerUser,
  getUserProfil,
  editingProfile,
  getOneAddress,
  editingOneAddress,
  addingOneAddress,
  deletingOneAddress,
  addUser ,
  passwordRequest,
  updatePassword
} from './authAsyncThunk';

const initialState = {
  auth: {
    isAuthenticated: localStorage.getItem('token'),
    isLoading: false,
    error: null as string | null,
    email: localStorage.getItem('email'),
    isLoggedIn: false,
  },
  register: {
    isLoading: false,
    error: null as string | null,
    data: null as any,
  },
  profile: {
    isLoading: false,
    error: null as string | null,
    user: null,
    roleId: null,
  },
  oneAddress: {
    isLoading: false,
    error: null as string | null,
    data: null,
    addEditSuccess: false,
  },
  newUser:{
    isLoading: false,
    error: null as string | null,
    data: null as any,
  },
  editSuccess: false,
  role: localStorage.getItem('role'),
  passwordRequest : {
    isLoading: false,
    error: null as string | null,
    data: null as any,
  },
  updatePassword: {
    isLoading: false,
    error: null as string | null,
    data: null as any,
  }
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('email');
      localStorage.removeItem('token');
      localStorage.removeItem('isLoggedIn');
      localStorage.setItem('role', 'VISITOR');
      state.auth.isLoggedIn = false;
      state.auth.isAuthenticated = null;
      state.profile.roleId = null;
    },

    emptyOneAddress: (state) => {
      state.oneAddress = {
        isLoading: false,
        error: null as string | null,
        data: null,
        addEditSuccess: false,
      };
    },

    emptyUserAfterEdit: (state) => {
      state.profile = {
        isLoading: false,
        error: null as string | null,
        user: null,
        roleId: null,
      };
      state.editSuccess = false;
    },

    emptyRegisterData: (state) => {
      state.register = {
        isLoading: false,
        error: null as string | null,
        data: null as any,
      };
    },
  },
  extraReducers: (builder) => {
    builder

      // Login
      .addCase(login.pending, (state) => {
        state.auth.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.auth.isLoading = false;
        state.auth.error = null;
        state.auth.isAuthenticated = action.payload.meta.apiKey;
        state.auth.email = action.payload.email;
        state.auth.isLoggedIn = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.auth.isLoading = false;
        state.auth.error = action.error.message ?? null;
      })

      // Register
      .addCase(registerUser.pending, (state) => {
        state.register.isLoading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.register.isLoading = false;
        state.register.data = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.register.isLoading = false;
        state.register.error = action.payload as string | null;
      })

      //get user data
      .addCase(getUserProfil.pending, (state) => {
        state.profile.isLoading = true;
      })
      .addCase(getUserProfil.fulfilled, (state, action) => {
        state.profile.isLoading = false;
        state.profile.error = null;
        state.profile.user = action.payload.user;
        state.profile.roleId = action.payload.roleId;
      })
      .addCase(getUserProfil.rejected, (state, action) => {
        state.profile.isLoading = false;
        state.profile.error = action.payload as string | null;
      })

      //edit profile
      .addCase(editingProfile.pending, (state) => {
        state.profile.isLoading = true;
      })
      .addCase(editingProfile.fulfilled, (state, action) => {
        state.profile.isLoading = false;
        state.profile.user = action.payload;
        state.editSuccess = true;
      })
      .addCase(editingProfile.rejected, (state, action) => {
        state.profile.isLoading = false;
        state.profile.error = action.payload as string | null;
      })

      //get one address
      .addCase(getOneAddress.pending, (state) => {
        state.oneAddress.isLoading = true;
      })
      .addCase(getOneAddress.fulfilled, (state, action) => {
        state.oneAddress.isLoading = false;
        state.oneAddress.error = null;
        state.oneAddress.data = action.payload;
      })
      .addCase(getOneAddress.rejected, (state, action) => {
        state.oneAddress.isLoading = false;
        state.oneAddress.error = action.payload as string | null;
      })

      //edit one address
      .addCase(editingOneAddress.pending, (state) => {
        state.oneAddress.isLoading = true;
      })
      .addCase(editingOneAddress.fulfilled, (state, action) => {
        state.oneAddress.isLoading = false;
        state.oneAddress.error = null;
        state.oneAddress.addEditSuccess = true;
        state.oneAddress.data = action.payload;
      })
      .addCase(editingOneAddress.rejected, (state, action) => {
        state.oneAddress.isLoading = false;
        state.oneAddress.error = action.payload as string | null;
      })

      //add address
      .addCase(addingOneAddress.pending, (state) => {
        state.oneAddress.isLoading = true;
      })
      .addCase(addingOneAddress.fulfilled, (state, action) => {
        state.oneAddress.isLoading = false;
        state.oneAddress.error = null;
        state.oneAddress.addEditSuccess = true;
        state.oneAddress.data = action.payload;
      })
      .addCase(addingOneAddress.rejected, (state, action) => {
        state.oneAddress.isLoading = false;
        state.oneAddress.error = action.payload as string | null;
      })

      //delete address
      .addCase(deletingOneAddress.pending, (state) => {
        state.oneAddress.isLoading = true;
      })
      .addCase(deletingOneAddress.fulfilled, (state, action) => {
        state.oneAddress.isLoading = false;
        state.oneAddress.error = null;
        state.oneAddress.addEditSuccess = true;
        state.oneAddress.data = action.payload;
      })
      .addCase(deletingOneAddress.rejected, (state, action) => {
        state.oneAddress.isLoading = false;
        state.oneAddress.error = action.payload as string | null;
      })

      //add user
      .addCase(addUser.pending, (state) => {
        state.newUser.isLoading = true;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.newUser.isLoading = false;
        state.newUser.error = null;
        state.newUser.data = action.payload;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.newUser.isLoading = false;
        state.newUser.error = action.payload as string | null;
      })
      //password request
      .addCase(passwordRequest.pending, (state) => {
        state.passwordRequest.isLoading = true;
      })
      .addCase(passwordRequest.fulfilled, (state, action) => {
        state.passwordRequest.isLoading = false;
        state.passwordRequest.error = null;
        state.passwordRequest.data = action.payload;
      })
      .addCase(passwordRequest.rejected, (state, action) => {
        state.passwordRequest.isLoading = false;
        state.passwordRequest.error = action.payload as string | null;
      })
      //update password
      .addCase(updatePassword.pending, (state) => {
        state.updatePassword.isLoading = true;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.updatePassword.isLoading = false;
        state.updatePassword.error = null;
        state.updatePassword.data = action.payload;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.updatePassword.isLoading = false;
        state.updatePassword.error = action.payload as string | null;
      });
  },
});

export const { logout, emptyOneAddress, emptyUserAfterEdit, emptyRegisterData } = authSlice.actions;

export default authSlice.reducer;
