import { createSlice } from '@reduxjs/toolkit';
import { CarouselState } from 'reduxTK/RootState';
import { getCarousel } from './carouselAsyncThunk';

const initialState: CarouselState = {
    data: null,
    isLoading: false,
    error: null
};

export const carouselSlice = createSlice({
    name: 'carousel',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCarousel.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCarousel.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.data = action.payload;
            })
            .addCase(getCarousel.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message ?? null;
            });
    }
});

export default carouselSlice.reducer;
