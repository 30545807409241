import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './latestProject.module.scss';
import { API, API_HOST_FICODEV } from 'configs/api';
import axiosService from 'common/axiosService';

interface LatestProjectProps {
  title: string;
  usage: string;
  type: string;
  amount: string;
  status: string;
  applicantFirstName: string;
  applicantLastName: string;
  manager: string;
  creationDate: string;
  id?: string;
  customer?: string;
  lastContactedDate?: string;
  timesContacted?: string;
  s_email_agent: string;
  
}

const LatestProject: React.FC<LatestProjectProps> = ({
  title,
  usage,
  type,
  amount,
  status,
  applicantFirstName,
  applicantLastName,
  manager,
  creationDate,
  id,
  customer,
  lastContactedDate,
  timesContacted,
  s_email_agent,
}) => {

  const navigate = useNavigate();
  const [file, setFile] = React.useState<any>(null);
  const [nameCustomer, setNameCustomer] = React.useState<string>('');
  const [agent, setAgent] = React.useState<string>('');
  useEffect(() => {

    const fetchStatuses = async (status: string) => {
      const res = await axiosService.get(`${API_HOST_FICODEV}${API.customer.customerById(customer ?? '')}`);
      setNameCustomer(res.data.name);
      return res.data.s_cust_logo;
    }

    const getFile = async (id: string) => {
      if (!id) {
      return null;
      }
      try {
      const res = await axiosService.get(`${API_HOST_FICODEV}${API.files(id)}`);
      return res.data;
      } catch (error) {
      console.error('Error:', error);
      throw error;
      }
    };

    if (customer) {
      fetchStatuses(customer).then((res) => {
        getFile(res).then((file) => {
          setFile(file);
        });
      });
    }
    if (s_email_agent) {
      const agent = async () => {
        try {
          const URL = `${API_HOST_FICODEV}${API.auth.user}?email=${s_email_agent}`;
          const response = await axiosService.get(
            URL
          );
          setAgent(response.data[0].firstName + ' ' + response.data[0].lastName);
        } catch (error) {
          console.error(error);
          setAgent('');
        }
      };
      agent();
    }
  }, [customer,s_email_agent]);

  return (
    <div className={styles.latestProjectContainer}>
      <h3>{title}</h3>
      <div className={styles.latestProject}>
        <div className={styles.latestProject__Image}>
          {file?.content && (
            <img src={`data:image/png;base64,${file.content}`} alt="logo" />
          )}
          <span>{nameCustomer}</span>
        </div>
        <div className={styles.latestProjectDetails}>
          <div className={styles.firstDetails}>
            <span>{usage}</span>
            <span className={styles.deskTop}>/</span>
            <span>{type}</span>
          </div>
          <div className={styles.secondDetails}>
            <span>{parseFloat(amount).toFixed(2)}</span>
            <span>{status}</span>
          </div>
          <div className={styles.thirdDetails}>
            <span>Demandeur :{` ${applicantFirstName} ${applicantLastName}`}</span>
            <span>Via :{agent}</span>
            <span>Dossier géré par : {manager} </span>
            <span>Créé le {new Date(creationDate).toLocaleDateString()}</span>
            {timesContacted && lastContactedDate && (
              <span>
                Contacté {""}
                {timesContacted
                  ? `${timesContacted} fois - Dernier contact le ${
                      lastContactedDate
                        ? new Date(lastContactedDate).toLocaleDateString()
                        : 'Date inconnue'
                    }`
                  : `0 fois`}
              </span>
            )}
          </div>
          <button className={styles.btn} onClick={() => navigate('/projectFile/' + id)}>
            ACCEDEZ AU DOSSIER
          </button>
        </div>
      </div>
    </div>
  );
};

export default LatestProject;
