import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'common/hooks';
import AddressBook from 'components/adresses/addressBook/AddressBook';
import { adressesBookBlock } from 'reduxTK/slices/static-pages/staticPagesAsyncThunk';

interface RootState {
  staticPages: {
    adressesBookBlock: {
      data: any;
      status: string;
      error: any;
    };
  };
}
const AddressBookPage = () => {
  const dispatch = useAppDispatch();

  const about = useSelector((state: RootState) => state.staticPages.adressesBookBlock.data);
  useEffect(() => {
    document.title = "Intergestion - Adresses";
  }, []);
  useEffect(() => {
    dispatch(adressesBookBlock());
  }, [dispatch]);

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: about?.content }} />
      <AddressBook />
    </div>
  );
};

export default AddressBookPage;
