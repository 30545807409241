import React from 'react';
import styles from '../Authentication.module.scss';
import { useNavigate } from 'react-router-dom';

const ConfirmResetPassword: React.FC = () => {
  const navigate = useNavigate();

  const handleReturn = () => {
    navigate('/login');
  };

  return (
    <div className={styles.authenticationContainer}>
      <h2>MOT DE PASSE OUBLIÉ ?</h2>
      <div className={styles.centerFlex}>
        <p>Un nouveau de passe vient de vous être envoyé par email.</p>
        <div className={`${styles.buttonGroup} ${styles.width40}`}>
          <button type="button" onClick={handleReturn}>RETOUR</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmResetPassword;