import EditAddress from 'components/adresses/editAddress/EditAddress';
import { useEffect } from 'react';

const EditAddressPage = () => {
  useEffect(() => {
    document.title = "Intergestion - Adresses";
  }, []);
  return (
    <div>
      <EditAddress />
    </div>
  );
};

export default EditAddressPage;
