import { createSlice } from '@reduxjs/toolkit';
import { getUserProfile } from './profileAsyncThunk';

const reduxState = {
    data: null,
    status: 'loading',
    error: null
};

const initialState = {
    profile: reduxState,
};

export const profileSlice = createSlice(
    {
        name: 'Profile',
        initialState,
        reducers: {},
        extraReducers: (builder) => {
            builder
                .addCase(getUserProfile.pending, state => {
                    return { ...state, profile: { ...state.profile, status: 'loading' } };
                })
                .addCase(getUserProfile.fulfilled, (state, action) => {
                    return { ...state, profile: { ...state.profile, status: 'success', data: action.payload } };
                })
                .addCase(getUserProfile.rejected, (state, action) => {
                    return { ...state, profile: { ...state.profile, status: 'failed', error: (action.payload as any).error } };
                })
        }
    }
);

export default profileSlice.reducer;