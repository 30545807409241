import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { confirmRegisterContent } from 'reduxTK/slices/confirmRegisterContent/confirmRegisterContentAsyncThunk';
import { emptyRegisterData } from 'reduxTK/slices/authentication/authSlice';
import { AppDispatch } from 'reduxTK/store/store';

import styles from '../Authentication.module.scss';

const ConfirmRegister: React.FC = () => {
  interface RootState {
    confirmRegisterContent: {
      data: {
        content: string;
      };
      isLoading: boolean;
      error: Error | null;
    };
  }

  const dispatch: AppDispatch = useDispatch();
  const fetchConfirmRegisterContent = useSelector(
    (state: RootState) => state.confirmRegisterContent?.data
  );

  useEffect(() => {
    dispatch(confirmRegisterContent());
  }, [dispatch]);

  useEffect(() => {
    dispatch(emptyRegisterData());
  }, [dispatch]);

  return (
    <div className={styles.authenticationContainer}>
      <h2>VOTRE INSCRIPTION</h2>
      <div className={styles.authenticationContainer__confirmationContent}>
        {fetchConfirmRegisterContent?.content && (
          <div dangerouslySetInnerHTML={{ __html: fetchConfirmRegisterContent?.content }} />
        )}
      </div>
    </div>
  );
};

export default ConfirmRegister;
