import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'common/hooks';
import { useNavigate } from 'react-router-dom';

import { CircularProgress } from '@mui/material';
import { getUserProfil, login } from 'reduxTK/slices/authentication/authAsyncThunk';

import styles from '../Authentication.module.scss';

interface RootState {
  auth: {
    auth: {
      isAuthenticated: string;
      isLoading: boolean;
      error: string | null;
    };
    profile: {
      roleId: number;
    };
  };
}

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state: RootState) => state.auth);
  const { roleId } = useSelector((state: RootState) => state.auth.profile);

  const [form, setForm] = useState({
    email: '',
    password: '',
  });

  const handleLogin = () => {
    dispatch(login(form));
  };

  const inputsChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((initialState) => ({
      ...initialState,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePassword = () => {
    navigate('/ResetPassword');
  };

  useEffect(() => {
    if (!auth.auth.isLoading && auth.auth.isAuthenticated) {
      dispatch(getUserProfil());
    }
  }, [auth.auth.isLoading, auth.auth.isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (roleId === 1 || roleId === 5) {
      navigate('/agent/home');
    } else if (roleId === 2) {
      navigate('/applicant/home');
    }
  }, [roleId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.authenticationContainer}>
      <div className={styles.login_Container}>
        <div className={styles.login_register_message}>
          <span className={styles.has_account}>Vous avez deja un compte?</span>
          <span>Veuillez saisir vos informations de connexion</span>
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="email">Adresse e-mail *</label>
          <input
            id="email_login"
            type="email"
            name="email"
            placeholder="Adresse e-mail"
            required
            onChange={inputsChangeHandler}
          />
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="password">Mot de passe *</label>
          <input
            id="password_login"
            name="password"
            type="password"
            required
            onChange={inputsChangeHandler}
          />
        </div>
        {auth.auth.error === 'Rejected' && (
          <p className={styles.error_message}>Votre email ou mot de passe n'est pas valide</p>
        )}
        <div className={styles.checkboxGroup}>
          <div className={styles.spacer_30}></div>
          <div className={styles.checkboxItem}>
            <input type="checkbox" id="stayConnected" />
            <label htmlFor="stayConnected">Rester connecté</label>
          </div>
        </div>
        <div className={styles.inputGroup}>
          <div className={styles.spacer_30}></div>
          <div className={styles.buttonGroup}>
            <button type="submit" onClick={handleLogin}>
              {auth.auth.isLoading && <CircularProgress size={15} className={styles.loader} />}
              CONNEXION
            </button>
            <button type="button" onClick={handlePassword}>
              MOT DE PASSE OUBLIE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
