import { createSlice } from '@reduxjs/toolkit';
import { getUseraddresslist , getCountries , getRegions , getCountriesName , getRegionsName} from './addressAsyncThunk';

const reduxState = {
    data: null,
    status: 'loading',
    error: null
};

const initialState = {
    addressList: reduxState,
    countries: reduxState,
    regions: reduxState ,
    countriesName: reduxState ,
    regionsName : reduxState
};

export const addressSlice = createSlice(
    {
        name: 'address',
        initialState,
        reducers: {},
        extraReducers: (builder) => {
            builder
                .addCase(getUseraddresslist.pending, state => {
                    return { ...state, addressList: { ...state.addressList, status: 'loading' } };
                })
                .addCase(getUseraddresslist.fulfilled, (state, action) => {
                    return { ...state, addressList: { ...state.addressList, status: 'success', data: action.payload } };
                })
                .addCase(getUseraddresslist.rejected, (state, action) => {
                    return { ...state, addressList: { ...state.addressList, status: 'failed', error: (action.payload as any).error } };
                })
                .addCase(getCountries.pending, state => {
                    return { ...state, countries: { ...state.countries, status: 'loading' } };
                })
                .addCase(getCountries.fulfilled, (state, action) => {
                    return { ...state, countries: { ...state.countries, status: 'success', data: action.payload } };
                })
                .addCase(getCountries.rejected, (state, action) => {
                    return { ...state, countries: { ...state.countries, status: 'failed', error: (action.payload as any).error } };
                })
                .addCase(getRegions.pending, state => {
                    return { ...state, regions: { ...state.regions, status: 'loading' } };
                })
                .addCase(getRegions.fulfilled, (state, action) => {
                    return { ...state, regions: { ...state.regions, status: 'success', data: action.payload } };
                })
                .addCase(getRegions.rejected, (state, action) => {
                    return { ...state, regions: { ...state.regions, status: 'failed', error: (action.payload as any).error } };
                })
                .addCase(getCountriesName.pending, state => {
                    return { ...state, countriesName: { ...state.countriesName, status: 'loading' } };
                })
                .addCase(getCountriesName.fulfilled, (state, action) => {
                    return { ...state, countriesName: { ...state.countriesName, status: 'success', data: action.payload } };
                })
                .addCase(getCountriesName.rejected, (state, action) => {
                    return { ...state, countriesName: { ...state.countriesName, status: 'failed', error: (action.payload as any).error } };
                })
                .addCase(getRegionsName.pending, state => {
                    return { ...state, regionsName: { ...state.regionsName, status: 'loading' } };
                })
                .addCase(getRegionsName.fulfilled, (state, action) => {
                    return { ...state, regionsName: { ...state.regionsName, status: 'success', data: action.payload } };
                })
                .addCase(getRegionsName.rejected, (state, action) => {
                    return { ...state, regionsName: { ...state.regionsName, status: 'failed', error: (action.payload as any).error } };
                });
        }
    }
);

export default addressSlice.reducer;