import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosService from 'common/axiosService';
import { API, API_HOST, API_HOST_FICODEV } from 'configs/api';
import dayjs from 'dayjs';

export const getRequestsbyCustomerID = createAsyncThunk(
  'Customer/getRequestsbyCustomerID',
  async (
    filters: { agency: string ; status: string , agent :string , startDate : string , endDate : string , sort?: string , order?: string , applicant : string},
  ) => {
    const profileId = localStorage.getItem('profileId');
    try {
      const response = await axiosService.get(
        `${API_HOST_FICODEV}${API.requests.all}?customerUser=${profileId}&customer=${filters.agency}&internal_status=${filters.status}&s_email_agent=${filters.agent}&createdAt[gte]=${filters.startDate}&createdAt[lte]=${filters.endDate}&sort=${filters.order}${filters.sort}&limit=100`
      );

      // Extract only serializable data from the response
      const serializableData = {
        data: response.data, // Assuming response.data is serializable
        status: response.status, // Assuming response.status is serializable
        statusText: response.statusText, // Assuming response.statusText is serializable
      };

      return serializableData;
    } catch (error) {
      // Ensure the rejected action payload contains the error property
      throw error; // Wrap the error in an object with the error property
    }
  }
);

export const getRequestInternalStatus = createAsyncThunk(
  'Requests/getRequestInternalStatus',
  async (id: any) => {
    try {
      const response = await axiosService.get(
        `${API_HOST_FICODEV}${API.requests.requestInternalStatus(id)}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const getRequestUsageBien = createAsyncThunk(
  'Requests/getRequestUsageBien',
  async (id: any) => {
    try {
      const response = await axiosService.get(
        `${API_HOST_FICODEV}${API.requests.requsetUsageBien(id)}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const getRequestTypeBien = createAsyncThunk(
  'Requests/getRequestTypeBien',
  async (id: any) => {
    try {
      const response = await axiosService.get(
        `${API_HOST_FICODEV}${API.requests.requsetTypeBien(id)}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const getChildrenRequest = createAsyncThunk(
  'Requests/getrelatedRequests',
  async (_, thunkAPI) => {
    const storedCustomerId = localStorage.getItem('customer');
    if (storedCustomerId === null) {
      throw new Error('Customer ID is null');
    }
    const customerId = storedCustomerId as string; 
    let allIds: string[] = [];
    
    async function fetchIds(customerId: string) {
      try {
        const response = await axiosService.get(
          `${API_HOST_FICODEV}${API.requests.childrenRequest(customerId)}`
        );
        const ids = response.data.map((item: any) => item.id);
        allIds = allIds.concat(ids);
        if (ids.length > 0) {
          for (const id of ids) {
            await fetchIds(id);
          }
        }
      } catch (error) {
        console.error("Error fetching IDs:", error);
      }
    }
    await fetchIds(customerId);
    return allIds;
  }
);

export const getAllRequests = createAsyncThunk('Requests/getAllRequests', async (
  { id, filters }: { id: any, filters: { agency: string ; status: string , agent :string , startDate : string , endDate : string , applicant : string} }
) => {
  const profileId = localStorage.getItem('profileId');
  const isAdmin = localStorage.getItem('role') === 'ADMIN';
  const isDirector = localStorage.getItem('role') === 'DIRECTOR';
  const storedCustomerId = localStorage.getItem('customer');

  const customerIds = (id: any[]) => id.map((id) => id).join(',');

  try {
    const response = await axiosService.get(
      isAdmin || isDirector
      ? `${API_HOST_FICODEV + API.requests.requests}?customer=${ filters.agency ?  filters.agency : storedCustomerId + (id.length > 0 ? ',' + customerIds(id) : '')}&customerUser=${filters.applicant}&customer=${filters.agency}&internal_status=${filters.status}&s_email_agent=${filters.agent}&createdAt[gte]=${filters.startDate}&createdAt[lte]=${filters.endDate}&page=1&limit=1000`
      : `${API_HOST_FICODEV + API.requests.requests}?customer=${storedCustomerId}&customerUser=${profileId}&page=1&limit=1000`
    );
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const getOneRequest = createAsyncThunk('Requests/getOneRequest', async (id: any) => {
  try {
    const response = await axiosService.get(`${API_HOST_FICODEV}${API.requests.requestById(id)}`);
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
});

export const getRequestCustomer = createAsyncThunk(
  'Requests/getRequestCustomer',
  async (id: any) => {
    try {
      const response = await axiosService.get(
        `${API_HOST_FICODEV}${API.requests.requestCustomerById(id)}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const getRequestAssignedUser = createAsyncThunk(
  'Requests/getRequestAssignedUser',
  async (id: any) => {
    try {
      const response = await axiosService.get(
        `${API_HOST_FICODEV}${API.requests.requestAssignedUser(id)}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const addFinancialRequest = createAsyncThunk(
  'Requests/addFinancialRequest',
  async (data: any, { fulfillWithValue, rejectWithValue }) => {
    const URL = API_HOST_FICODEV + API.requests.requests;

    return axiosService
      .post(URL, data)
      .then((res) => fulfillWithValue(res.data))
      .catch((err) => rejectWithValue(err));
  }
);

export const gettingOneRequest = createAsyncThunk(
  'Requests/gettingOneRequest',
  async (id: string, { fulfillWithValue, rejectWithValue }) => {
    const URL = API_HOST_FICODEV + API.requests.requests + '/' + id;

    return axiosService
      .get(URL)
      .then((res) => fulfillWithValue(res.data))
      .catch((err) => rejectWithValue(err));
  }
);

export const editingRequest = createAsyncThunk(
  'Requests/editFinancialRequest',
  async (data: { id: string; data: any }, { fulfillWithValue, rejectWithValue }) => {
    const URL = API_HOST_FICODEV + API.requests.requests + '/' + data.id;

    return axiosService
      .patch(URL, data.data)
      .then((res) => fulfillWithValue(res.data))
      .catch((err) => rejectWithValue(err));
  }
);

export const confirmAddProject = createAsyncThunk('Requests/confirmAddProject', async () => {
  const response = await axios.get(`${API_HOST}${API.static.projectAddConfirmation}`);
  return response.data.data;
});

export const getRequestSituationPro = createAsyncThunk(
  'Requests/getRequestSituationPro',
  async (id: any) => {
    try {
      const response = await axiosService.get(
        `${API_HOST_FICODEV}${API.requests.requestSituationProById(id)}`
      );
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const getRequestsByAgent = createAsyncThunk(
  'Requests/getRequestsByAgent',
  async (filters: {
    agency?: string;
    status?: string;
    agent?: string;
    startDate?: string;
    endDate?: string;
    applicant?: string;
  }) => {
    const agentEmail = localStorage.getItem('email');
    
    const queryParams = Object.entries(filters)
      .filter(([key, value]) => value !== undefined) 
      .map(([key, value]) => `${key}=${value}`);

    if (agentEmail) {
      queryParams.push(`s_email_agent=${agentEmail}`);
    }
    if (filters.applicant) {
      queryParams.push(`customerUser=${filters.applicant}`);
    }

    if (filters.agency) {
      queryParams.push(`customer=${filters.agency}`);
    }
    if (filters.status) {
      queryParams.push(`internal_status=${filters.status}`);
    }
    if (filters.startDate) {
      queryParams.push(`createdAt[gte]=${filters.startDate}`);
    }
    if (filters.endDate) {
      queryParams.push(`createdAt[lte]=${filters.endDate}`);
    }

    if (filters.applicant) {
      queryParams.push(`customerUser=${filters.applicant}`);
    }
    
    const queryString = queryParams.join('&');
    const url = `${API_HOST_FICODEV + API.requests.requests}?${queryString}&page=1&limit=1000`;

    try {
      const response = await axiosService.get(url);
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }
);



export const getAgentRequests = createAsyncThunk<{ month: number; count: number }[]>(
  'Requests/getAgentRequests',
  async () => {
    const s_email_agent = localStorage.getItem('email');
    const formatDateToMonth = (date: Date) => date.getMonth();

    const countOccurrences = (array: any) => {
      const countMap: Record<string, number> = {};
      array.forEach((obj: any) => {
        const month = formatDateToMonth(dayjs(obj.createdAt).toDate());
        countMap[month] = (countMap[month] || 0) + 1;
      });
      return countMap;
    };

    const convertToDesiredFormat = (countMap: Record<string, number>) => {
      const resultArray = [];
      for (let month = 0; month <= 11; month++) {
        const count = countMap[month] || 0;
        resultArray.push({ month: month, count });
      }
      return resultArray;
    };

    try {
      const response = await axiosService.get(
        `${API_HOST_FICODEV}${API.requests.agentRequests(s_email_agent)}`
      );

      const occurrences = countOccurrences(response.data);

      return convertToDesiredFormat(occurrences);
    } catch (error: any) {
      throw error.response.data;
    }
  }
);

export const getAllRequestsByAgent = createAsyncThunk(
  'Requests/getAllRequestsByAgent',
  async (id?: any) => {
    const s_email_agent = localStorage.getItem('email');
    const role = localStorage.getItem('role');

    const customerIds = (id: any[]) => id.map((id) => id).join(',');
    const storedCustomerId = localStorage.getItem('customer');

    const URL =
      role === 'AGENT'
        ? `${API_HOST_FICODEV}${API.requests.requests}?s_email_agent=${s_email_agent}&page=1&limit=1000`
        : `${API_HOST_FICODEV + API.requests.requests}?customer=${storedCustomerId}${id.length > 0 ? ',' + customerIds(id) : ''}&page=1&limit=1000`

    try {
      const response = await axiosService.get(URL);

      let yearData = [
        {
          month: 0,
          count: 0,
        },
        {
          month: 1,
          count: 0,
        },
        {
          month: 2,
          count: 0,
        },
        {
          month: 3,
          count: 0,
        },
        {
          month: 4,
          count: 0,
        },
        {
          month: 5,
          count: 0,
        },
        {
          month: 6,
          count: 0,
        },
        {
          month: 7,
          count: 0,
        },
        {
          month: 8,
          count: 0,
        },
        {
          month: 9,
          count: 0,
        },
        {
          month: 10,
          count: 0,
        },
        {
          month: 11,
          count: 0,
        },
      ];

      const closedProjects = {
        fonds_non_debloques: {
          value: 'fonds_non_debloques',
          number: 0,
        },
        assurance_refusee: {
          value: 'assurance_refusee',
          number: 0,
        },
        offre_de_credit_refusee: {
          value: 'offre_de_credit_refusee',
          number: 0,
        },
        fonds_debloques: {
          value: 'fonds_debloques',
          number: 0,
        },
        cancelled_by_customer: {
          value: 'cancelled_by_customer',
          number: 0,
        },
        rejected: {
          value: 'rejected',
          number: 0,
        },
      };

      const openProjects = {
        open: {
          value: 'open',
          number: 0,
        },
        more_info_requested: {
          value: 'more_info_requested',
          number: 0,
        },
        assurance_acceptee: {
          value: 'assurance_acceptee',
          number: 0,
        },
        expected_signature: {
          value: 'expected_signature',
          number: 0,
        },
        proposition_acceptee: {
          value: 'proposition_acceptee',
          number: 0,
        },
        offre_de_credit_acceptee: {
          value: 'offre_de_credit_acceptee',
          number: 0,
        },
        processed: {
          value: 'processed',
          number: 0,
        },
        information_provided: {
          value: 'information_provided',
          number: 0,
        },
        signed: {
          value: 'signed',
          number: 0,
        },
        more_file_requested: {
          value: 'more_file_requested',
          number: 0,
        },
      };

      await response.data.map((req: any) => {
        if (req.internal_status === 'open') {
          openProjects.open.number = openProjects.open.number + 1;
        }
        if (req.internal_status === 'more_info_requested') {
          openProjects.more_info_requested.number = openProjects.more_info_requested.number + 1;
        }
        if (req.internal_status === 'assurance_acceptee') {
          openProjects.assurance_acceptee.number = openProjects.assurance_acceptee.number + 1;
        }
        if (req.internal_status === 'expected_signature') {
          openProjects.expected_signature.number = openProjects.expected_signature.number + 1;
        }
        if (req.internal_status === 'proposition_acceptee') {
          openProjects.open.number = openProjects.open.number + 1;
        }
        if (req.internal_status === 'offre_de_credit_acceptee') {
          openProjects.offre_de_credit_acceptee.number =
            openProjects.offre_de_credit_acceptee.number + 1;
        }
        if (req.internal_status === 'processed') {
          openProjects.processed.number = openProjects.processed.number + 1;
        }
        if (req.internal_status === 'information_provided') {
          openProjects.information_provided.number = openProjects.information_provided.number + 1;
        }
        if (req.internal_status === 'signed') {
          openProjects.signed.number = openProjects.signed.number + 1;
        }
        if (req.internal_status === 'more_file_requested') {
          openProjects.more_file_requested.number = openProjects.more_file_requested.number + 1;
        }

        if (req.internal_status === 'fonds_non_debloques') {
          closedProjects.fonds_non_debloques.number = closedProjects.fonds_non_debloques.number + 1;
        }
        if (req.internal_status === 'assurance_refusee') {
          closedProjects.assurance_refusee.number = closedProjects.assurance_refusee.number + 1;
        }
        if (req.internal_status === 'offre_de_credit_refusee') {
          closedProjects.offre_de_credit_refusee.number =
            closedProjects.offre_de_credit_refusee.number + 1;
        }
        if (req.internal_status === 'fonds_debloques') {
          closedProjects.fonds_debloques.number = closedProjects.fonds_debloques.number + 1;
        }
        if (req.internal_status === 'cancelled_by_customer') {
          closedProjects.cancelled_by_customer.number =
            closedProjects.cancelled_by_customer.number + 1;
        }
        if (req.internal_status === 'rejected') {
          closedProjects.rejected.number = closedProjects.rejected.number + 1;
        }
        return null;
      });

      await response.data.map((req: any) => {
        if (req.internal_status === 'approuved') {
          const monthIndex = dayjs(req.createdAt).toDate().getMonth();
          yearData[monthIndex].count = yearData[monthIndex].count + 1;
        }
        return null;
      });

      return { data: response.data, yearData, openProjects, closedProjects };
    } catch (error: any) {
      throw error.response.data;
    }
  }
);
