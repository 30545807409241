import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gettingTermsPage } from 'reduxTK/slices/static-pages/staticPagesAsyncThunk';
import { AppDispatch } from 'reduxTK/store/store';
import Skeleton from '@mui/material/Skeleton';

const TermsPage: React.FC = () => {
    useEffect(() => {
        document.title = "Intergestion - CGU / CGV";
      }, []);
    interface RootState {
        staticPages: {
            terms: {
                data: {
                    title: string;
                    content: string;
                }
                isLoading: boolean;
                error: Error | null;
            };
        };
    }

    const dispatch: AppDispatch = useDispatch();
    const terms = useSelector((state: RootState) => state.staticPages.terms.data);
    const isLoading = useSelector((state: RootState) => state.staticPages.terms.isLoading);

    useEffect(() => {
        dispatch(gettingTermsPage());
    }, [dispatch]);

    return (
        <div>
            {isLoading ? (
                <>
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} width={200} />
                    <Skeleton variant="rectangular" height={200} />
                </>
            ) : (
                <>
                    <h2>{terms?.title}</h2>
                    <div dangerouslySetInnerHTML={{ __html: terms?.content }} />
                </>
            )}
        </div>
    );
};

export default TermsPage;
