import { useState } from 'react';
import styles from './accordion.module.scss';
import DescriptionProjectFile from 'components/projectFile/description/DescriptionProjectFile';
import MaritalStatusProjectFile from 'components/projectFile/maritalStatus/MaritalStatusProjectFile';
import ObjetProjectFile from 'components/projectFile/objet/ObjetProjectFile';
import FundingProjectFile from 'components/projectFile/funding/FundingProjectFile';
import Propositions from 'components/projectFile/propositions/Propositions';
import Contacts from 'components/projectFile/contacts/Contacts';
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

const sections = [
    { id: 1, label: 'Description', component: <DescriptionProjectFile /> },
    { id: 2, label: 'Etat civil', component: <MaritalStatusProjectFile /> },
    { id: 3, label: 'Objet', component: <ObjetProjectFile /> },
    { id: 4, label: 'Financement', component: <FundingProjectFile /> },
    { id: 5, label: 'Propositions', component: <Propositions /> },
    { id: 6, label: 'Contacts', component: <Contacts /> },
];

const AccordionNavbar = () => {
    const [activeSection, setActiveSection] = useState<number | null>(1);

    const handleSectionClick = (sectionId: number) => {
        setActiveSection(activeSection === sectionId ? null : sectionId);
    };

    return (
        <div className={styles.sectionContainer}>
            <div className={styles.accordion}>
                {sections.map(({ id, label, component }) => (
                    <div key={id}>
                        <button className={styles.btn} onClick={() => handleSectionClick(id)}>
                            {label}
                            {activeSection === id ? <FaChevronUp /> : <FaChevronDown />}
                        </button>
                        {activeSection === id && component}
                    </div>
                ))}
            </div>
            <nav className={styles.navbar}>
                {sections.map(({ id, label }) => (
                    <button key={id} onClick={() => handleSectionClick(id)}>
                        {label}
                    </button>
                ))}
            </nav>
            <div>
                {sections.map(({ id, component }) => (
                    <div key={id} className={styles.desktop}>
                        {activeSection === id && component}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AccordionNavbar;