import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllAffiliations } from 'reduxTK/slices/affiliations/affiliationsAsyncThunk';
import styles from '../../agent/usersList/userList.module.scss';
import './affiliations.scss';
interface RootState {
  affiliations: {
    allAffiliations: {
      data: any;
      status: string;
      statusText: any;
    };
  }
}
const AffiliationList = () => {
  const dispatch = useDispatch();
  const headers = ['Clé', 'Nom', 'Email superviseur', 'URL'];
  const affiliations = useSelector((state: RootState) => state.affiliations.allAffiliations.data);
  const status = useSelector((state: RootState) => state.affiliations.allAffiliations.status);
  //const error = useSelector((state: RootState) => state.affiliations.allAffiliations.error);

  // useEffect(() => {
  //   dispatch(getAllAffiliations());
  // }, [dispatch]);

  useEffect(() => {
    document.title = "Intergestion - Affiliations";
  }, []);

  return (
    <div className={styles.addressBookItemContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.iconContainer}>
          <h2>Liste des Affiliations</h2>
        </div>
        <button className={styles.editButton}>Ajouter une affiliation</button>
      </div>
      <br />
      {status === 'loading' && <p>Loading...</p>}
      {status === 'success' && (
        <table className="bordered-table">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {affiliations.map((el:any, index:number) => (
              <tr key={index}>
                <td>{el.affiliate_key}</td>
                <td>{el.name}</td>
                <td>{el.email}</td>
                <td><span>{`https://intergestion.prexange.com/?agent=${el.affiliate_key}`}</span></td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AffiliationList;
