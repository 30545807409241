import { useAppDispatch } from 'common/hooks';

import {
  acceptConfirmation,
  acceptParagraph,
  rejectConfirmation,
  rejectParagraph,
} from 'constant/Dummydata';

import { FaCheckCircle } from 'react-icons/fa';
import { RiCloseCircleLine } from "react-icons/ri";
import styles from './acceptRejectDetails.module.scss';
import { acceptProposition, rejectProposition } from 'reduxTK/slices/propositions/propositionsAsyncThunk';
import { useNavigate } from 'react-router-dom';

const AcceptRejectDetails = (
  { type, propositionId, handleClose }: { type: 'accept' | 'reject', propositionId: number, handleClose: () => void }
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleAccept = async () => {
    dispatch(acceptProposition({ propositionId }));
    await handleClose();
    navigate('/propositionsList');
    
  };

  const handleReject = async () => {
  dispatch(rejectProposition({ propositionId }));
  await handleClose();
  navigate('/propositionsList');
  };

  return (
    <div className={styles.accept_reject}>
      <div className={styles.confirmation}>
        <p className={styles.description_content}>
          {type === 'accept' ? acceptParagraph : rejectParagraph}
        </p>
        <span>{type === 'accept' ? acceptConfirmation : rejectConfirmation}</span>
        <button
          className={type === 'accept' ? styles.btn_accept : styles.btn_refuse}
          onClick={
            type === 'accept' ? handleAccept : handleReject
          }
        >
          {type === 'accept' ? <FaCheckCircle /> : <RiCloseCircleLine />}
          {type === 'accept' ? 'Accepter' : 'Refuser'}
        </button>
        <button
          className={styles.btn_return}
          onClick={handleClose}
        >
          RETOUR
        </button>
      </div>
    </div>
  );
};

export default AcceptRejectDetails;
