import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProgressBar from '@ramonak/react-progress-bar';
import { FaRegEdit } from 'react-icons/fa';
//import logo from 'assets/logos/dummy_img.png';
import { getRequestInternalStatus } from 'reduxTK/slices/requests/requestsAsyncThunk';
import styles from './headerProjectFile.module.scss';
import axiosService from 'common/axiosService';
import { API, API_HOST_FICODEV } from 'configs/api';
import { useNavigate } from 'react-router-dom';
import React from 'react';

interface RootState {
  requests: {
    oneRequestById: {
      data: any;
      status: string;
      error: any;
    };
    requestCustomer: {
      data: any;
      status: string;
      error: any;
    };
    requestAssignedUser: {
      data: any;
      status: string;
      error: any;
    };
    requestInternalStatus: {
      data: any;
      status: string;
      error: any;
    };
  };
  auth: {
    profile: {
      user: any;
    };
  };
}
interface ProgressSteps {
  [key: string]: number;
}
const HeaderProjectFile = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const fileHeader = useSelector((state: RootState) => state.requests.oneRequestById.data);
  const customer = useSelector((state: RootState) => state.requests.requestCustomer.data);
  //const customerName = useSelector((state: RootState) => state.auth.profile.user);
  const internalStatus = useSelector(
    (state: RootState) => state.requests.requestInternalStatus.data
  );

  const [typeBien, setTypeBien] = useState('');
  const [usageBien, setUsageBien] = useState('');
  const [agent, setAgent] = useState('');
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [firstNameOwner, setFirstNameOwner] = useState<string>('');
  const [lastNameOwner, setLastNameOwner] = useState<string>('');
  const [file, setFile] = React.useState<any>(null);
 
  useEffect(() => {
    const progressSteps: ProgressSteps = {
      'open':1,
      'processed':2,
      'expected_signature':3,
      'more_file_requested':4,
      'signed':5,
      'approuved':6,
      'proposition_acceptee':7,
      'dossier_finalise':8,
      'assurance_acceptee':9,
      'assurance_refusee':12,
      'offre_de_credit_editee':10,
      'offre_de_credit_acceptee':11,
      'offre_de_credit_refusee':12,
      'fonds_debloques':12,
      'fonds_non_debloques':12,
      'rejected':12
  
    }
    const fetchStatuses = async (status: string) => {
      const res = await axiosService.get(`${API_HOST_FICODEV}${API.customer.customerById(fileHeader?.customer ?? '')}`);
      return res.data.s_cust_logo;
    }

    const getFile = async (id: string) => {
      if (!id) {
      return null;
      }
      try {
      const res = await axiosService.get(`${API_HOST_FICODEV}${API.files(id)}`);
      return res.data;
      } catch (error) {
      console.error('Error:', error);
      throw error;
      }
    };

    if (fileHeader?.customer) {
      fetchStatuses(fileHeader?.customer).then((res) => {
        getFile(res).then((file) => {
          setFile(file);
        });
      });
    }
    if (fileHeader?.internal_status) {
      dispatch(getRequestInternalStatus(fileHeader?.id) as any);
      const frenchGoodsUsage = async () => {
        try {
          const response = await axiosService.get(
            API_HOST_FICODEV + API.requests.requsetUsageBienById(fileHeader?.s_usage_bien)
          );
          setUsageBien(response.data.name);
        } catch (error) {
          console.error(error);
          setUsageBien('');
        }
      };
      frenchGoodsUsage();
    }
    if (fileHeader?.s_type_bien) {
      const frenchGoodsType = async () => {
        try {
          const response = await axiosService.get(
            API_HOST_FICODEV + API.requests.requsetTypeBienById(fileHeader?.s_type_bien)
          );
          setTypeBien(response.data.name);
        } catch (error) {
          console.error(error);
          setTypeBien('');
        }
      };
      frenchGoodsType();
    }
    if (fileHeader?.s_email_agent) {
      const agent = async () => {
        try {
          const URL = `${API_HOST_FICODEV}${API.auth.user}?email=${fileHeader?.s_email_agent}`;
          const response = await axiosService.get(
            URL
          );
          setAgent(response.data[0].firstName + ' ' + response.data[0].lastName);
        } catch (error) {
          console.error(error);
          setAgent('');
        }
      };
      agent();
    } 
    if(fileHeader?.internal_status) {
      Object.keys(progressSteps).forEach(key => {
        if (key === fileHeader.internal_status) {
         // step = progressSteps[key];
          const calculatedProgressPercentage = (progressSteps[key] / 12) * 100; 
          setProgressPercentage(calculatedProgressPercentage);
        }
      });    
    }

  }, [
    dispatch,
    fileHeader?.id,
    fileHeader?.s_type_bien,
    fileHeader?.s_usage_bien,
    fileHeader?.internal_status,
    fileHeader?.s_email_agent,
    fileHeader?.customer
  ]);

  useEffect(() => {
    const fetchData = async () => {
      if (fileHeader?.owner) {
        try {
          const onwnerRes = await axiosService.get(`${API_HOST_FICODEV}${API.users}/${fileHeader.owner}`);
          const onwnerData = onwnerRes.data;
          setFirstNameOwner(onwnerData.firstName);
          setLastNameOwner(onwnerData.lastName);
        } catch (error) {
          console.error('Error:', error);
        }
      }
    }
    fetchData();
  }
    , [fileHeader]);

  return (
    <div className={styles.headerContainer}>
      <FaRegEdit className={styles.edit} onClick={() => navigate('/editProjectApplicant/' + fileHeader?.id)} />
      <div className={styles.progressBarContainer}>
        <ProgressBar bgColor={'green'} completed={progressPercentage.toFixed(2)} height={'17px'} labelAlignment='center' labelSize='10px' />
      </div>
      <div className={styles.project}>
        <span className={`${styles.center} ${styles.usage}`}>{usageBien}</span>
        <span className={`${styles.center} ${styles.type}`}>{typeBien}</span>
      </div>
      <span className={`${styles.center} ${styles.status}`}>{internalStatus?.name}</span>
      <span className={`${styles.center} ${styles.montant}`}>
        Montant à financer : {parseFloat(fileHeader?.s_montant_global).toFixed(2)} €
      </span>
      <div className={styles.imgCaontainer}>
      {file?.content && (
        <img className={styles.img} src={`data:image/png;base64,${file?.content}`} alt="project" />
      )}
        <span className={styles.center}>{customer?.name}</span>
      </div>
      <div className={styles.details}>
        <span>
          Demandeur :{fileHeader?.firstName} {fileHeader?.lastName}
        </span>
        <span>
          via {agent}
        </span>
        <span>
          Dossier géré par :{` ${firstNameOwner} ${lastNameOwner}`}
        </span>
        <span>
          Créé le {new Date(fileHeader?.createdAt).toLocaleDateString()} - mise à jour{' '}
          {new Date(fileHeader?.updatedAt).toLocaleDateString()}
        </span>
        <span>
          Contacté{' '}
          {fileHeader?.timesContacted > 0
            ? `${fileHeader.timesContacted} fois - Dernier contact le ${fileHeader.lastContactedDate ? new Date(fileHeader.lastContactedDate).toLocaleDateString() : 'Date inconnue'}`
            : `0 fois`}
        </span>
      </div>
    </div>
  );
};

export default HeaderProjectFile;
