import BlockTitle from '../../blockTitle/BlockTitle';
import styles from './raisonInvestir.module.scss';

const raisonInvestir = () => {
    return (
        <div className={styles.raisonInvestirContainer}>

          <BlockTitle title="Pourquoi ?" subtitle="3 bonnes raisons d’investir à crédit" />

          <div>
            <ol>
              <li>
                <span className={styles.counter}>1.</span>
                <h3>Profiter de l’effet de levier</h3>
                <p>En investissant dans une SCPI dont la rentabilité est supérieure au taux d’intérêt de votre crédit,
                  vous bénéficiez d’un effet de levier qui vous permet d’augmenter votre capital à long terme !</p>
              </li>

              <li>
                <span className={styles.counter}>2.</span>
                <h3>Conserver de la liquidité</h3>
                <p>Faire un emprunt vous permet d’investir tout en conservant de la liquidité, essentielle pour pouvoir
                  faire face aux imprévus et aux aléas de la vie : perte d’emploi, baisse d’activité, etc.</p>
              </li>

              <li>
                <span className={styles.counter}>3.</span>
                <h3>Saisir les opportunités</h3>
                <p>Le crédit vous permet de saisir les meilleures opportunités, vous pouvez ainsi acheter une quantité
                  plus élevée de parts des SCPI les plus rentables et ainsi profiter rapidement de revenus
                  potentiels.</p>
              </li>
            </ol>
          </div>

        </div>
    )
}

export default raisonInvestir