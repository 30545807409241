import logoPrexange from 'assets/logos/logo-prexange.png';
import SliderImage from 'assets/slider-home-image.png';

const Hero = () => {
  return (
    <div className="block_hero__container">
      <img className="block_hero__image" src={SliderImage} alt="" />
      <div className="block_hero__body">
        <h1 className="block_hero__title">Investissez en SCPI <br />à crédit et augmentez <br />vos revenus potentiels
        </h1>
        <p>Inter Gestion REIM s’associe à la plateforme de financement Prexange pour vous proposer une solution de
          financement simple et rapide ! <br /><img src={logoPrexange} alt="prexange" /></p>
      </div>
    </div>
  );
}

export default Hero;