// App.tsx
import Login from 'components/authentication/login/Login';
import Register from 'components/authentication/register/Resgiter';
import React, { useEffect } from 'react';

const AuthenticationPage: React.FC = () => {
  useEffect(() => {
    document.title = "Intergestion - Authentification";
  }, []);
  return (
    <div>
      <Login />
      <Register />
    </div>
  );
};

export default AuthenticationPage;