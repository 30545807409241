import React, { useEffect } from 'react';
import ContactItem from './contactItem/ContactItem';
import { useSelector } from 'react-redux';
import axiosService from 'common/axiosService';
import { API_HOST_FICODEV, API } from 'configs/api';

interface RootState {
  requests: {
    oneRequestById: {
      data: any;
      status: string;
      error: any;
    };
    requestAssignedUser: {
      data: any;
      status: string;
      error: any;
    };
  };
}
const Contacts = () => {
  const fileHeader = useSelector((state: RootState) => state.requests.oneRequestById.data);
  //const assignedUser = useSelector((state: RootState) => state.requests.requestAssignedUser.data);
  const [prescripteur, setPrescripteur] = React.useState<any>('');
  const [owner, setOwner] = React.useState<any>('');
  const [businessUnit, setBusinessUnit] = React.useState<any>('');
  const [customerName, setCustomerName] = React.useState<any>('');
  useEffect(() => {

    if(fileHeader?.s_email_agent) {
      const agent = async () => {
        try {
          const URL = `${API_HOST_FICODEV}${API.auth.user}?email=${fileHeader.s_email_agent}`;
          const response = await axiosService.get(
            URL
          );
          setPrescripteur(response.data[0]);
          const customerId= response.data[0].customer;
          if(customerId) {
            const URL = `${API_HOST_FICODEV}${API.customer.customerById(customerId)}`;
          const response = await axiosService.get(
            URL
          );
          setCustomerName(response.data.name);
          }
        } catch (error) {
          setCustomerName('');
        }
      };
      agent();
    }
    if(fileHeader?.owner) {
      const owner = async () => {
        try {
          const URL = `${API_HOST_FICODEV}/admin/api/users/${fileHeader.owner}`;
          const response = await axiosService.get(
            URL
          );
          setOwner(response.data);
          const businessUnitData = response.data.businessUnits;
          if(businessUnitData) {
            try {
              const URL = `${API_HOST_FICODEV}/admin/api/businessunits/${businessUnitData[0]}`;
              const response = await axiosService.get(
                URL
              );
              setBusinessUnit(response.data.name);
            } catch (error) {
              setBusinessUnit('');
            }
          }
        } catch (error) {
          setOwner('');
        }
      };
      owner();
    }
  },[fileHeader.s_email_agent,fileHeader.owner]);
  const contacts = [
    {
      contactRole: 'Votre courtier',
      contactName:
        owner === null
          ? '---'
          : `${owner?.firstName} ${owner?.lastName}`,
      contactAgency: businessUnit,
      contactEmail: owner?.email ? owner?.email : '---',
      contactPhone: owner?.phone === null ? '---' : owner?.phone,
    },
    {
      contactRole: 'Votre prescripteur',
      contactName: `${prescripteur?.firstName} ${prescripteur?.lastName}`,
      contactAgency: customerName,
      contactEmail: fileHeader?.s_email_agent,
      contactPhone: '06 12 34 56 78',
    },
  ];

  return (
    <div>
      {contacts.map((contact, index) => (
        <ContactItem
          key={index}
          contactRole={contact.contactRole}
          contactName={contact.contactName}
          contactAgency={contact.contactAgency}
          contactEmail={contact.contactEmail}
          contactPhone={contact.contactPhone}
        />
      ))}
    </div>
  );
};

export default Contacts;
