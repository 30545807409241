import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from 'common/hooks';

import NewProposition from './NewProposition';
import DetailsSection from './DetailsSection';
import ProgressBar from '@ramonak/react-progress-bar';
import styles from './propositionDetails.module.scss';
import { useParams } from 'react-router-dom';
import { getOneProposition } from 'reduxTK/slices/propositions/propositionsAsyncThunk';
import { useSelector } from 'react-redux';
import axiosService from 'common/axiosService';
import { API, API_HOST_FICODEV } from 'configs/api';
import saveAs from 'file-saver';
import {
  getFile1,
  getFile2,
  getFile3,
} from 'reduxTK/slices/filesToDownload/filesToDownloadAsyncThunk';
import ReactToPrint from 'react-to-print-advanced';
import { FaCheckCircle, FaPrint } from 'react-icons/fa';
import { Box, Modal } from '@mui/material';
import AcceptRejectDetails from './AcceptRejectDetails/AcceptRejectDetails';

const PropositionDetails = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams();

  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(getOneProposition(id ?? ''));
  }, [dispatch, id]);

  const OneProposition = useSelector((state: any) => state.propositions.oneProposition.data);
  const fileOne = useSelector((state: any) => state.files.fileOne.data);
  const fileTwo = useSelector((state: any) => state.files.fileTwo.data);
  const fileThree = useSelector((state: any) => state.files.fileThree.data);
  interface Statuses {
    montantGlobal: string | null;
    usageBien: string | null;
    typeBien: string | null;
    customerUser: any | null;
    status: string | null;
    assignedUsersfirstName: string | null;
    assignedUserslastName: string | null;
  }
  const [statuses, setStatuses] = useState<Statuses>({
    montantGlobal: null,
    usageBien: null,
    typeBien: null,
    customerUser: null,
    status: null,
    assignedUsersfirstName: null,
    assignedUserslastName: null,
  });

  const [file, setFile] = React.useState<any>(null);
  const [nameCustomer, setNameCustomer] = React.useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      if (OneProposition?.customer) {
        try {
          const customerRes = await axiosService.get(
            `${API_HOST_FICODEV}${API.customer.customerById(OneProposition?.customer)}`
          );
          const customerData = customerRes.data;
          setNameCustomer(customerData.name);
          const fileRes = await axiosService.get(
            `${API_HOST_FICODEV}${API.files(customerData?.s_cust_logo)}`
          );
          const fileData = fileRes.data;
          setFile(fileData);
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };
    fetchData();
  }, [OneProposition?.customer]);

  useEffect(() => {
    if (!OneProposition?.customerUser) {
      return;
    }

    const fetchStatuses = async () => {
      try {
        let usageBienResponse: any,
          typeBienResponse: any,
          montantGlobalResponse: any,
          assignedUsers: any,
          costmerUserResponse: any;
        if (OneProposition?.customerUser) {
          costmerUserResponse = await axiosService.get(
            `${API_HOST_FICODEV}${API.auth.user}/${OneProposition?.customerUser}`
          );
        }

        if (OneProposition?.request) {
          usageBienResponse = await axiosService.get(
            `${API_HOST_FICODEV}${API.requests.requsetUsageBien(OneProposition?.request)}`
          );
          typeBienResponse = await axiosService.get(
            `${API_HOST_FICODEV}${API.requests.requsetTypeBien(OneProposition?.request)}`
          );
          montantGlobalResponse = await axiosService.get(
            `${API_HOST_FICODEV}${API.requests.all}/${OneProposition?.request}`
          );
          assignedUsers = await axiosService.get(
            `${API_HOST_FICODEV}${API.users}/${OneProposition?.owner}`
          );
        }

        setStatuses((prevStatuses) => ({
          ...prevStatuses,
          usageBien: usageBienResponse?.data?.name,
          typeBien: typeBienResponse?.data?.name,
          montantGlobal: montantGlobalResponse?.data?.s_montant_global,
          customerUser: costmerUserResponse?.data,
          assignedUsersfirstName: assignedUsers?.data.firstName,
          assignedUserslastName: assignedUsers?.data.lastName,
        }));

        if (OneProposition?.internal_status) {
          const statusResponse = await axiosService.get(
            `${API_HOST_FICODEV}${API.propositions.propositionInternalStatus}/${OneProposition?.internal_status}`
          );
          setStatuses((prevStatuses) => ({
            ...prevStatuses,
            status: statusResponse?.data?.name,
          }));
        }
      } catch (error) {
        console.error('Error fetching statuses:', error);
      }
    };

    fetchStatuses();
  }, [
    OneProposition?.request,
    OneProposition?.internal_status,
    OneProposition?.customerUser,
    OneProposition?.customer,
    OneProposition?.owner,
  ]);

  const OnePropositionTitles = [
    { text: statuses.typeBien ?? '', icon: '/' },
    { text: statuses.usageBien ?? '', icon: '<RiZoomInLine />' },
  ];

  const OnePropositiondetails = [
    {
      label: 'Demandeur :',
      value: `${statuses.customerUser?.firstName} ${statuses.customerUser?.lastName}` ?? '',
    },
    {
      label: 'Dossier gérer par :',
      value: `${statuses.assignedUsersfirstName} ${statuses.assignedUserslastName}` ?? '',
    },
    {
      label: 'Montant à financier',
      value: statuses.montantGlobal ? parseFloat(statuses.montantGlobal).toFixed(2) : '',
    },
  ];

  const newPropositions = [
    { type: 'title', label: '', value: OneProposition?.poNumber },
    { type: 'title', label: '', value: '' },
    {
      type: 'date',
      label: 'Proposée le',
      value: new Date(OneProposition?.createdAt).toLocaleDateString(),
    },
    {
      type: 'date',
      label: "Valide jusqu'ou",
      value: new Date(OneProposition?.validUntil).toLocaleDateString(),
    },
  ];

  useEffect(() => {
    dispatch(getFile1(id ?? ''));
    dispatch(getFile2(id ?? ''));
    dispatch(getFile3(id ?? ''));
  }, [OneProposition, dispatch, id]);

  const handleDownload = (data: any) => {
    try {
      if (!data.content || typeof data.content !== 'string') {
        console.error('File content is empty or not valid.');
        return;
      }
      const bytes = Uint8Array.from(atob(data.content), (c) => c.charCodeAt(0));

      const blob = new Blob([bytes], { type: data.mimeType });

      saveAs(blob, data.originalFilename);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const [isPrinting, setIsPrinting] = useState(false);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    p: 3,
    color: '#003c8a',
  };


  const [modalType, setModalType] = React.useState<null | 'accept' | 'reject'>(null);

  const handleOpenAccept = () => {
    setModalType('accept');
  };

  const handleOpenReject = () => {
    setModalType('reject');
  };

  const handleClose = () => {
    setModalType(null);
  };

  return (
    <>
      <ReactToPrint
        trigger={() => <FaPrint size={30} className={styles.print_icon} />}
        content={() => sectionRef.current as HTMLElement}
        onBeforePrint={() => setIsPrinting(true)}
        onAfterPrint={() => setIsPrinting(false)}
      />
      <div className={styles.propositionDetailsContainer} ref={sectionRef}>
        <div className={styles.progressBarContainer}></div>
        <ProgressBar bgColor={'green'} completed={60} height={'15px'} />

        <DetailsSection
          titles={OnePropositionTitles}
          details={OnePropositiondetails}
          propositionStatus={statuses?.status}
          AgenceName={nameCustomer}
          file={file}
        />
        <NewProposition
          propositionStatus={statuses?.status} newPropositions={newPropositions.map((item) => ({ ...item, value: String(item.value) }))}
        />
        <br></br>
        <div className={styles.description}>
          <h5 className={styles.description_title}>Description de la proposition</h5>
          <p className={styles.description_content} style={{ whiteSpace: 'pre-line' }}>{OneProposition?.s_quote_detail}</p>
        </div>
        <div className={styles.files}>
          <div className={styles.file} onClick={() => handleDownload(fileOne)}>
            <span>{fileOne?.originalFilename} </span>
          </div>
          <div className={styles.file} onClick={() => handleDownload(fileTwo)}>
            <span>{fileTwo?.originalFilename} </span>
          </div>
          <div className={styles.file} onClick={() => handleDownload(fileThree)}>
            <span>{fileThree?.originalFilename} </span>
          </div>
        </div>
        {!isPrinting && (statuses.status !== 'Proposition acceptée' && statuses.status !== 'Proposition refusée') && (
          <div className={styles.actions}>
            <button className={styles.actions_accept}
              onClick={handleOpenAccept}
            >
              <FaCheckCircle />
              Accepter
            </button>
            <button className={styles.actions_reject}
              onClick={handleOpenReject}
            >
              <FaCheckCircle />
              Refuser
            </button>
          </div>
        )}
        <Modal
          open={modalType !== null}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {modalType && 
            <AcceptRejectDetails 
              type={modalType}  
              propositionId = {OneProposition?.id}
              handleClose={handleClose}
            />}
          </Box>
        </Modal>
      </div>
    </>
  )
};

export default PropositionDetails;
