import { createSlice } from '@reduxjs/toolkit';
import { ConfirmRegisterContentState } from 'reduxTK/RootState';
import { confirmRegisterContent } from './confirmRegisterContentAsyncThunk';

const initialState: ConfirmRegisterContentState = {
    data: null,
    isLoading: false,
    error: null,
};

const confirmRegisterContentSlice = createSlice({
    name: 'confirmRegisterContent',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(confirmRegisterContent.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(confirmRegisterContent.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(confirmRegisterContent.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || null;
            });
    },
});

export default confirmRegisterContentSlice.reducer;
