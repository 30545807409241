import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'common/hooks';
import { useSelector } from 'react-redux';

import { CircularProgress } from '@mui/material';

import { registerUser } from 'reduxTK/slices/authentication/authAsyncThunk';

import styles from '../Authentication.module.scss';

interface RootState {
  auth: {
    register: {
      isLoading: boolean;
      error: Array<{ source: string; detail: string }> | null;
      data: null;
    };
  };
}

const Register: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { register } = useForm();

  const { isLoading, error, data } = useSelector((state: RootState) => state.auth.register);

  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [match, setMatch] = useState(true);
  const [emailErrorText, setEmailErrorText] = useState('');
  const [passwordErrorText, setPasswordErrorText] = useState('');

  const onSubmit = (event: any) => {
    event.preventDefault();
    if (password1 === password2) {
      setMatch(true);
      let data = {
        lastName: event.target[0].value,
        firstName: event.target[1].value,
        email: event.target[2].value,
        password: event.target[3].value,
        birthday: event.target[5].value,
      };

      dispatch(registerUser(data));
    } else {
      setMatch(false);
    }
  };

  useEffect(() => {
    if (error) {
      error.forEach((obj) => {
        if (obj.source === 'email') {
          setEmailErrorText(obj.detail);
        } else if (obj.source === 'password') {
          setPasswordErrorText(obj.detail);
        }
      });

      if (!error.some((obj) => obj.source === 'email')) {
        setEmailErrorText('');
      }

      if (!error.some((obj) => obj.source === 'password')) {
        setPasswordErrorText('');
      }
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      navigate('/ConfirmRegister');
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.authenticationContainer}>
      <div className={styles.login_register_message}>
        <span className={styles.no_account}>Vous n'avez pas de compte?</span>
        <span>
          Veuillez vous enregistrer si vous n'avez pas de compte. Un courtier vous contactera
          rapidement
        </span>
      </div>
      <form onSubmit={onSubmit}>
        <div className={styles.inputGroup}>
          <label htmlFor="email">Nom *</label>
          <input {...register('lastName')} name="lastName" required type="text" placeholder="Nom" />
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="email">Prénom *</label>
          <input
            {...register('firstName')}
            name="firstName"
            required
            type="text"
            placeholder="Prénom"
          />
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="email">Adresse e-mail *</label>
          <input
            id="email_register"
            type="email"
            placeholder="Adresse e-mail"
            required
            {...register('email')}
            name="email"
          />
        </div>
        {emailErrorText === 'This email is already used.' && (
          <p className={styles.error_message}>Cet email est déjà utilisé.</p>
        )}
        <div className={styles.inputGroup}>
          <label htmlFor="password">Mot de passe *</label>
          <input
            id="password_register"
            type="password"
            autoComplete="new-password"
            placeholder="Mot de passe"
            required
            {...register('password')}
            name="password"
            onChange={(e) => setPassword1(e.target.value)}
          />
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="re-password">Retapez le mot de passe *</label>
          <input
            id="re-password"
            type="password"
            autoComplete="new-password"
            placeholder="Retapez le mot de passe"
            required
            {...register('confirmPassword')}
            name="confirmPassword"
            onChange={(e) => setPassword2(e.target.value)}
          />
        </div>
        {!match && <p className={styles.error_message}>Mots de passe non identiques!</p>}
        {passwordErrorText ===
          'The password must be at least 8 characters long and include an upper case letter and a number' && (
          <p className={styles.error_message}>
            Le mot de passe doit comporter au moins 8 caractères et inclure une lettre majuscule et
            un chiffre.
          </p>
        )}
        <div className={styles.inputGroup}>
          <label htmlFor="birthday">Date de naissance *</label>
          <input id="birthday" type="date" required />
        </div>
        <div className={styles.inputGroup}>
          <span className={styles.spacer_30}></span>
          <div className={styles.buttonGroup}>
            <button type="submit">
              {isLoading && <CircularProgress size={15} className={styles.loader} />}
              <span>S'INSCRIRE</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Register;
