import { FaRegNewspaper } from 'react-icons/fa';
import styles from './propositionDetails.module.scss';

interface NewPropositionProps {
  newPropositions: { type: string; label: string; value: string }[];
  propositionStatus : string | null;
}

const NewProposition: React.FC<NewPropositionProps> = ({ newPropositions , propositionStatus }) => {
  return (
    <div className={styles.newProposition}>
      <h3 className={styles.newProposition_title}>
        <FaRegNewspaper /> {propositionStatus}
      </h3>
      <div className={styles.newProposition_details}>
      {newPropositions.map((proposition, index) =>
        proposition.type === 'title' ? (
          <h3 key={index}>
            {proposition.label} {proposition.value}
          </h3>
        ) : (
          <span key={index}>
            {proposition.label} {proposition.value}
          </span>
        )
      )}
      </div>
    </div>
  );
};

export default NewProposition;
