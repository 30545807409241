import React from 'react'
import { useSelector } from 'react-redux';
import Detail from '../Detail';

interface RootState {
    requests: {
      oneRequestById: {
        data: any;
        status: string;
        error: any;
      };
    };
}

const FundingProjectFile = () => {
    const fileHeader = useSelector((state: RootState) => state.requests.oneRequestById.data);

    const fundingDetails = [
        { label: "Prix de vente à financer (€) :", value: `${parseFloat(fileHeader?.s_montant_global).toFixed(2)}` },
        { label: "Dont prix acquisition terrain (€)  :", value: `${parseFloat(fileHeader?.s_acquisition).toFixed(2)}` },
        { label: "Dont prix travaux et/ou construction (€)  :", value: `${parseFloat(fileHeader?.s_montant_travaux).toFixed(2)}` },
        { label: "Montant total revenus net imposable annuel (€) :", value: `${parseFloat(fileHeader?.s_montant_impot).toFixed(2)}` },
        { label: "Montant total mensuel des crédits en cours (€) :", value: `${parseFloat(fileHeader?.s_montant_credit).toFixed(2)}` },
        { label: "Montant de l'appart possible (€) :", value: `${parseFloat(fileHeader?.s_montant_apport).toFixed(2)}` },
        { label: "Mensualité maximum souhaitée (€) :", value: `${parseFloat(fileHeader?.s_montant_mens_max).toFixed(2)}` },
        { label: "Durée du crédit souhaitée :", value: `${fileHeader?.s_duree_credit_souhait.replace(/_/g, ' ')}` },
    ];

    return (
        <div>
            <h3>FINANCEMENT</h3>
            {fundingDetails.map((detail, index) => (
                <Detail key={index} label={detail.label} value={detail.value} />
            ))}
        </div>
    )
}

export default FundingProjectFile