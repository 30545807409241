import { createSlice } from '@reduxjs/toolkit';
import { getFile1 , getFile2 , getFile3 } from './filesToDownloadAsyncThunk';

const reduxState = {
    data: null,
    status: 'loading',
    error: null
};
const initialState = {
    fileOne: reduxState,
    fileTwo: reduxState,
    fileThree: reduxState,
};
export const filesSlice = createSlice(
    {
        name: 'Files',
        initialState,
        reducers: {},
        extraReducers: (builder) => {
            builder
            // File 1
            .addCase(getFile1.pending, state => {
                return { ...state, allPropositions: { ...state.fileOne, status: 'loading' } };
            })
            .addCase(getFile1.fulfilled, (state, action) => {
                return { ...state, fileOne: { ...state.fileOne, status: 'success', data: action.payload } };
            })
            .addCase(getFile1.rejected, (state, action) => {
                return { ...state, fileOne: { ...state.fileOne, status: 'failed', error: (action.error as any ) } };
            })
            // File 2
            .addCase(getFile2.pending, state => {
                return { ...state, allPropositions: { ...state.fileTwo, status: 'loading' } };
            })
            .addCase(getFile2.fulfilled, (state, action) => {
                return { ...state, fileTwo: { ...state.fileTwo, status: 'success', data: action.payload } };
            })
            .addCase(getFile2.rejected, (state, action) => {
                return { ...state, fileTwo: { ...state.fileTwo, status: 'failed', error: (action.error as any ) } };
            })
            // File 3
            .addCase(getFile3.pending, state => {
                return { ...state, allPropositions: { ...state.fileThree, status: 'loading' } };
            })
            .addCase(getFile3.fulfilled, (state, action) => {
                return { ...state, fileThree: { ...state.fileThree, status: 'success', data: action.payload } };
            })
            .addCase(getFile3.rejected, (state, action) => {
                return { ...state, fileThree: { ...state.fileThree, status: 'failed', error: (action.error as any ) } };
            });

        }
    }
);

export default filesSlice.reducer;
