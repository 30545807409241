import styles from '../editProjectApplicant.module.scss';

interface AdviceBlockProps {
  handleInputChange: any;
  formData: any;
}

const AdviceBlock: React.FC<AdviceBlockProps> = ({ handleInputChange, formData }) => {
  return (
    <div>
      <h2 className={styles.blockTitle}>ZONE RÉSERVÉE AU CONSEILLER</h2>
      <div className={styles.inputContainer}>
        <label htmlFor="s_email_agent">
          Adresse du prescripteur<span>*</span>
        </label>
        <input
          value={formData.s_email_agent}
          onChange={(e) => handleInputChange('s_email_agent', e.target.value)}
          className={styles.input}
          type="text"
          name="s_email_agent"
          id="s_email_agent"
        />
      </div>
    </div>
  );
};

export default AdviceBlock;
