import React, { useEffect, useState } from 'react'
import { MenuItem, Select } from '@mui/material'

interface FilterSelectOptionsProps {
    filterData: any,
    Address: any,
    selectChangeHandler: (e: any) => void
}

const Countryfilter = (
    { filterData, Address , selectChangeHandler }: FilterSelectOptionsProps
) => {

    const [uniqueRegion, setUniqueRegion] = useState<string[]>([]);
    const [hasEffectRun, setHasEffectRun] = useState(false);
    
useEffect(() => {
    if (!hasEffectRun && Address && Address.length > 0) {
        const Regions = Address.flatMap((entry: any) => entry.country);
        const uniqueRegion: string[] = Array.from(new Set(Regions));
        setUniqueRegion(uniqueRegion);
        setHasEffectRun(true);
    }
}, [Address, hasEffectRun]);

    return (
        <Select
        labelId="country"
        value={filterData.country}
        name="country"
        label="Par country"
        onChange={selectChangeHandler}
        >
            <MenuItem value="">
                <em>Aucun</em>
            </MenuItem>
            {uniqueRegion &&
                uniqueRegion.map(
                    (entry, index) => (
                        <MenuItem key={index} value={entry}>{entry}</MenuItem>
                    )
                )}
        </Select>
    )
}

export default Countryfilter
