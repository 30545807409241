import { useEffect, useState } from 'react'
import styles from './PropositionsList.module.scss';
import axiosService from 'common/axiosService';
import { API, API_HOST_FICODEV } from 'configs/api';
import { useNavigate } from 'react-router-dom';


interface Proposition {
    poNumber: string;
    s_montant_global: string;
    createdAt: string;
    validUntil: string;
    s_quote_detail: string;
    id: string;
    request: string;
    customerUser: string;
    internal_status: boolean; // the status of the proposition is not yet defined in the Data Base
}

interface Statuses {
    montantGlobal: string | null;
    usageBien: string | null;
    typeBien: string | null;
    customerUser: any | null;
    status: string | null;
    request: any | null;
}

const OneProposition = ({ proposition }: { proposition: Proposition }) => {
const [prescripteur,setPrescripteur] = useState('');
    const [statuses, setStatuses] = useState<Statuses>({
        montantGlobal: null,
        usageBien: null,
        typeBien: null,
        customerUser: null,
        status: null,
        request: null
    });

    const navigate = useNavigate();
    
    useEffect(() => {
        const fetchStatuses = async () => {
            try {
                if (!proposition?.request ) {
                    console.log("Proposition request is null");
                    return;
                }
                const costmerUserResponse = await axiosService.get(`${API_HOST_FICODEV}${API.auth.user}/${proposition?.customerUser}`);
                const usageBienResponse = await axiosService.get(`${API_HOST_FICODEV}${API.requests.requsetUsageBien(proposition?.request)}`);
                const typeBienResponse = await axiosService.get(`${API_HOST_FICODEV}${API.requests.requsetTypeBien(proposition?.request)}`);
                const montantGlobalResponse = await axiosService.get(`${API_HOST_FICODEV}${API.requests.all}/${proposition?.request}`);
                const requestResponse = await axiosService.get(`${API_HOST_FICODEV}${API.requests.requestById(proposition.request)}`);
                setStatuses(prevStatuses => ({
                    ...prevStatuses,
                    usageBien: usageBienResponse?.data?.name,
                    typeBien: typeBienResponse?.data?.name,
                    montantGlobal: montantGlobalResponse?.data?.s_montant_global,
                    customerUser: costmerUserResponse?.data,
                    request : requestResponse?.data
                }));
                if (proposition?.internal_status) {
                    const statusResponse = await axiosService.get(`${API_HOST_FICODEV}${API.propositions.propositionInternalStatus}/${proposition?.internal_status}`);
                    setStatuses(prevStatuses => ({
                        ...prevStatuses,
                        status: statusResponse?.data?.name
                    }));
                }
            } catch (error) {
                console.error('Error fetching statuses:', error);
            }
        };
        
        fetchStatuses();
        if(statuses?.request?.s_email_agent){
            console.log('here');
        const fetchPrescripteur = async () => {
            try {
                const URL = `${API_HOST_FICODEV}${API.auth.user}?email=${statuses.request.s_email_agent}`;
                const response = await axiosService.get(
                  URL
                );
                setPrescripteur(response.data[0].firstName+' '+response.data[0].lastName);
              } catch (error) {
                console.log(error);
              }
        };
        fetchPrescripteur();
    }
    }, [proposition?.id, proposition?.request, proposition?.internal_status ,proposition?.customerUser,statuses?.request?.s_email_agent]);
    
    

    return (
        <div className={styles.one_proposition_container}>
            <div className={styles.description_status_container}>
                <div className={styles.description_container}>
                    <div className={styles.firstDetail}>
                        <div className={styles.firstBox}>
                            <span className={styles.proposition_title}>Proposition : {proposition?.poNumber}</span>
                        </div>
                        <span className={styles.accepted}>
                            {statuses.status }
                        </span>
                    </div>
                </div>
            </div>
            <span className={styles.proposition_text}>Projet : {statuses.typeBien} / {statuses.usageBien} </span>
            <span className={styles.proposition_text}>
                Demandeur : {statuses.customerUser?.firstName} {statuses.customerUser?.lastName} </span>
                <span className={styles.proposition_text}>
                Via : {prescripteur} </span>
            <span className={styles.proposition_text}>
                Montant à financer : {statuses.montantGlobal? parseFloat(statuses.montantGlobal).toFixed(2) : ''} €
            </span>
            <span className={styles.proposition_text}>
                Proposée le {new Date(proposition?.createdAt).toLocaleDateString()}</span>
            <span className={styles.proposition_text}>
                valide jusqu'au  {new Date(proposition?.validUntil).toLocaleDateString()}</span>
            <span style={{ whiteSpace: 'pre-line' }} className={styles.proposition_comment} >{proposition?.s_quote_detail}</span>

            <button className={styles.go_to_proposition} onClick={() => navigate(`/propositionDetailsPage/${proposition?.id}`)} >Accédez à la proposition</button>
        </div>
    );
};
export default OneProposition
