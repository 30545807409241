import PropositionDetails from 'components/propositionDetail/PropositionDetails';
import { useEffect } from 'react';

const PropositionsDetailsPage = () => {
  useEffect(() => {
    document.title = "Intergestion - Proposition";
  }, []);
  return (
    <div>
      <PropositionDetails />
    </div>
  )
}

export default PropositionsDetailsPage
