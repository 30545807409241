import React, { useEffect, useState } from 'react';
import { contactus } from 'reduxTK/slices/static-pages/staticPagesAsyncThunk';
import { useAppDispatch } from 'common/hooks';
import { useSelector } from 'react-redux';
import styles from './ContactUsPage.module.scss';
import { constactUsInputs } from 'constant/Dummydata';
import { useNavigate } from 'react-router-dom';
import { resetContactus } from 'reduxTK/slices/static-pages/staticPagesSlice';


interface RootState {
  staticPages: {
    contactus: {
      data: any;
      isLoading: boolean;
      error: any;
    };
  };
}

const ContactUsPage: React.FC = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector((state: RootState) => state.staticPages.contactus.isLoading);

  const [formData, setFormData] = useState({}); 

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(contactus(formData));
  };
  useEffect(() => {
    document.title = "Intergestion - Contact";
  }, []);
  useEffect(() => {
    if (isLoading===false) {
      setFormData({});
      navigate('/');
      dispatch(resetContactus());
    }
  }, [isLoading, dispatch , navigate]);

  return (
    <div className={styles.contactMainContainer}>
      <h1>Contactez-nous</h1>
      <p>Remplissez le formulaire ci-dessous pour nous contacter.</p>
      <div className={styles.formContactUs}>
        <form className={styles.fildsContainer} onSubmit={handleSubmit}>
          {constactUsInputs.map((input, index) => (
            <div key={index} className={styles.inputs}>
              <label htmlFor={input.name}>{input.label}</label>
              {input.type === 'select' && input.options ? (
                <select
                  id={input.name}
                  defaultValue={input.defaultValue}
                  name={input.name}
                  required={input.required}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleInputChange(
                    e as unknown as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  )}
                >
                  {input.options.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : input.type === 'textarea' ? (
                <textarea
                  className={styles.textAria}
                  id={input.name}
                  name={input.name}
                  required={input.required}
                  onChange={handleInputChange}
                />
              ) : (
                <input
                  type={input.type}
                  id={input.name}
                  name={input.name}
                  required={input.required}
                  onChange={handleInputChange}
                />
              )}
            </div>
          ))}
          <div className={styles.btnContainer}>
            <button className={styles.submitButton} type="submit">
              Envoyer
            </button>
            <button className={styles.outlineButton}>Cliquez ici pour vous inscrire</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUsPage;
