import AddressBookItem from './addressBookItem/AddressBookItem';

const AddressBook = () => {
  const Sections = [
    { sectionName: 'MES ADRESSES' },
  ];

  return (
    <div>
      {Sections.map((section, index) => {
        return <AddressBookItem key={index} sectionName={section.sectionName} />;
      })}
    </div>
  );
};

export default AddressBook;
