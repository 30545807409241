import { useEffect } from 'react';
import { useAppDispatch } from 'common/hooks';
import { useSelector } from 'react-redux';

import {
  getEtatAvancementList,
  getModaliteAchatList,
  getModeAchatList,
  getTypeBienList,
  getUsageBienList,
} from 'reduxTK/slices/requestDropDowns/requestDropDownsAsyncThunk';

import styles from '../editProjectApplicant.module.scss';

interface RootState {
  requestDropDowns: {
    type_bien: {
      data: any;
    };
    usage_bien: {
      data: any;
    };
    modalite_achat: {
      data: any;
    };
    mode_achat: {
      data: any;
    };
    etat_avancement: {
      data: any;
    };
  };
}

interface ProjectPurposeBlockProps {
  handleInputChange: any;
  formData: any;
}

const ProjectPurposeBlock: React.FC<ProjectPurposeBlockProps> = ({
  handleInputChange,
  formData,
}) => {
  const dispatch = useAppDispatch();

  const type_bien = useSelector((state: RootState) => state.requestDropDowns.type_bien.data);
  const usage_bien = useSelector((state: RootState) => state.requestDropDowns.usage_bien.data);
  const modalite_achat = useSelector(
    (state: RootState) => state.requestDropDowns.modalite_achat.data
  );
  const mode_achat = useSelector((state: RootState) => state.requestDropDowns.mode_achat.data);
  const etat_avancement = useSelector(
    (state: RootState) => state.requestDropDowns.etat_avancement.data
  );

  const projectPurpose = [
    { label: 'Type de bien', type: 'select', name: 's_type_bien', options: type_bien  , required: true},
    { label: 'Usage du bien', type: 'select', name: 's_usage_bien', options: usage_bien , required: true},
    {
      label: 'Modalité d’achat',
      type: 'select',
      name: 's_modalite_achat',
      options: modalite_achat,
      required: true,
    },
    { label: "Mode d'achat", type: 'select', name: 's_mode_achat', options: mode_achat , required: true},
    {
      label: 'Etat d’avancement acquisition',
      type: 'select',
      name: 's_etat_avancement',
      options: etat_avancement,
      required: true,
    },
  ];

  useEffect(() => {
    dispatch(getTypeBienList());
    dispatch(getUsageBienList());
    dispatch(getModaliteAchatList());
    dispatch(getModeAchatList());
    dispatch(getEtatAvancementList());
  }, [dispatch]);

  return (
    <div>
      <h2 className={styles.blockTitle}>OBJET DE MON PROJET</h2>
      <div className={styles.inputsBlock}>
        {projectPurpose.map((input, index) => {
          return (
            <div key={index} className={styles.inputContainer}>
              <label htmlFor={input.name}>
                {input.label}
                <span>*</span>
              </label>
              <select
                className={styles.input}
                name={input.name}
                id={input.name}
                value={formData ? formData[input.name] : ''}
                onChange={(e) => handleInputChange(input.name, e.target.value)}
                required={input.required}
              >
                {input.options?.map((option: any, index: number) => {
                  return (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  );
                })}
              </select>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProjectPurposeBlock;
