import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import HomePageApplicant from 'pages/applicant/home/HomePageApplicant';
import AgentHome from 'components/home/agentHome/AgentHome';
import DemandePret from 'components/demandePret/DemandePret';
import RaisonInvestir from 'components/home/raisonInversir/RaisonInvestir';
import CommentObtenirPret from 'components/home/commentObtenirPret/CommentObtenirPret';
import Hero from 'components/home/hero/Hero';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

interface RootState {
  auth: {
    profile: {
      isLoading: boolean;
    };
  };
}

interface HomeProps {
  role: string;
}

const HomePage: React.FC<HomeProps> = ({ role }) => {
  const user = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('agent');
  let content: JSX.Element;
  
  useEffect(() => {
    document.title = "Intergestion - Accueil";
  }, []);
  useEffect(() => {
    if(query) {
    
     const cookies = new Cookies();
     cookies.set('from_agent', query, {
      path: '/',
      domain: '.prexange.com', // Domaine commun pour tous les sous-domaines
      httpOnly: false,
      sameSite: 'none',
      secure: true
    });
    }
  }, [query]);


  switch (role) {
    case 'APPLICANT':
      content = !user.profile.isLoading ? <HomePageApplicant /> : <div>Loading...</div>;
      break;
    case 'AGENT':
      content = !user.profile.isLoading ? <AgentHome /> : <div>Loading...</div>;
      break;
    case 'ADMIN':
      content = !user.profile.isLoading ? <AgentHome /> : <div>Loading...</div>;
      break;
    default:
      content = (
        <div>
          <Hero />
          <RaisonInvestir />
          <CommentObtenirPret />
          <DemandePret />
        </div>
      );
      break;
  }

  return content;
};

export default HomePage;
