import  { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Detail from '../Detail';
import { useDispatch } from 'react-redux';
import axiosService from 'common/axiosService';
import { API, API_HOST_FICODEV } from 'configs/api';

interface RootState {
  requests: {
    oneRequestById: {
      data: any;
      status: string;
      error: any;
    };
  };
  auth: {
    profile: {
      user: {
        addresses: any;
      };
    };
  };
}

const ObjetProjectFile = () => {
  const dispatch = useDispatch();

  const fileHeader = useSelector((state: RootState) => state.requests.oneRequestById.data);
 // const billingAddress = useSelector((state: RootState) => state.auth.profile.user.addresses);

  const [typeBien, setTypeBien] = useState('');
  const [usageBien, setUsageBien] = useState('');
  const [modaliteAchat, setModaliteAchat] = useState('');
  const [modeAchat, setModeAchat] = useState('');
  const [etatAvancement, setEtatAvancement] = useState('');

  useEffect(() => {
    if (fileHeader?.s_usage_bien) {
      const frenchGoodsUsage = async () => {
        try {
          const response = await axiosService.get(
            API_HOST_FICODEV + API.requests.requsetUsageBienById(fileHeader?.s_usage_bien)
          );
          setUsageBien(response.data.name);
        } catch (error) {
          console.error(error);
          setUsageBien('');
        }
      };
      frenchGoodsUsage();
    }
    if (fileHeader?.s_type_bien) {
      const frenchGoodsType = async () => {
        try {
          const response = await axiosService.get(
            API_HOST_FICODEV + API.requests.requsetTypeBienById(fileHeader?.s_type_bien)
          );
          setTypeBien(response.data.name);
        } catch (error) {
          console.error(error);
          setTypeBien('');
        }
      };
      frenchGoodsType();
    }
    if (fileHeader?.s_modalite_achat) {
      const frenchGoodsModalite = async () => {
        try {
          const response = await axiosService.get(
            API_HOST_FICODEV + API.requests.requestModaliteAchatById(fileHeader?.s_modalite_achat)
          );
          //   console.log(response);
          setModaliteAchat(response.data.name);
        } catch (error) {
          console.error(error);
          setModaliteAchat('');
        }
      };
      frenchGoodsModalite();
    }
    if (fileHeader?.s_mode_achat) {
      const frenchGoodsMode = async () => {
        try {
          const response = await axiosService.get(
            API_HOST_FICODEV + API.requests.requestModeAchatById(fileHeader?.s_mode_achat)
          );
          setModeAchat(response.data.name);
        } catch (error) {
          console.error(error);
          setModeAchat('');
        }
      };
      frenchGoodsMode();
    }
    if (fileHeader?.s_etat_avancement) {
      const frenchGoodsState = async () => {
        try {
          const response = await axiosService.get(
            API_HOST_FICODEV + API.requests.requestEtatAvancementById(fileHeader?.s_etat_avancement)
          );
          setEtatAvancement(response.data.name);
        } catch (error) {
          console.error(error);
          setEtatAvancement('');
        }
      };
      frenchGoodsState();
    }
  }, [
    dispatch,
    fileHeader?.s_type_bien,
    fileHeader?.s_usage_bien,
    fileHeader?.s_modalite_achat,
    fileHeader?.s_mode_achat,
    fileHeader?.s_etat_avancement,
  ]);

  const objectDetails = [
    { label: 'Type de bien :', value: { typeBien } },
    { label: 'Usage du bien :', value: { usageBien } },
    { label: "Modalité d'achat :", value: { modaliteAchat } },
    { label: "Mode d'achat :", value: { modeAchat } },
    { label: 'Avancement acquisition :', value: { etatAvancement } },
  ];

  //const billingSecondary = billingAddress[1]?.primary === false;

  const adresseDetails = [
    {
      label: 'Adresse :',
      value:
        fileHeader
          ? fileHeader.s_street1 + '  ' + fileHeader.s_street2
          : '',
    },
    {
      label: 'Code postal :',
      value: fileHeader ? fileHeader.s_codepostal : '',
    },
    { label: 'ville :', value: fileHeader ? fileHeader.s_ville : '' },
    {
      label: 'Pays :',
      value:  fileHeader ? fileHeader.s_pays : '',
    },
  ];

  return (
    <div>
      <h3>Objet du projet</h3>
      {objectDetails.map((detail, index) => (
        <Detail
          key={index}
          label={detail.label}
          value={
            typeof detail.value === 'string'
              ? detail.value
              : detail.value.typeBien ||
                detail.value.usageBien ||
                detail.value.modaliteAchat ||
                detail.value.modeAchat ||
                detail.value.etatAvancement ||
                ''
          }
        />
      ))}
      <h3>Adresse du bien</h3>
      {adresseDetails.map((detail, index) => (
        <Detail key={index} label={detail.label} value={detail.value} />
      ))}
    </div>
  );
};

export default ObjetProjectFile;
