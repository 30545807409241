import { useEffect } from 'react';

import { InputLabel, MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

import styles from './CustomFilter.module.scss';
import { getAllCustomers, getrfpinternalstatuses, getAllApplicants, getAllAgents , getAllPropositionsStatuses } from 'reduxTK/slices/filter/filterAsyncThunk';
import { useAppDispatch } from 'common/hooks';
import { useSelector } from 'react-redux';

const CustomFilter = (
    { handleFilterChange, filters , type  }
        : { handleFilterChange: any, filters: any , type : string}
) => {

    const dispatch = useAppDispatch();
    const allCustomers = useSelector((state: any) => state.filter.allCustomers.data);
    const allInternalStatuses = useSelector((state: any) => state.filter.allInternalStatuses.data);
    const allApplicants = useSelector((state: any) => state.filter.allApplicants.data);
    const allAgents = useSelector((state: any) => state.filter.allAgents.data);
    const allPropositionsStatuses = useSelector((state: any) => state.filter.allPropositionsStatuses.data);

    useEffect(() => {
        dispatch(getAllCustomers())
        dispatch(getrfpinternalstatuses())
        dispatch(getAllApplicants())
        dispatch(getAllAgents())
        dispatch(getAllPropositionsStatuses())
    }
        , [dispatch]);

    const isApplicant = localStorage.getItem('role') === 'APPLICANT';
    const isAgent = localStorage.getItem('role') === 'AGENT';

    return (
        <div className={styles.filterContainer}>
            <FormControl sx={{ m: 1, minWidth: 280 }}>
                <InputLabel id="customer">Par agence</InputLabel>
                <Select
                    labelId="customer"
                    id="customer"
                    label="Par agence"
                    onChange={handleFilterChange}
                    name='customer'
                    value={filters.customer}
                >
                    <MenuItem value="">
                        Aucun
                    </MenuItem>
                    {allCustomers && allCustomers.map((option: any, index: number) => (
                        <MenuItem key={index} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 280 }}>
                <InputLabel id="internal_status">Par statut</InputLabel>
                <Select
                    labelId="internal_status"
                    id="internal_status"
                    value={filters.internal_status}
                    label="Par statut"
                    defaultValue=''
                    onChange={handleFilterChange}
                    name='internal_status'
                >
                    <MenuItem value="">
                        Aucun
                    </MenuItem>
                    {
                        type === 'proposition' ?
                            allPropositionsStatuses && allPropositionsStatuses.map((option: any, index: number) => (
                                <MenuItem key={index} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))
                            :
                            allInternalStatuses && allInternalStatuses.map((option: any, index: number) => (
                                <MenuItem key={index} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))
                    }
                </Select>
            </FormControl>
            {
                !isApplicant &&
                <FormControl sx={{ m: 1, minWidth: 280 }}>
                    <InputLabel id="firstName">Par demandeur</InputLabel>
                    <Select
                        labelId="firstName"
                        id="firstName"
                        value={filters.firstName}
                        label="Par demandeur"
                        defaultValue=''
                        name='firstName'
                        onChange={handleFilterChange}
                    >
                        <MenuItem value="">
                            Aucun
                        </MenuItem>
                        {allApplicants && allApplicants.map((option: any, index: number) => (
                            <MenuItem key={index} value={option.id}>
                                {option.firstName} {option.lastName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            }
            {
             ( type === 'proposition' || !isAgent) &&
                <FormControl sx={{ m: 1, minWidth: 280 }}>
                    <InputLabel id="s_customer_name">Par agent</InputLabel>
                    <Select
                        labelId="s_customer_name"
                        id="s_customer_name"
                        value={filters.s_customer_name}
                        label="Par agent"
                        name='s_customer_name'
                        defaultValue=''
                        onChange={handleFilterChange}
                    >
                        <MenuItem value="">
                            Aucun
                        </MenuItem>
                        {allAgents && allAgents.map((option: any, index: number) => (
                            <MenuItem key={index} value={option.email}>
                                {option.firstName} {option.lastName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            }
            <FormControl sx={{ m: 1, minWidth: 280 }}>
                <div className={styles.dateContainer}>
                    <span className={styles.label}>
                        Par date
                    </span>
                    <div className={styles.inputs}>
                        <div >
                            <span>
                                Du :
                            </span>
                            <input
                                type="date"
                                name="startDate"
                                onChange={handleFilterChange}
                                value={filters.startDate}
                            />
                        </div>
                        <div>
                            <span>
                                Au :
                            </span>
                            <input
                                type="date"
                                name="endDate"
                                onChange={handleFilterChange}
                                value={filters.endDate}
                            />
                        </div>
                    </div>
                </div>
            </FormControl>
        </div>
    )
}

export default CustomFilter
