import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'common/hooks';

import { SelectChangeEvent } from '@mui/material/Select';

import OneProposition from './OneProposition';
import {
  getAgentQuotesByRequests,
  getPropositionsByCustomerID,
} from 'reduxTK/slices/propositions/propositionsAsyncThunk';
import { getRequestsByAgent } from 'reduxTK/slices/requests/requestsAsyncThunk';
import CustomFilter from 'pages/filter/CustomFilter';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { InputLabel, MenuItem } from '@mui/material';

import styles from './PropositionsList.module.scss';
import { requestListBlock } from 'reduxTK/slices/static-pages/staticPagesAsyncThunk';
import { BsSortDown } from "react-icons/bs";
import { BsSortDownAlt } from "react-icons/bs";

interface RootState {
  staticPages: {
    requestListBlock: {
      data: any;
      status: string;
      error: any;
    };
  };
}

const EditProjectPageApplicant = () => {

  const isAgent = localStorage.getItem('role') === 'AGENT';
  const isApplicant = localStorage.getItem('role') === 'APPLICANT';

  const dispatch = useAppDispatch();
  const propositionbyCustomer = useSelector(
    (state: any) => state.propositions.propositionbyCustomer.data
  );

  const correspondingRequest = useSelector((state: any) => state.requests.requestByAgent.data);

  const filteredQuotesByAgentRequests = useSelector(
    (state: any) => state.propositions.agentQuotesByRequests.data
  );

  const requestsId = correspondingRequest?.map((obj: any) => obj.id);

  const result: string[] = requestsId?.map((item: number) => String(item));
  const about = useSelector((state: RootState) => state.staticPages.requestListBlock.data);

  const [order, setOrder] = useState(true);
  const [filters, setFilters] = useState({
    customer: '',
    internal_status: '',
    firstName: '',
    s_customer_name: '',
    startDate: '',
    endDate: '',
    sort: ''
  });


  const handleFilterChange = (event: SelectChangeEvent) => {
    const name = event.target.name;
    setFilters({
      ...filters,
      [name]: event.target.value,
    });
  };
  useEffect(() => {
    document.title = "Intergestion - Propositions";
  }, []);
  useEffect(() => {
    dispatch(requestListBlock());
  }, [dispatch]);

  useEffect(() => {
    const data = {
      sort: filters.sort,
      order: order ? '' : '-',
    };
    if (isAgent) {
      dispatch(getRequestsByAgent(data as any));
    } else {
      dispatch(
        getPropositionsByCustomerID({
          agency: filters.customer,
          status: filters.internal_status,
          startDate: filters.startDate,
          endDate: filters.endDate,
          sort: filters.sort,
          order: order ? '' : '-',
        } as any)
      );
    }
  }, [dispatch, filters , filters , order]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (correspondingRequest !== null) {
      dispatch(getAgentQuotesByRequests(result));
    }
  }, [dispatch, correspondingRequest]); // eslint-disable-line react-hooks/exhaustive-deps

  const propositionFiltersComponent = () => {
    return (
      <>
        <div dangerouslySetInnerHTML={{ __html: about?.content }} />
        <h4>FILTRES</h4>
        <div className={styles.filters}>

          <CustomFilter
            handleFilterChange={handleFilterChange}
            filters={filters}
            type='proposition'
          />
        </div>
      </>
    );
  };

  const propositionPaginationComponent = () => {

    return (
      <div className={styles.pagination_container}>
        {propositionbyCustomer && <span>{propositionbyCustomer?.length} Proposition</span>}
        <div className={styles.sort}>
          <FormControl sx={{ m: 1, minWidth: 280 }}>
            <InputLabel id="sort">
              Tirer par
            </InputLabel>
            <Select
              labelId="sort"
              id="sort"
              value={filters.sort}
              label="Tirer par"
              defaultValue=''
              name='sort'
              onChange={handleFilterChange}
            >
              <MenuItem value="">
                Aucun
              </MenuItem>
              <MenuItem value="customer">Agence</MenuItem>
              {/* {!isAgent && <MenuItem value="s_email_agent">Agent</MenuItem>} */}
              {!isApplicant && <MenuItem value="3">Demandeur</MenuItem>}
              <MenuItem value="createdAt">Date de dépôt</MenuItem>
            </Select>
          </FormControl>
          <div className={styles.sortBtn}>
            <button
              onClick={() => setOrder(!order)}
              disabled={!filters.sort}
            >
              {order ? <BsSortDown /> : <BsSortDownAlt />}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.propositions_list_container}>
      <div className={styles.filters_container}>
        {propositionFiltersComponent()}
      </div>
      {propositionPaginationComponent()}
      <div className={styles.propositions_container}>
        {isAgent
          ? filteredQuotesByAgentRequests?.map((el: any, index: number) => (
            <OneProposition key={index} proposition={el} />
          ))
          : propositionbyCustomer &&
          propositionbyCustomer?.map((el: any, index: number) => (
            <OneProposition key={index} proposition={el} />
          ))}
      </div>
    </div>
  );
};

export default EditProjectPageApplicant;
