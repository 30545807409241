import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ROLE } from 'features/Role.enum';

interface PrivateRouteProps {
    children: JSX.Element;
    isAuthenticated: boolean;
    roles: ROLE[]
}

interface RootState {
    auth: {
        role: ROLE;
    };
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ isAuthenticated, children, roles }) => {

    const userRole = useSelector((state: RootState) => state.auth.role);
    if (!isAuthenticated) {
        // L'utilisateur n'est pas connecté, rediriger vers la page de connexion
        return <Navigate to="/login" replace />;
    }
    if (roles && !roles.includes(userRole)) {
        // L'utilisateur n'a pas le rôle requis, rediriger vers une page d'erreur ou d'accueil
        return <Navigate to="/" />;
    }
    return children
};
export default PrivateRoute;