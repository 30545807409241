import styles from './detail.module.scss';

const Detail = ({ label, value }: { label: string; value: string }) => (
  <span className={styles.detail}>
    <label className={styles.label}>{label}</label>
    <span className={styles.value}>{value}</span>
  </span>
);

export default Detail;
