import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'reduxTK/store/store';
import { confirmAddProject } from 'reduxTK/slices/requests/requestsAsyncThunk';
import styles from '../../../components/authentication/Authentication.module.scss';
import { useNavigate } from 'react-router-dom';

const ProjectAddConfirmation: React.FC = () => {
  interface RootState {
    requests: {
      addProjectConfirmation: {
        data: {
          content: string;
        };
        isLoading: boolean;
        error: Error | null;
      };
    };
  }

  const dispatch: AppDispatch = useDispatch();
  const fetchAddProjectConfirmation = useSelector(
    (state: RootState) => state.requests.addProjectConfirmation?.data
  );

  useEffect(() => {
    dispatch(confirmAddProject());
  }, [dispatch]);

  const navigate = useNavigate();

  return (
    <div className={`${styles.authenticationContainer} ${styles.confirmation}`}>
      <h2>VOTRE PROJET</h2>
      <div className={styles.authenticationContainer__confirmationContent}>
        {fetchAddProjectConfirmation?.content && (
          <div dangerouslySetInnerHTML={{ __html: fetchAddProjectConfirmation?.content }} />
        )}
      </div>
      <div className={styles.btnContainer}>
        <button className={styles.btn} onClick={() => navigate('/')}>
          Retour vers l'accueil
        </button>
      </div>
    </div>
  );
};

export default ProjectAddConfirmation;
