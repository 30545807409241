import { createSlice } from '@reduxjs/toolkit';
import { RoadMapState } from 'reduxTK/RootState';
import { getRoadMap } from './roadMapAsyncThunk';

const initialState: RoadMapState = {
    data: null,
    isLoading: false,
    error: null
};

export const roadMapSlice = createSlice({
    name: 'roadMap',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRoadMap.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getRoadMap.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.data = action.payload;
            })
            .addCase(getRoadMap.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message ?? null;
            });
    }
});

export default roadMapSlice.reducer;