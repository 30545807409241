// App.tsx
import ResetPassword from 'components/authentication/resetpassword/ResetPassword';
import React, { useEffect } from 'react';

const ResetPasswordPage: React.FC = () => {
  useEffect(() => {
    document.title = "Intergestion - Modification du mot de passe";
  }, []);
  return (
    <div>
      <ResetPassword />
    </div>
  );
};

export default ResetPasswordPage;