import { useEffect, useState } from 'react';
import { useAppDispatch } from 'common/hooks';
import { useSelector } from 'react-redux';
import styles from './userList.module.scss';
import { Pagination } from '@mui/material';
import { FaUsers } from "react-icons/fa6";
import UserItem from './userItem/UserItem';
import { getNumberOfUser, getUsersListByCustomer } from 'reduxTK/slices/users/usersAsyncThunk';
import { usersListBlock } from 'reduxTK/slices/static-pages/staticPagesAsyncThunk';
import { useNavigate } from 'react-router-dom';


interface RootState {
    staticPages: {
        usersListBlock: {
            data: any;
            status: string;
            error: any;
        };
    };
}
const UserListPageAgent = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const usersList = useSelector((state: any) => state.users.users.data);
    const numberOfUsers = useSelector((state: any) => state.users.numberOfUsers?.data);
    const numberOfPages = Math.ceil(numberOfUsers / 10);
    const isAdmin = localStorage.getItem('role') === 'ADMIN';

    const pageChangeHandler = (event: React.ChangeEvent<unknown>, page: number) => {
        setPageNumber(page);
    };
    const about = useSelector((state: RootState) => state.staticPages.usersListBlock.data);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    useEffect(() => {
        document.title = "Intergestion - Utilisateurs";
      }, []);
    useEffect(() => {
        dispatch(usersListBlock());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getNumberOfUser());
        dispatch(getUsersListByCustomer(
            { page: pageNumber, pageSize: 10 }
        ));
    }, [dispatch, pageNumber]);



    return (
        <div className={styles.addressBookItemContainer}>
            <div dangerouslySetInnerHTML={{ __html: about?.content }} />
            <div className={styles.headerContainer}>
                <div className={styles.iconContainer}>
                    <FaUsers size={39} />
                    <h2>
                        Liste des Utilisateurs
                    </h2>
                </div>
                {isAdmin &&
                <button onClick={() => navigate('/user/new')} className={styles.editButton}>
                    NOUVEL UTILISATEUR
                </button>
                }
            </div>
            <div className={styles.adressDetails}>
                <span>{numberOfUsers} Utilisateurs</span>
                <Pagination
                    page={pageNumber}
                    onChange={pageChangeHandler}
                    count={numberOfPages}
                />
            </div>
            <div>
                {
                    usersList?.map((user: any) => {
                        return (
                            <UserItem key={user.id} user={user} pageNumber={pageNumber} />
                        );
                    })
                }
            </div>
        </div>
    );
};

export default UserListPageAgent
