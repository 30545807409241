import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'common/hooks';
import { InputLabel, MenuItem } from '@mui/material';

import { projectListBlock } from 'reduxTK/slices/static-pages/staticPagesAsyncThunk';
import { SelectChangeEvent } from '@mui/material/Select';
import { getAllRequests, getChildrenRequest, getRequestsByAgent, getRequestsbyCustomerID } from 'reduxTK/slices/requests/requestsAsyncThunk';

import CustomFilter from 'pages/filter/CustomFilter';
import OneProject from './OneProject';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { BsSortDown } from "react-icons/bs";
import { BsSortDownAlt } from "react-icons/bs";


import styles from './projectsListPage.module.scss';

interface RootState {
  requests: {
    allRequests: {
      data: any;
      status: string;
      error: any;
    };
    requestByAgent: {
      data: any;
      status: string;
      error: any;
    };
  };
  staticPages: {
    projectListBlock: {
      data: any;
      status: string;
      error: any;
    };
  };
}

const ProjectsListPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    document.title = "Intergestion - Projets";
  }, []);
  useEffect(() => {
    dispatch(projectListBlock());
  }, [dispatch]);


  const latestProjects = useSelector((state: RootState) => state.requests.allRequests.data);   // requsets for director and admin
  const agentProjects = useSelector((state: RootState) => state.requests.requestByAgent.data);
  const requestsbyCustomerID = useSelector((state: any) => state.requests.requestsbyCustomerID.data);  // requests by customer id for the applicant
  const about = useSelector((state: RootState) => state.staticPages.projectListBlock.data); // about static page content from directus

  const isAgent = localStorage.getItem('role') === 'AGENT';
  const isAdmin = localStorage.getItem('role') === 'ADMIN';
  const isDirector = localStorage.getItem('role') === 'DIRECTOR';
  const isApplicant = localStorage.getItem('role') === 'APPLICANT';

  const dataToMap = isAgent ? agentProjects : isApplicant ? requestsbyCustomerID : latestProjects;


  const [filters, setFilters] = useState({
    customer: '',
    internal_status: '',
    firstName: '',
    s_customer_name: '',
    startDate: '',
    endDate: '',
    sort : ''
  });

  const [order, setOrder] = useState(true);

  const handleFilterChange = (event: SelectChangeEvent) => {
    const name = event.target.name;
    setFilters({
      ...filters,
      [name]: event.target.value,
    });
  };
  interface Project {
    title: string;
    company: string;
    agency: string;
    s_usage_bien: string;
    s_type_bien: string;
    s_montant_global: string;
    internal_status: string;
    firstName: string;
    lastName: string;
    s_customer_name: string;
    createdAt: string;
    id: string;
    customer: string;
    owner: number;
    s_email_agent: string;
    timesContacted: string;
    lastContactedDate: string;
  }


  useEffect(() => {
    const data = {
      agency: filters.customer,
      status: filters.internal_status,
      agent: filters.s_customer_name,
      startDate: filters.startDate,
      endDate: filters.endDate,
      applicant: filters.firstName,
      sort: filters.sort,
      order: order ? '' : '-',
    };

    isApplicant ? dispatch(getRequestsbyCustomerID(data)) :
      isAgent ? dispatch(getRequestsByAgent(data)) :
        dispatch(getChildrenRequest()).then((res) => dispatch(getAllRequests({
          id: res?.payload,
          filters: data,
        })));
    ;
  }, [dispatch, isApplicant, isAgent, isAdmin, isDirector, filters , order]);

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: about?.content }} />
      <div>
        <h4>FILTRES</h4>

        <CustomFilter
          handleFilterChange={handleFilterChange}
          filters={filters}
          type='request'
        />
        <div className={styles.sortContainer}>
          <div className={styles.total}>
            <span>{dataToMap?.length} projets</span>
          </div>
          <div className={styles.sort}>
            <FormControl sx={{ m: 1, minWidth: 280 }}>
              <InputLabel id="sort">
                Tirer par
              </InputLabel>
              <Select
                labelId="sort"
                id="sort"
                value={filters.sort}
                label="Tirer par"
                defaultValue=''
                name='sort'
                onChange={handleFilterChange}
              >
                <MenuItem value="">
                  Aucun
                </MenuItem>
                <MenuItem value="customer">Agence</MenuItem>
                {!isAgent && <MenuItem value="s_email_agent">Agent</MenuItem>}
                {/* {!isApplicant && <MenuItem value="3">Demandeur</MenuItem>} */}
                <MenuItem value="createdAt">Date de dépôt</MenuItem>
              </Select>
            </FormControl>
            <div className={styles.sortBtn}>
              <button
                onClick={() => setOrder(!order)}
                disabled={!filters.sort}
              >
                {order ? <BsSortDown /> : <BsSortDownAlt />}
              </button>
            </div>
          </div>
        </div>
        {dataToMap &&
          dataToMap.map((project: Project, index: number) => (
            <OneProject
              key={index}
              title={project.title}
              company={project.company}
              s_type_bien={project.s_type_bien}
              s_usage_bien={project.s_usage_bien}
              s_montant_global={project.s_montant_global}
              internal_status={project.internal_status}
              firstName={project.firstName}
              lastName={project.lastName}
              s_customer_name={project.s_customer_name}
              createdAt={project.createdAt}
              id={project.id}
              customer={project.customer}
              owner={project.owner}
              s_email_agent={project.s_email_agent}
              timesContacted={project.timesContacted}
              lastContactedDate={project.lastContactedDate}
            />
          ))}
      </div>
    </div>
  );
};

export default ProjectsListPage;