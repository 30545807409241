import React from 'react'
import styles from './contactItem.module.scss'
import img from 'assets/logos/dummy_img.png';

const ContactItem = (
    {
        contactRole,
        contactName,
        contactAgency,
        contactEmail,
        contactPhone
    }: {
        contactRole: string,
        contactName: string,
        contactAgency: string,
        contactEmail: string,
        contactPhone: string
    }
) => {
    return (
        <div className={styles.contactItemContainer}>
            <span className={styles.role}>{contactRole}</span>
            <div className={styles.detailsContainer}>
                <img className={styles.image} src={img} alt="contact-img"/>
                <div className={styles.details}>
                    <span>{contactName}</span>
                    <span>Agence: {contactAgency}</span>
                    <span>Email: {contactEmail}</span>
                    <span>Téléphone: {contactPhone}</span>
                </div>
            </div>
        </div>
    )
}

export default ContactItem
