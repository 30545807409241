import { useNavigate } from 'react-router-dom';

import { FiEdit } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import styles from './userItem.module.scss';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'common/hooks';
import { API, API_HOST_FICODEV } from 'configs/api';
import axiosService from 'common/axiosService';
import { deleteUser, getNumberOfUser } from 'reduxTK/slices/users/usersAsyncThunk';

interface UserItemProps {
  user : {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    customer: string;
    roles: string[];
  }
  pageNumber: number
}

const UserItem = ({user: { id,firstName, lastName, email, customer, roles},pageNumber}: UserItemProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [customerName, setCustomerName] = useState('');
  const [roleNames, setRoleNames] = useState<string[]>([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    p: 3,
    color: '#003c8a',
  };


  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const response = await axiosService.get(API_HOST_FICODEV + API.customer.customerById(customer));
        setCustomerName(response.data.name);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  
}, [customer]);


useEffect(() => {
  const fetchRoleNames = async () => {
    const names: string[] = [];
    for (const role of roles) {
      try {
        const response = await axiosService.get(API_HOST_FICODEV + API.auth.customerUserRoles(role));
        names.push(response.data.label);
      } catch (error) {
        console.error(error);
      }
    }
    setRoleNames(names);
  };

  fetchRoleNames();
}, [customer, roles]);



  const handeldeleteUser = () => {
    dispatch(deleteUser(id)).then(() => handleClose()).then(() => dispatch(getNumberOfUser())
    )};

  return (
    <div className={styles.profileDetailsContainer}>
      <div className={styles.flex}>
        <div className={styles.adresses}>
          <span className={styles.label}>
            {firstName} {lastName}
          </span>
          <span className={styles.label}>
            Agence : {customerName}
          </span>
          <span className={styles.label}>
            {email}
          </span>
          <span className={styles.value}>Role(s) : {roleNames.join(' , ')}</span>
        </div>
        <div className={styles.editIcons}>
          <span className={styles.btn}>
            <FiEdit onClick={() => navigate(`/user/edit/${id}`)} size={20} />
          </span>
          <span className={styles.btn}>
            <RiDeleteBin6Line onClick={handleOpen} size={20} />
          </span>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Êtes-vous sûr de vouloir supprimer ?
          </Typography>
          <div className={styles.buttonGroup}>
            <button className={styles.modal_buttons} 
              onClick={handeldeleteUser}
            >
              Supprimer
            </button>
            <button className={styles.modal_buttons}>
              Annuler
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default UserItem;
