import { createSlice } from '@reduxjs/toolkit';
import { EditProjectContentState } from 'reduxTK/RootState';
import { editProjectContent } from './editProjectContentAsyncThunk';

const initialState: EditProjectContentState = {
    data: null,
    isLoading: false,
    error: null,
};

const editProjectContentSlice = createSlice({
    name: 'editProjectContent',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(editProjectContent.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editProjectContent.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(editProjectContent.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || null;
            });
    },
});

export default editProjectContentSlice.reducer;
