import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Adresses from 'components/adresses/Adresses';
import ProfileDetails from 'components/profile/ProfileDetails';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useAppDispatch } from 'common/hooks';
import { getUserProfile } from 'reduxTK/slices/profile/profileAsyncThunk';

import styles from './profile.module.scss';
import { profileBlock } from 'reduxTK/slices/static-pages/staticPagesAsyncThunk';

interface RootState {
  staticPages: {
    profileBlock: {
      data: any;
      status: string;
      error: any;
    };
  };
}
const Profile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const profile = useSelector((state: any) => state.profile.profile.data);
  const about = useSelector((state: RootState) => state.staticPages.profileBlock?.data);
  useEffect(() => {
    document.title = "Intergestion - Profil";
  }, []);
  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    dispatch(profileBlock());
  }, [dispatch]);

  return (
    <div className={styles.profilApplicantContaier}>
      <div dangerouslySetInnerHTML={{__html:about?.content}} />
      <ProfileDetails
        email={profile?.email}
        namePrefix={profile?.namePrefix}
        firstName={profile?.firstName}
        middleName={profile?.middleName}
        lastName={profile?.lastName}
        birthday={profile?.birthday}
        roles={profile?.roles}
        confirmed={profile?.confirmed}
        enabled={profile?.enabled}
      />
      <div className={styles.headerContainer}>
        <FaMapMarkerAlt size={39} />
        <h2>ADRESSES</h2>
      </div>
      <div className={styles.adressesContainer}>
        {profile?.addresses.map(
          (
            adress: { city: string; region: string; street: string; postalCode: string },
            index: number
          ) => {
            return (
              <Adresses
                key={index}
                city={adress?.city}
                region={adress?.region}
                street={adress?.street}
                postalCode={adress?.postalCode}
              />
            );
          }
        )}
        <button onClick={() => navigate('/addressBookPage')} className={styles.editButton}>
          GERER LES ADRESSES
        </button>
      </div>
    </div>
  );
};

export default Profile;
